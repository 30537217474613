import { useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { useBoolean } from '@chakra-ui/react';
import Swal from 'sweetalert2';

import { useStore, useLoader } from 'common/hooks';
import { RoleProfileMapping } from 'models/role/RoleProfileMapping';
import { ResponseError } from 'models';
import { CompanyMaster } from 'models/company/Company';
import { UserCompany } from 'models/user/UserCompany';
import {
    getRoleProfileMapping,
    addRoleProfileMapping
} from 'data/role/repositories/role.repository';
import { getCompanyPlatformMapping } from 'data/company/repositories/companyPlatformMapping.repository';
import { CompanyPlatformMapping } from 'data/company/schemas/companyPlatformMapping.schema';

function AssignRoleProfileModel() {
    const loader = useLoader();

    const { userCompanyStore, companyMasterStore } = useStore();
    const { companyMaster } = companyMasterStore;
    const { userRole, userCompanyMapping, currentAdminCompany } = userCompanyStore;

    const companyOptions = useMemo(() => {
        if (userRole === 'Super Admin') {
            return companyMaster;
        }

        // If doesn't admin role
        if (!currentAdminCompany) {
            return [];
        }

        // This case might not be happen but it's safe to do this.
        const foundCompanyMaster = companyMaster.find(
            company => company.sid === currentAdminCompany.sid
        );
        if (!foundCompanyMaster) {
            return [];
        }

        return [foundCompanyMaster];
    }, [companyMaster, currentAdminCompany]);
    const [selectedCompany, setSelectedCompany] = useState<CompanyMaster>();
    const userOptions = useMemo(() => {
        // If doesn't select company
        if (!selectedCompany) {
            return [];
        }

        return userCompanyMapping.filter(user => user.sid === selectedCompany.sid);
    }, [userCompanyMapping, selectedCompany]);
    const [selectedUsers, setSelectedUsers] = useState<UserCompany>();

    const [roleProfileMapping, setRoleProfileMapping] = useState<RoleProfileMapping[]>([]);
    const [userRoleProfileMapping, setUserRoleProfileMapping] = useState<RoleProfileMapping[]>([]);
    const [selectedPlatform, setSelectedPlatform] = useState<CompanyPlatformMapping>();
    const [companyPlatformMapping, setCompanyPlatformMapping] = useState<CompanyPlatformMapping[]>(
        []
    );
    const platformOptions = useMemo(() => {
        // If doesn't select company
        if (!selectedCompany) {
            return [];
        }

        return companyPlatformMapping;
    }, [companyPlatformMapping, selectedCompany]);

    const [showModalERP, setShowModalERP] = useBoolean();
    const [showModalWorkflow, setShowModalWorkflow] = useBoolean();

    // Initial fetches
    useEffect(() => {
        if (!userRole) {
            return;
        }

        companyMasterStore.fetchCompanyMasterStore('', 'active');
        userCompanyStore.fetchUserCompany('', '', '');
    }, [userRole, companyMasterStore, userCompanyStore]);

    // Auto select company when current user role is admin
    useEffect(() => {
        if (userRole === 'Admin' && currentAdminCompany) {
            handleSelectCompany(currentAdminCompany as unknown as CompanyMaster);
        }
    }, [userRole, currentAdminCompany]);

    const fetchRoleProfileMapping = async (sid: string) => {
        try {
            loader.show();

            const [error, data] = await getRoleProfileMapping({ sid, username: '' });
            if (error) {
                throw error;
            }

            if (data.total_count === 0) {
                setRoleProfileMapping([]);

                throw Error('Roles not found from this company.');
            }

            setRoleProfileMapping(data.result_list);
        } catch (error) {
            fireSwal('Error!', (error as Error).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const fetchUserRoleProfileMapping = async (
        sid: string,
        platformId: string,
        username: string
    ) => {
        try {
            loader.show();

            var user = username.split('@')[0];
            const [error, data] = await getRoleProfileMapping({
                sid,
                platform_id: platformId,
                username: user
            });
            if (error) {
                throw error;
            }

            if (data.total_count === 0) {
                setUserRoleProfileMapping([]);

                //throw Error('Roles not found from this user.');
                return;
            }

            setUserRoleProfileMapping(data.result_list);
        } catch (error) {
            fireSwal('Error!', (error as Error).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const fetchCompanyPlatformMapping = async (sid: string) => {
        try {
            loader.show();

            const [error, data] = await getCompanyPlatformMapping({ sid });
            if (error) {
                throw error;
            }

            if (data.total_count === 0) {
                setCompanyPlatformMapping([]);

                throw Error('Roles not found from this company.');
            }

            setCompanyPlatformMapping(data.result_list);
        } catch (error) {
            fireSwal('Error!', (error as Error).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const handleSelectCompany = (company: SingleValue<CompanyMaster>) => {
        if (!company || !company.sid) {
            return;
        }

        setSelectedCompany(company);
        fetchRoleProfileMapping(company.sid);
        fetchCompanyPlatformMapping(company.sid);
    };

    const handleSelectUser = (user: SingleValue<UserCompany>) => {
        if (
            !selectedCompany ||
            !selectedPlatform ||
            !selectedPlatform.platform_id ||
            !user ||
            !user.citizen_id
        ) {
            return;
        }

        setSelectedUsers(user);
        fetchUserRoleProfileMapping(
            selectedCompany.sid,
            selectedPlatform.platform_id,
            user.citizen_id
        );
    };

    const handleSaveUserRoles = useCallback(
        async (selectedRoles: RoleProfileMapping[]) => {
            if (!selectedCompany || !selectedPlatform || !selectedUsers) {
                return;
            }

            try {
                loader.show();

                const [error] = await addRoleProfileMapping({
                    sid: selectedCompany.sid,
                    platform_id: selectedPlatform?.platform_id,
                    user_id: selectedUsers.citizen_id.split('@')[0],
                    profile_id_list: selectedRoles.map(role => role.profileid)
                });
                if (error) {
                    throw error;
                }

                fireSwal(
                    'Success',
                    `แก้ไข Role ผู้ใช้ ${selectedUsers.citizen_id ?? 'Unknown'} สำเร็จ`,
                    'success'
                );
            } catch (error) {
                const responseError = JSON.parse((error as Error).message) as ResponseError;
                Swal.fire('Error', responseError.message, 'error');
            } finally {
                loader.hide();
                fetchUserRoleProfileMapping(
                    selectedCompany.sid,
                    selectedPlatform.platform_id,
                    selectedUsers.citizen_id
                );
            }
        },
        [selectedCompany, selectedUsers]
    );

    const handleSelectPlatform = (platform: SingleValue<CompanyPlatformMapping>) => {
        if (!selectedCompany || !platform || !platform.platform_id) {
            return;
        }

        setSelectedPlatform(platform);
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
            });
        },
        []
    );

    return {
        userRole,
        companyOptions,
        userOptions,
        selectedUsers,
        selectedCompany,
        showModalERP,
        showModalWorkflow,
        roleProfileMapping,
        userRoleProfileMapping,
        setShowModalERP,
        setShowModalWorkflow,
        handleSelectCompany,
        handleSelectUser,
        handleSaveUserRoles,
        platformOptions,
        selectedPlatform,
        handleSelectPlatform
    };
}

export default AssignRoleProfileModel;
