import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from '../../../models';
import {
    CompanyMaster,
    CompanyMasterRequest,
    UserCompanyRequest,
    UserCompanyResponse
} from '../schemas';
import { getAll, getUserCompany } from '../datasources/companyMaster.datasource';

export async function getCompanyMaster(
    params: CompanyMasterRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<CompanyMaster>>> {
    try {
        const data = (await getAll(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getUserCompanyByCompany(
    params: UserCompanyRequest
): Promise<DatasourceReturnType<ResponseError, UserCompanyResponse[]>> {
    try {
        const data = (await getUserCompany(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
