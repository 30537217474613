import React, { useMemo } from 'react';
import { UserInformationResponse } from '../../../models/citizen/Citizen';
type Props = {
  userInformation: UserInformationResponse;
  copyUserInformation: UserInformationResponse;
  handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
function SignType(props: Props) {
  const {
    userInformation
  } = props;
  const {
    sign_type
  } = userInformation;
  const signTypeRadioButtons = useMemo(() => [{
    ref: React.createRef<HTMLInputElement>(),
    label: '1. Level 1 : ใช้ลายเซ็นต์ โดยไม่ต้องขอ OTP',
    defaultChecked: sign_type?.includes('mode1') ?? false,
    value: 'mode1'
  }, {
    ref: React.createRef<HTMLInputElement>(),
    label: '2. Level 2 : ใช้ลายเซ็นต์ โดยมีการใช้ OTP',
    defaultChecked: sign_type?.includes('mode2') ?? false,
    value: 'mode2'
  }, {
    ref: React.createRef<HTMLInputElement>(),
    label: '3. Level 3 : ใช้ลายเซ็นต์ โดยมีการใช้ OTP และมี Personal CA',
    defaultChecked: sign_type?.includes('mode3') ?? false,
    value: 'mode3'
  }], [sign_type]);
  return <React.Fragment>
            <p className="text-lg font-medium">Sign Type</p>
            <hr />

            <div className="flex flex-col">
                {signTypeRadioButtons.map(button => <div key={button.label} className="flex gap-2">
                        <input type="radio" name="sign_type" defaultChecked={button.defaultChecked} value={button.value} />
                        <p className="m-0">{button.label}</p>
                    </div>)}
            </div>
        </React.Fragment>;
}
export default SignType;