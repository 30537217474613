/**
 * @description This component is only use under `RoleObjectMappingModal`.
 */

import { forwardRef, useImperativeHandle } from 'react';
import { Button as ChakraButton, ButtonGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Button } from 'common/materials';
import { MainProgram } from 'models/role/MainProgram';
import { ProfileObjectMapping } from 'models/role/ProfileObjectMapping';
import { getMainProgramLabel } from './RoleObjectMappingModal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
export type ManageActivityAndPlantModalRefHandle = {
  getCurrentChangedProgramsMap: () => Map<string, ProfileObjectMapping>;
};
type Props = {
  isOpen: boolean;
  selectedProgram?: MainProgram | ProfileObjectMapping;
  onClose: () => void;
};
const ManageActivityAndPlantModal = forwardRef<ManageActivityAndPlantModalRefHandle, Props>((props, ref) => {
  const {
    isOpen,
    selectedProgram,
    onClose
  } = props;
  const [selectedActivities, setSelectedActivities] = useState(new Set<string>());

  // Temporarily save changed (this will free when `RoleObjectMappingModal` close. Be careful)
  const changedProgramsMapRef = useRef(new Map<string, ProfileObjectMapping>());

  // Prepare rendering (collapse this sh*t, I warn you)
  const buttons = useMemo(() => [{
    key: 'S',
    label: 'Activity'
  }, {
    key: 'C',
    label: 'Plant'
  }], []);
  const radioButtons = useMemo(() => [{
    key: '01',
    label: 'Create'
  }, {
    key: '02',
    label: 'Change'
  }, {
    key: '03',
    label: 'Display'
  }, {
    key: '04',
    label: 'Copy'
  }, {
    key: '05',
    label: 'Delete'
  }, {
    key: '06',
    label: 'Print'
  }, {
    key: '07',
    label: 'Cancel'
  }, {
    key: '08',
    label: 'Release'
  }, {
    key: '10',
    label: 'Reverse'
  }], []);
  useEffect(() => {
    if (!isOpen) {
      setSelectedActivities(new Set());
      return;
    }
    if (!selectedProgram) {
      return;
    }

    // Find changed first then apply the origin one
    const program = changedProgramsMapRef.current.get(selectedProgram.parentobjectid + selectedProgram.objectid) ?? (selectedProgram as ProfileObjectMapping);
    const activities = (!program.objectvalue || program.objectvalue === '*' ? radioButtons.map(button => button.key).join(',') : program.objectvalue).split(',').map(activity => activity.trim()).filter(activity => !(activity > '10'));
    setSelectedActivities(new Set(activities));
  }, [isOpen, selectedProgram]);
  useImperativeHandle(ref, () => ({
    getCurrentChangedProgramsMap: () => changedProgramsMapRef.current
  }), []);
  const handleCheckAll = useCallback(() => {
    if (selectedActivities.size === radioButtons.length) {
      setSelectedActivities(new Set());
    } else {
      setSelectedActivities(new Set(radioButtons.map(button => button.key)));
    }
  }, [selectedActivities]);
  const handleChangeCheckbox = useCallback((activityKey: string) => {
    if (!selectedActivities.has(activityKey)) {
      selectedActivities.add(activityKey);
    } else {
      selectedActivities.delete(activityKey);
    }
    setSelectedActivities(new Set(selectedActivities));
  }, [selectedActivities]);
  const handleClickSave = useCallback(() => {
    if (!selectedProgram) {
      throw new Error('Oops! something may be not right.');
    }

    // If selected program is activity type
    if (selectedProgram.objecttype === 'S') {
      const changedSelectedProgram = ({
        ...selectedProgram,
        objectvalue: selectedActivities.size === radioButtons.length ? '*' : Array.from(selectedActivities).sort().join(',')
      } as ProfileObjectMapping);
      const {
        parentobjectid,
        objectid
      } = changedSelectedProgram;

      // Set to local map in this component. When you want to use, just call `getCurrentChangedProgramsMap`
      // (don't forget to pass ref)
      changedProgramsMapRef.current.set(parentobjectid + objectid, changedSelectedProgram);
    }

    // No need to clear `selectedActivities` state
    // Because `useEffect` above handle that already XD
    onClose();
  }, [selectedProgram, selectedActivities]);
  return <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                {selectedProgram && <ModalContent className={'max-w-[95dvw] transition-transform'}>
                        <ModalHeader>
                            <p>Manage Main Program</p>
                            <p className="text-base font-medium">
                                Main Program{' '}
                                <span className="text-primary-900">
                                    {getMainProgramLabel(selectedProgram)}
                                </span>
                            </p>
                        </ModalHeader>
                        <ModalBody className="flex flex-1 flex-col space-y-4 p-6 pt-0">
                            <ButtonGroup isAttached>
                                {buttons.map(button => <ChakraButton key={button.label} variant="outline" className={twMerge('leading-none', button.key === selectedProgram.objecttype ? 'bg-primary-900 text-white' : 'text-neutral-400')}>
                                        {button.label}
                                    </ChakraButton>)}
                            </ButtonGroup>
                            {selectedProgram.objecttype === 'S' ? <div className="flex w-72 flex-col rounded-md border border-zinc-300 [&_label:not(:first-child)]:border-t">
                                    <label htmlFor="*" className="inline-flex gap-2 border-zinc-300 px-2 py-1 transition-colors [&:has(input:checked)]:border-primary-50 [&:has(input:checked)]:bg-primary-100">
                                        <input type="checkbox" name="activity" id="*" checked={selectedActivities.size === radioButtons.length} className="peer outline-none checked:accent-primary-900" onChange={handleCheckAll} />
                                        <span className="flex-1 peer-checked:text-primary-900">
                                            * - All
                                        </span>
                                    </label>
                                    {radioButtons.map(button => <label key={button.label} htmlFor={button.label} className="inline-flex gap-2 border-zinc-300 px-2 py-1 transition-colors [&:has(input:checked)]:border-primary-50 [&:has(input:checked)]:bg-primary-100">
                                            <input type="checkbox" name="activity" id={button.label} checked={selectedActivities.has(button.key)} className="peer outline-none checked:accent-primary-900" onChange={() => handleChangeCheckbox(button.key)} />
                                            <span className="flex-1 peer-checked:text-primary-900">
                                                {button.key} - {button.label}
                                            </span>
                                        </label>)}
                                </div> : <div>Manage Plant</div>}
                        </ModalBody>
                        <ModalFooter className="gap-2">
                            <Button onClick={onClose}>Cancel</Button>
                            <Button className="bg-primary-900 text-white" onClick={handleClickSave}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>}
            </Modal>;
});
ManageActivityAndPlantModal.displayName = 'ManageActivityAndPlantModal';
export default ManageActivityAndPlantModal;