import EXPAND_ARROW_BLACK from './icons/expand-arrow-black.svg';
import EXPAND_ARROW_ORANGE from './icons/expand-arrow-orange.svg';
import EXPAND_ARROW_WHITE from './icons/expand-arrow-white.svg';
import ORDER_TO_SYSTEM_COLLECTION from './icons/order-to-system-collection.svg';
import BOTTOM_ARROW_ICON from './icons/bottom-arrow-icon.svg';
import INVOICE_REQUEST_MANAGEMENT from './icons/invoice-request-management.svg';
import ACCOUNTING_FINACICAL_SYSTEM from './icons/accounting-financial-system.svg';
import VECTOR from './icons/vector.svg';
import VECTOR_BULE from './icons/vector-bule.svg';
import VECTOR_GRAY from './icons/vector-gray.svg';
import STAYLINKED from './icons/staylinked.svg';
import PROFILEEDIT from './icons/profile-Edit.svg';
import ANNOUNCE from './icons/announce.svg';
import NEW_TASK from './icons/new-task.svg';
import SQUARED_MENU from './icons/squared-menu.svg';
import USER_REGISTER from './icons/user-register.svg';
import SETTING from './icons/settings.svg';
import ANNOUNCEMENT from './icons/announcement.svg';
import NOTIFICATION from './icons/Notification.svg';
import PLAY_ICON from './icons/play.png';
import ICON_CURSOR_CLICK from './icons/icon-cursor-click.png';

export default {
    EXPAND_ARROW_BLACK,
    EXPAND_ARROW_ORANGE,
    ORDER_TO_SYSTEM_COLLECTION,
    BOTTOM_ARROW_ICON,
    INVOICE_REQUEST_MANAGEMENT,
    ACCOUNTING_FINACICAL_SYSTEM,
    VECTOR,
    VECTOR_BULE,
    VECTOR_GRAY,
    STAYLINKED,
    PROFILEEDIT,
    ANNOUNCE,
    SQUARED_MENU,
    NEW_TASK,
    USER_REGISTER,
    SETTING,
    ANNOUNCEMENT,
    NOTIFICATION,
    EXPAND_ARROW_WHITE,
    PLAY_ICON,
    ICON_CURSOR_CLICK
};
