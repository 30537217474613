import 'react-quill/dist/quill.snow.css';
import { useCallback, useMemo, useRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { twMerge } from 'tailwind-merge';
import { formats } from './configs';
type Props = Pick<ReactQuillProps, 'value' | 'onChange'> & {
  className?: string;
  editorClassName?: string;
};
function QuillEditor(props: Props) {
  const {
    value,
    className,
    editorClassName,
    onChange
  } = props;
  const quillRef = useRef<ReactQuill>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const modules = useMemo(() => ({
    toolbar: {
      container: [['bold', 'italic', 'underline', 'strike'],
      // toggled buttons
      ['blockquote', 'code-block'], [{
        header: 1
      }, {
        header: 2
      }],
      // custom button values
      [{
        list: 'ordered'
      }, {
        list: 'bullet'
      }], [{
        script: 'sub'
      }, {
        script: 'super'
      }],
      // superscript/subscript
      [{
        indent: '-1'
      }, {
        indent: '+1'
      }],
      // outdent/indent
      [{
        direction: 'rtl'
      }],
      // text direction

      [{
        size: ['small', false, 'large', 'huge']
      }],
      // custom dropdown
      [{
        header: [1, 2, 3, 4, 5, false]
      }], [{
        color: []
      }, {
        background: []
      }], [{
        align: []
      }], ['link', 'image', 'video'], ['clean']],
      handlers: {
        video: () => videoInputRef.current?.click()
      }
    }
  }), []);
  const insertEmbed = (url: string, type: 'image' | 'video') => {
    const quill = quillRef.current?.editor;
    if (!quill) {
      return;
    }
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, type, url, 'user');
    quill.setSelection(range.index + 1, 0);
    quill.focus();
  };
  const handleUploadVideo = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const url = URL.createObjectURL(files[0]);
    insertEmbed(url, 'video');
  }, []);
  return <div className={className}>
            <input ref={videoInputRef} type="file" accept="video/*" onChange={handleUploadVideo} className="hidden" />
            <ReactQuill ref={quillRef} theme="snow" formats={formats} modules={modules} value={value} onChange={onChange} placeholder="Type something..." className={twMerge('[&>.ql-container]:rounded-b-md [&>.ql-toolbar]:rounded-t-md', editorClassName)} />
        </div>;
}
export default QuillEditor;