import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/company/CompanyApplicationMapping';

// Load UserCompany
interface LoadCompanyApplicationMapping {
  type: 'LOAD_COMPANYAPPLICATIONMAPPING';
}
interface LoadedCompanyApplicationMapping {
  type: 'LOADEDCOMPANYAPPLICATIONMAPPING';
  response: Models.resGetCompanyApplicationMapping;
  status: number;
  statusMessage: string;
}
interface FailLoadedCompanyApplicationMapping {
  type: 'LOADCOMPANYAPPLICATIONMAPPINGFAIL';
  status: number;
  statusMessage: any;
}
interface ClearLoadedCompanyApplicationMapping {
  type: 'CLEARLOADEDCOMPANYAPPLICATIONMAPPING';
}
type KnownAction = LoadCompanyApplicationMapping | LoadedCompanyApplicationMapping | FailLoadedCompanyApplicationMapping | ClearLoadedCompanyApplicationMapping;
export const actionCreators = {
  // GET
  requestGetCompanyApplicationMapping: (check: boolean, method: string, citizen_id: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.CompanyApplication && check && method == 'GET') {
      console.log('call actionCreators : requestGetCompanyApplicationMapping');
      fetch(`/v1/company/application_mapping?citizen_id=` + citizen_id, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
      }).then(response => {
        return response;
      }).then(data => {
        if (!data.ok) {
          data.json().then(err => {
            console.log(err.message);
            dispatch({
              type: 'LOADCOMPANYAPPLICATIONMAPPINGFAIL',
              status: 204,
              statusMessage: err.message
            });
          });
        } else {
          data.json().then(data => {
            dispatch({
              type: 'LOADEDCOMPANYAPPLICATIONMAPPING',
              response: data,
              status: 200,
              statusMessage: ''
            });
          });
        }
      });
      dispatch({
        type: 'LOAD_COMPANYAPPLICATIONMAPPING'
      });
    } else if (method == 'CLEAR') {
      dispatch({
        type: 'CLEARLOADEDCOMPANYAPPLICATIONMAPPING'
      });
    }
  }
};
const unloadedStateGet: Models.CompanyApplicationMappingState = {
  isLoadGetCompanyApplicationMapping: false,
  statusGetCompanyApplicationMapping: 0
};
export const reducer: Reducer<Models.CompanyApplicationMappingState> = (state: Models.CompanyApplicationMappingState | undefined, incomingAction: Action): Models.CompanyApplicationMappingState => {
  if (state == undefined) {
    return unloadedStateGet;
  }
  const action = (incomingAction as KnownAction);
  switch (action.type) {
    // GET
    case 'LOAD_COMPANYAPPLICATIONMAPPING':
      return {
        isLoadGetCompanyApplicationMapping: false,
        statusGetCompanyApplicationMapping: 0
      };
    case 'LOADEDCOMPANYAPPLICATIONMAPPING':
      return {
        isLoadGetCompanyApplicationMapping: true,
        responseGetCompanyApplicationMapping: (action.response as any),
        statusGetCompanyApplicationMapping: action.status
      };
    case 'LOADCOMPANYAPPLICATIONMAPPINGFAIL':
      return {
        isLoadGetCompanyApplicationMapping: true,
        responseGetCompanyApplicationMapping: action.statusMessage,
        statusGetCompanyApplicationMapping: action.status
      };
    case 'CLEARLOADEDCOMPANYAPPLICATIONMAPPING':
      return {
        isLoadGetCompanyApplicationMapping: false,
        messageGetCompanyApplicationMapping: "",
        statusGetCompanyApplicationMapping: 0
      };
    default:
      return state;
  }
};