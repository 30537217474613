import React, { useState, useCallback, useEffect } from 'react';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineClose } from 'react-icons/ai';
import { OverlayPortal } from '../../../common/components';
type Props = {
  sources: string[];
  initialIndex?: number;
  onClose?: () => void;
};
function ImageGalleryModal(props: Props) {
  const {
    sources,
    initialIndex,
    onClose
  } = props;
  const imageLength = sources.length;
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex ?? 0);
  useEffect(() => {
    const rootEl = (document.getElementById('root') as HTMLDivElement);
    rootEl.classList.add('overflow-hidden');
    return () => rootEl.classList.remove('overflow-hidden');
  }, []);
  const handleRotationImage = useCallback((nextIndex: number) => {
    nextIndex %= imageLength;
    if (nextIndex < 0) {
      setCurrentImageIndex(imageLength - 1);
      return;
    }
    setCurrentImageIndex(nextIndex);
  }, []);
  return <OverlayPortal>
            <div className="fixed inset-0 z-[9999] flex flex-col bg-black/90 backdrop-blur-[2px]">
                <div className="absolute top-0 z-20 flex w-full cursor-pointer justify-end bg-black/20">
                    <span className="p-2" onClick={onClose}>
                        <AiOutlineClose size={24} color="white" />
                    </span>
                </div>

                <div className="relative flex flex-1 justify-center">
                    {sources[currentImageIndex].includes('.mp4') ? <React.Fragment>
                                <video className="max-h-[calc(100vh_-_116px)]" controls autoPlay muted>
                                    <source src={sources[currentImageIndex]} type="video/mp4" />
                                </video>
                            </React.Fragment> : <img src={sources[currentImageIndex]} alt="Test-Image" className="max-h-[calc(100vh_-_116px)]" />}

                    <div className="absolute left-2 top-1/2 flex cursor-pointer select-none items-center rounded-full bg-black/20" onClick={() => handleRotationImage(currentImageIndex - 1)}>
                        <AiFillCaretLeft color="white" size={40} className="relative right-0.5" />
                    </div>
                    <div className="absolute right-2 top-1/2 flex cursor-pointer select-none items-center rounded-full bg-black/20" onClick={() => handleRotationImage(currentImageIndex + 1)}>
                        <AiFillCaretRight color="white" size={40} className="relative left-0.5" />
                    </div>
                </div>

                {/* Preview gallery */}
                <div className="flex gap-x-3 overflow-x-auto p-2">
                    {sources.map((image, index) => <div key={image + index} className="h-[100px] w-[100px] overflow-hidden rounded-md border border-blue-400">
                            {image.includes('.mp4') ? <React.Fragment>
                                        <video className="h-full w-full object-contain" autoPlay={false}>
                                            <source src={image} type="video/mp4" />
                                        </video>
                                    </React.Fragment> : <img src={image} alt={'image-' + (index + 1)} className="h-full w-full object-contain" />}
                        </div>)}
                </div>
            </div>
        </OverlayPortal>;
}
export default ImageGalleryModal;