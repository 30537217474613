import React from 'react';
import { arrGetCompanyApplicationMapping } from '../../models/company/CompanyApplicationMapping';
import AccordionLayout from './Accordion/AccordionLayout';
type Props = {
  dataSelectedOpject: arrGetCompanyApplicationMapping[];
};
function ExpansionApplication(props: Props) {
  const {
    dataSelectedOpject
  } = props;
  return <div>
            {dataSelectedOpject.map((dataObj, index) => <AccordionLayout key={index} header={dataObj.platform_name} contentData={dataObj.application_list} />)}
        </div>;
}
export default ExpansionApplication;