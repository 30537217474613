import { block as _block$ } from "million/react";
import React from 'react';
import { observer } from 'mobx-react';
import ClientSystemDownloadViewModel from './ClientSystemDownload.viewmodel';
const M$ClientSystemDownload = function ClientSystemDownload() {
  const {
    selectedCompany,
    setSelectedCompany,
    userCompanyOption,
    userClientSystem
  } = ClientSystemDownloadViewModel();
  return <div className="flex flex-col items-start">
            <div className="flex flex-1 items-center">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black">
                    <span className="text-[20px] font-medium leading-6 text-white">1</span>
                </div>
                <span className="ml-[10px]">Download FGUI เพื่อติดตั้งที่เครื่อง</span>
            </div>
            <div className="flex flex-col pl-[50px]">
                <a href="https://fgui.s3.ap-southeast-1.amazonaws.com/F1STD5_PRD.exe" target="_blank" rel="nofollow noreferrer noopener">
                    <div className="bg-light text-primary mt-3 rounded-[8px] border-[2px] p-[10px]">
                        <span>Download FGUI PRD for Windows 64 bit</span>
                    </div>
                </a>
                <a href="https://fgui.s3.ap-southeast-1.amazonaws.com/F1STD5_UAT.exe" target="_blank" rel="nofollow noreferrer noopener">
                    <div className="bg-light text-primary mt-2 rounded-[8px] border-[2px] p-[10px]">
                        <span>Download FGUI UAT for Windows 64 bit</span>
                    </div>
                </a>
            </div>
            <div className="mt-10 flex flex-1 items-center">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black">
                    <span className="text-[20px] font-medium leading-6 text-white">2</span>
                </div>
                <span className="ml-[10px]">
                    Download Server Profile ของบริษัทคุณ เพื่อติดตั้งที่เครื่อง Client
                </span>
            </div>
            <div className="flex flex-col pl-[50px]">
                <div className="pt-3">
                    <label className="ml-1">เลือกบริษัท</label>
                    <select className="flex flex-1 rounded-md border-2 p-1 " onChange={e => {
          setSelectedCompany(e.target.value);
        }} value={selectedCompany}>
                        <option value={''}>กรุณาเลือกบริษัท</option>
                        {userCompanyOption.map((optionData, index) => <option key={index} value={optionData.value}>
                                {optionData.label}
                            </option>)}
                    </select>
                    {userClientSystem && userClientSystem.length > 0 && userClientSystem.map((clientItemdata, index) => <div className="mt-[10px] ml-[5px]" key={index}>
                                <a href={clientItemdata.download_url} target="_blank" rel="nofollow noreferrer noopener">
                                    <span>
                                        Download Server Profile ({clientItemdata.system_port})
                                    </span>
                                </a>
                            </div>)}
                </div>
            </div>
            <div className="mt-[50px] flex flex-col pl-[50px]">
                <div className="justify-content-center flex">
                    <div style={{
          borderBottom: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: 'grey',
          width: '75%'
        }}></div>
                </div>
                <span className="ml-[10px] mt-[10px]">
                    <b>วิธีการติดตั้ง focusOne Client Application</b>
                </span>
                <span className="justify-content-center ml-[10px] mt-[10px] flex">
                    <p>
                        &emsp; focusOne ERP มีการ Download program 2 ส่วน คือ
                        <br />
                        &emsp;&emsp; <b>ข้อ 1</b> focusOne GUI ทำการ Download เพื่อเป็นโปรแกรมของ
                        User / Client ใช้งานทุกเครื่อง เพื่อติดต่อกับ Database ข้อมูลหลัก มี 2
                        Version คือ
                        <br />
                        &emsp;&emsp;&emsp; Version ที่ 1 : Download FGUI PRD for Windows 64 bit
                        <br />
                        &emsp;&emsp;&emsp; Version ที่ 2 : Download FGUI UAT for Windows 64 bit
                        <br />
                        &emsp;&emsp; <b>ข้อ 2</b> focusOne Server Profile ทำการ Download ที่เครื่อง
                        Server Client ทุกเครื่อง User / Client จำเป็นต้องลงโปรแกรม เพื่อติดต่อกับ
                        Server ของบริษัท
                        <br />
                    </p>
                </span>
                <span className="ml-[10px] mt-[10px]">
                    <a target="_blank" href="https://f1-help.thailanderp.com/Help/HelpSubmodule.aspx?cid=00027&hcode=00001&hid=2ea05c8e-e801-42fa-8c0f-9419c8cd453a&xlevel=0">
                        เอกสารคู่มือ
                    </a>
                </span>
            </div>
        </div>;
};
const ClientSystemDownload = M$ClientSystemDownload;
export default observer(ClientSystemDownload);