import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import { AiOutlineMenu } from 'react-icons/ai';
import { Icons } from 'assets';
import { useStore, useUserApplicationMapping } from 'common/hooks';
import Accordion from './Accordion/Accordion';
function SideNavigation() {
  const containerElementRef = useRef<HTMLDivElement>(null);
  const accessToken = useReadLocalStorage<string>('SSO_AUTH');
  const {
    subDashboardStore,
    userCompanyStore
  } = useStore();
  const dataSubDashBoard = subDashboardStore.groupedApplications;
  const {
    citizenId
  } = userCompanyStore;
  const userApplicationProvider = useUserApplicationMapping();
  const [showMenu, setShowMenu] = useState(false);
  const [showSandbox, setShowSandbox] = useState(false);
  useEffect(() => {
    fetch('/v1/show-sandbox').then(res => res.json()).then(result => {
      const show = result.show_sandbox === 'true' ? true : false;
      setShowSandbox(show);
    }, error => {});
  }, []);
  useEffect(() => {
    if (!accessToken) {
      return;
    }
    fetchSubDashboardMenu();
  }, []);
  useEffect(() => {
    const containerElement = containerElementRef.current;
    if (!containerElement) {
      return;
    }
    const handleClick = (event: MouseEvent) => {
      if (!event.target) {
        return;
      }
      const target = (event.target as HTMLElement);
      if (!containerElement.contains(target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);
  useEffect(() => {
    if (dataSubDashBoard.length > 0) {
      userApplicationProvider.setUserApplication(dataSubDashBoard);
    }
  }, [dataSubDashBoard]);
  const fetchSubDashboardMenu = async () => {
    await subDashboardStore.fetchSubDashboardMenu(citizenId);
  };
  return <div ref={containerElementRef} className={twMerge('absolute z-10 flex h-full w-[calc(100vw_-_62px)] flex-col overflow-y-auto bg-neutral-100 shadow-md transition-transform duration-300 sm:w-[416px] lg:relative lg:translate-x-0 lg:shadow-none', showMenu ? 'translate-x-0' : '-translate-x-full')}>
            <div className="absolute left-[99.8%] top-0 z-10 cursor-pointer rounded-r-md bg-white/70 p-[10px] shadow-md lg:hidden" onClick={() => setShowMenu(prevState => !prevState)}>
                <AiOutlineMenu size={22} className="text-neutral-800" />
            </div>

            {dataSubDashBoard && dataSubDashBoard.map((subDashBoardData, index) => {
      if (subDashBoardData.groupItems.length > 0) {
        return <Accordion key={index} indexKey={index} header={subDashBoardData.groupName} dashboardDataItem={subDashBoardData.groupItems} />;
      } else if (subDashBoardData.groupItems.length === 0 && subDashBoardData.groupName.includes('SANDBOX') && showSandbox) {
        return <div key={index} className="flex items-center">
                                <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                                    <Link to={'/sandbox-register'} className="text-[14px] font-bold leading-[21px] text-black">
                                        <div className="ml-[10px] flex">
                                            <img src={Icons.VECTOR_GRAY} />
                                            <span className="ml-[16px]">
                                                {subDashBoardData.groupName}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>;
      }
    })}

            {dataSubDashBoard.length === 0 && showSandbox && <div className="flex items-center">
                    <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                        <Link to={'/sandbox-register'} className="text-[14px] font-bold leading-[21px] text-black">
                            <div className="ml-[10px] flex">
                                <img src={Icons.VECTOR_GRAY} />
                                <span className="ml-[16px]">F1 ERP WEB SANDBOX</span>
                            </div>
                        </Link>
                    </div>
                </div>}

            <div className="flex items-center">
                <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                    <Link to={'/client-system-program'} className="text-[14px] font-bold leading-[21px] text-black">
                        <div className="ml-[10px] flex">
                            <img src={Icons.VECTOR_GRAY} />
                            <span className="ml-[16px]">DOWNLOAD FGUI FOR CLIENT SYSTEM</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>;
}
export default observer(SideNavigation);