import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/group/PlatformInApplication';
import * as Models from '../../models/group/PlatformInApplication';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
type Store = Models.PlatformInApplicationState & typeof Stores.actionCreators;
class ModalPlatformInApplicationComponent extends React.PureComponent<any, {
  pageLoad: boolean;
  isOpen: boolean;
  isSubmitClick: boolean;
}, Store> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageLoad: true,
      isOpen: false,
      isSubmitClick: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.registerClick = this.registerClick.bind(this);
    this.acceptClick = this.acceptClick.bind(this);
    this.rejectClick = this.rejectClick.bind(this);
  }
  private toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
    if (!this.state.isOpen) {
      this.props.afterCloseModal();
    }
  }
  private registerClick(id: string) {
    Swal.fire({
      title: 'Are you sure to Register?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.isConfirmed) {
        this.props.requestFunction(this.state.pageLoad, this.props.appUUID, id, "POST");
        this.setState({
          isSubmitClick: true
        });
      } else {}
    });
  }
  private acceptClick(id: string) {
    Swal.fire({
      title: 'Are you sure to Accept?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.isConfirmed) {
        this.props.requestFunction(this.state.pageLoad, this.props.appUUID, id, "PUT");
        this.setState({
          isSubmitClick: true
        });
      } else {}
    });
  }
  private rejectClick(id: string) {
    Swal.fire({
      title: 'Are you sure to Reject?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.isConfirmed) {
        this.props.requestFunction(this.state.pageLoad, this.props.appUUID, id, "DELETE");
        this.setState({
          isSubmitClick: true
        });
      } else {}
    });
  }
  setTdStatus = (item: Models.PlatformInApplication) => {
    if (item.link_active_status == "unregister") {
      return <Button color="primary" data-serid={item.platform_id} onClick={() => this.registerClick(item.platform_id)}>Register</Button>;
    } else if (item.link_active_status == "pending") {
      return <div>
                <Button color="primary" data-serid={item.platform_id} onClick={() => this.acceptClick(item.platform_id)}>Accept</Button>{' '}
                <Button color="danger" data-serid={item.platform_id} onClick={() => this.rejectClick(item.platform_id)}>Reject</Button>
            </div>;
    } else if (item.link_active_status == "active") {
      return item.link_active_status;
    }
  };
  render() {
    if (this.props.isOpenModal) {
      this.toggleModal();
      this.props.requestFunction(this.state.pageLoad, this.props.appUUID, "", "GET");
      this.props.afterOpenModal();
    }
    if (this.props.isLoading) {
      console.log(this.props.response);
      if (this.props.isRegister && this.state.isSubmitClick) {
        console.log(this.props.response);
        console.log("message - " + this.props.message);
        this.setState({
          isSubmitClick: false
        });
      }
    }
    return <div>
                <Modal isOpen={this.state.isOpen} style={{
        maxWidth: "1200px"
      }}>
                    <ModalHeader toggle={this.toggleModal}>Platform In Application <br /> Application Name:{' ' + this.props.appName}</ModalHeader>
                    <ModalBody>
                        <div style={{
            overflow: "auto",
            maxHeight: "calc(100vh - 270px)",
            minHeight: "200px"
          }}>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr style={{
                  background: "rgba(9, 132, 227, 1)",
                  color: "#fff",
                  fontSize: '10pt'
                }}>
                                        <td>Platform ID</td>
                                        <td>Platform Name</td>
                                        <td>Created By</td>
                                        <td>Created On</td>
                                        <td>Status</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.isLoading && this.props.response.result_list != undefined && this.props.response.result_list.map((item: Models.PlatformInApplication) => <tr key={item.platform_id} className={'table-tbody table-tr-' + item.link_active_status}>
                                            <td>{item.platform_id}</td>
                                            <td>{item.platform_name}</td>
                                            <td>{item.created_by}</td>
                                            <td>{item.created_on}</td>
                                            <td>
                                                {this.setTdStatus(item)}
                                            </td>
                                        </tr>)}
                                    {!this.props.isLoading ? <tr><td colSpan={8} style={{
                    background: "rgba(209, 250, 221, 1)",
                    color: "black",
                    fontSize: '10pt',
                    textAlign: "center"
                  }}>Loading...</td></tr> : ''}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>;
  }
}
export default connect((state: ApplicationState) => state.platformInApplication, Stores.actionCreators)(ModalPlatformInApplicationComponent);