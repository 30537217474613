import React, { useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../common/materials';
import { TaskInbox } from '../../models/link/Task';
import { TaskStore } from '../../models/taskStore/Task';
import CloseTaskView, { CloseTaskHandle } from './CloseTask.view';

// link task
// type Props = {
//     isOpen?: boolean;
//     onClose?: () => void;
//     selectedTask?: TaskInbox;
// };

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  selectedTask?: TaskStore;
};
function CloseTaskModal(props: Props) {
  const {
    isOpen,
    onClose,
    selectedTask
  } = props;
  const ref = useRef<CloseTaskHandle>(null);
  return <Modal className="max-w-2xl" closeOnClickOverlay isOpen={isOpen} onClose={onClose}>
            <ModalHeader>ยืนยันการยกเลิก Information</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <CloseTaskView onClose={onClose} ref={ref} selectedTask={selectedTask} />
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button outline onClick={onClose} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={() => {
        ref.current?.handleClickCloseTaskActivity();
      }} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default CloseTaskModal;