import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';
import { TaskCommnet } from '../../../models/taskStore/Task';
import { extractAccessToken } from '../../../common/helpers';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import { BsReplyAll } from 'react-icons/bs';
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  keyId: string;
  comment: TaskCommnet[];
  submitComment: (keyId: string, message: string) => void;
  handleEventComment: (type: string, commentId: string, keyId: string, message?: string) => void;
};
function UserCommentEvent(props: Props) {
  const {
    isOpen,
    onClose,
    keyId,
    comment,
    submitComment,
    handleEventComment
  } = props;
  const [message, setMessage] = useState<string>();
  const [typeSend, setTypeSend] = useState<string>('create');
  const [taskCommentId, setTaskCommentId] = useState<string>();
  const accessToken = extractAccessToken();
  const handleSubmitComment = () => {
    submitComment(keyId, message!);
  };
  const onClickEventComment = (type: string, commentId: string) => {
    handleEventComment(type, commentId, keyId, message);
  };
  return <Modal noPortal fullscreen closeOnClickOverlay isOpen={isOpen} onClose={onClose} className="translate-y-0 transition-transform">
            <ModalHeader>Comment</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-x-auto">
                <div className="h-full w-full rounded-md border-2">
                    {comment && comment.map((value, index) => <div className=" flex flex-col" key={index}>
                                {value.ref_comment_id === '' && <React.Fragment>
                                        <div className="flex flex-row justify-between">
                                            <span className="text-[16px]">{value.created_by}</span>
                                            <span className="text-[13px]">{value.created_on}</span>
                                        </div>
                                        <div className="flex flex-row justify-between px-[3%]">
                                            <span className="text-[14px]">{value.message}</span>
                                            <div className="flex items-center justify-center gap-2 text-center">
                                                <BsReplyAll onClick={() => {
                  setTaskCommentId(value.comment_id!);
                  setMessage(value.created_by!);
                  setTypeSend('reply');
                }} />
                                                {accessToken.username === value.created_by && <React.Fragment>
                                                        <BiEditAlt onClick={() => {
                    setTaskCommentId(value.comment_id!);
                    setMessage(value.message!);
                    setTypeSend('edit');
                  }} />
                                                        <MdDeleteOutline onClick={() => {
                    onClickEventComment('delete', value.comment_id!);
                  }} />
                                                    </React.Fragment>}
                                            </div>
                                        </div>
                                    </React.Fragment>}
                                {comment.filter(ref => ref.ref_comment_id === value.comment_id).map((refComment, refIndex) => <React.Fragment key={refIndex}>
                                            <div className="flex flex-row justify-between px-[3%]">
                                                <span className="text-[16px]">
                                                    {refComment.created_by}
                                                </span>
                                                <span className="text-[13px]">
                                                    {refComment.created_on}
                                                </span>
                                            </div>
                                            <div className="flex flex-row justify-between px-[6%]">
                                                <span className="text-[14px]">
                                                    {refComment.message}
                                                </span>
                                                <div className="flex items-center justify-center gap-2 text-center">
                                                    <BsReplyAll onClick={() => {
                  setTaskCommentId(refComment.comment_id!);
                  setMessage(refComment.created_by!);
                  setTypeSend('reply');
                }} />
                                                    {accessToken.username === refComment.created_by && <React.Fragment>
                                                            <BiEditAlt onClick={() => {
                    setTaskCommentId(refComment.comment_id!);
                    setMessage(refComment.message!);
                    setTypeSend('edit');
                  }} />
                                                            <MdDeleteOutline onClick={() => {
                    onClickEventComment('delete', refComment.comment_id!);
                  }} />
                                                        </React.Fragment>}
                                                </div>
                                            </div>
                                        </React.Fragment>)}
                            </div>)}
                </div>
                <div className="flex flex-row items-center justify-center gap-2 pt-2">
                    <input type="text" value={message} className="h-8 w-full rounded-lg border border-black text-[16px]" onChange={e => setMessage(e.target.value)} />
                    <Button outline onClick={() => {
          if (typeSend === 'create') {
            handleSubmitComment();
          } else {
            onClickEventComment(typeSend, taskCommentId!);
          }
        }} className="text-neutral-400">
                        Send
                    </Button>
                </div>
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button outline onClick={onClose} className="text-neutral-400">
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>;
}
export default UserCommentEvent;