import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as ConfirmApplicationModel from '../../models/platform/ConfirmStatusApplication';
interface RequestFunctionAction {
  type: 'REQUEST_CONFIRMAPPLICATION';
}
interface ReceiveFunctionAction {
  type: 'RECEIVE_CONFIRMAPPLICATION';
  message: number;
}
type KnownAction = RequestFunctionAction | ReceiveFunctionAction;
export const actionCreators = {
  requestFunction: (pageload: boolean, uuid: string, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    console.log("uuid -" + uuid + " || method -" + method);
    if (appState && appState.confirmStatusApplication && pageload == true && method == "PUT") {
      console.log("call put");
      fetch(`/v1/apps/` + uuid + `/approve`, {
        method: 'PUT'
      }).then(response => dispatch({
        type: 'RECEIVE_CONFIRMAPPLICATION',
        message: response.status
      })).then(data => {}).then(error => {});
      dispatch({
        type: 'REQUEST_CONFIRMAPPLICATION'
      });
    }
    if (appState && appState.confirmStatusApplication && pageload == true && method == "DELETE") {
      console.log("call delete");
      fetch(`/v1/apps/` + uuid + `/reject`, {
        method: 'DELETE'
      }).then(response => dispatch({
        type: 'RECEIVE_CONFIRMAPPLICATION',
        message: response.status
      })).then(data => {
        console.log("data -->");
        console.log(data);
      }).then(error => {});
      dispatch({
        type: 'REQUEST_CONFIRMAPPLICATION'
      });
    }
  }
};
const unloadedState: ConfirmApplicationModel.ConfirmApplicationState = {
  isLoading: false,
  message: 400
};
export const reducer: Reducer<ConfirmApplicationModel.ConfirmApplicationState> = (state: ConfirmApplicationModel.ConfirmApplicationState | undefined, incomingAction: Action): ConfirmApplicationModel.ConfirmApplicationState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = (incomingAction as KnownAction);
  switch (action.type) {
    case 'REQUEST_CONFIRMAPPLICATION':
      return {
        isLoading: false,
        message: 400
      };
    case 'RECEIVE_CONFIRMAPPLICATION':
      return {
        isLoading: true,
        message: action.message
      };
    default:
      return state;
  }
};