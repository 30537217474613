const colorPalettes = {
    primary: {
        50: '#fef4e1',
        100: '#fee2b4',
        200: '#fed083',
        300: '#fdbd51',
        400: '#fdae2c',
        500: '#fea00d',
        600: '#fa9409',
        700: '#f48405',
        800: '#ee7503',
        900: '#e55c00'
    },
    secondary: {
        50: '#e6eff4',
        100: '#c8d8dd',
        200: '#a7bcc5',
        300: '#86a2ac',
        400: '#6d8d99',
        500: '#537a88',
        600: '#486b77',
        700: '#3a5761',
        800: '#2c444d',
        900: '#1c2f36'
    },
    success: {
        50: '#e4f7e8',
        100: '#beeac7',
        200: '#91dda2',
        300: '#5cd07c',
        400: '#22c55e',
        500: '#00ba3e',
        600: '#00aa35',
        700: '#009828',
        800: '#00871b',
        900: '#006703'
    },
    'line-green': {
        '50': '#e3f7e7',
        '100': '#bdebc5',
        '200': '#8fde9f',
        '300': '#57d276',
        '400': '#06c756',
        '500': '#00bc33',
        '600': '#00ac2a',
        '700': '#009a1c',
        '800': '#00890a',
        '900': '#006900'
    },
    danger: {
        50: '#ffeaed',
        100: '#ffc9ce',
        200: '#f09493',
        300: '#e6696a',
        400: '#ef4444',
        500: '#f32e26',
        600: '#e42027',
        700: '#d31121',
        800: '#c6011a',
        900: '#b7000c'
    },
    cyan: {
        50: '#e2f6fd',
        100: '#b6e8fa',
        200: '#87d9f7',
        300: '#5ac9f4',
        400: '#3cbdf2',
        500: '#26b2f1',
        600: '#20a3e2',
        700: '#1990cf',
        800: '#177fbb',
        900: '#0e5f99'
    }
};

export default colorPalettes;
