import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Stores from '../store/platform/Pending';
import * as Models from '../models/platform/Pending';
type Store = Models.PenState & typeof Stores.actionCreators;
class Home extends React.Component<any, {
  pageLoad: boolean;
}, Store> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageLoad: true
    };
  }
  public componentDidMount() {

    //this.ensureDataFetched();
  }
  private ensureDataFetched() {
    this.props.requestFunction(this.state.pageLoad);
    this.state = {
      pageLoad: false
    };
  }
  render() {
    // return (<div>
    ///     <SubDashboardComponent />
    //</div>)
    return <Redirect to='/feed-task' />;
    // return (
    //     <div style={{ paddingLeft: 'unset' }}>
    //         <h4 className="mb-3" style={{ fontWeight: 'bold' }}>Pending Application</h4>
    //         {this.props.isLoading && this.renderApplication(this.props.response)}

    //         <hr />
    //         <h4 className="mb-3" style={{ marginTop: '10px', fontWeight: 'bold' }}>Pending Service</h4>
    //         {this.props.isLoading && this.renderService(this.props.response)}
    //     </div>
    // );
  }

  renderApplication(data: Models.app) {
    return <React.Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr style={{
            background: "rgba(9, 132, 227, 1)",
            color: "#fff",
            fontSize: '10pt'
          }}>
                            <th>Application UUID</th>
                            <th>Application Name</th>
                            <th>Application ID</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.isLoading && this.props.response.result_list != undefined && this.props.response.app_list.map((item: Models.app) => <tr key={item.app_uuid} className="table-tbody">
                                <td>{item.app_uuid}</td>
                                <td>{item.app_name}</td>
                                <td>{item.app_id}</td>
                                <td>{item.email}</td>
                                <td>{item.active_status}</td>
                                <td>{item.created_by}</td>
                                <td>{item.created_on}</td>
                            </tr>)}
                    </tbody>
                </table>
            </React.Fragment>;
  }
  renderService(data: Models.PendingService) {
    return <React.Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr style={{
            background: "rgba(9, 132, 227, 1)",
            color: "#fff",
            fontSize: '10pt'
          }}>
                            <th>Application Name</th>
                            <th>Service ID</th>
                            <th>Service Name</th>
                            <th>Created By</th>
                            <th>Created On</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.isLoading && this.props.response.result_list != undefined && this.props.response.service_list.map((item: Models.PendingService) => <tr key={item.service_id} className="table-tbody">
                                <td>{item.app_name}</td>
                                <td>{item.service_id}</td>
                                <td>{item.service_name}</td>
                                <td>{item.created_by}</td>
                                <td>{item.created_on}</td>
                                <td>{item.link_active_status}</td>
                            </tr>)}
                    </tbody>
                </table>
            </React.Fragment>;
  }
}
export default connect((state: ApplicationState) => state.pending, Stores.actionCreators)(Home);