import { UserInformation } from 'models/authentication/Authentication';
import { checkExp, parseJwt } from 'modules/common';

export function extractAccessToken(token?: string): UserInformation {
    const auth = token ?? localStorage.getItem('SSO_AUTH');

    if (!auth) {
        return {} as UserInformation;
    }

    const credentials = JSON.parse(auth);
    const accessToken = credentials.access_token;
    const jwt = parseJwt(accessToken);
    const extra = JSON.parse(jwt.extra);

    checkExp(jwt);

    return extra;
}
