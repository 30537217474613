import { Badge, Button, Divider, IconButton, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverTrigger } from '@chakra-ui/react';
import { Images } from 'assets';
type Props = {
  userName: string;
  userRole: string;
  email: string;
  onClickNavigateToProfile: () => void;
  onClickLogout: () => void;
  onClickOpenSupportModal: () => void;
};
function ProfilePopover(props: Props) {
  const {
    userName,
    userRole,
    email,
    onClickNavigateToProfile,
    onClickLogout,
    onClickOpenSupportModal
  } = props;
  return <Popover isLazy placement="bottom-end">
            <PopoverTrigger>
                <IconButton size="md" variant="unstyled" aria-label="Help" className="group ml-1" icon={<img src={Images.USER} alt="User" />} />
            </PopoverTrigger>
            <PopoverContent className="w-64">
                <PopoverCloseButton />
                <PopoverBody className="flex flex-col items-center text-sm">
                    <div className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-primary-500 shadow-lg">
                        <img src={Images.USER} className="h-12 w-12 object-contain" />
                    </div>
                    <p className="font-bold">{userName}</p>
                    <Badge colorScheme="primary" className="rounded-full px-2">
                        {userRole}
                    </Badge>
                    <p className="mt-4">{email}</p>

                    <Button size="sm" className="mt-4 w-3/4 rounded-full bg-primary-900 text-xs text-white hover:bg-primary-700" onClick={onClickNavigateToProfile}>
                        Manage Account
                    </Button>
                </PopoverBody>
                <PopoverFooter className="flex border-0 p-0">
                    <Button size="sm" className="flex-1 rounded-none text-xs" onClick={onClickLogout}>
                        Logout
                    </Button>
                    <Divider orientation="vertical" />
                    <Button size="sm" className="flex-1 rounded-none text-xs" onClick={onClickOpenSupportModal}>
                        Support
                    </Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>;
}
export default ProfilePopover;