import axios from 'axios';
import {
    UserCompanyRequest,
    UserCompanyResponse,
    UpdateUserCompanyMappingRequest,
    AddUserRequest,
    IAddInviteRequest
} from '../schemas';

export async function getAll(params: UserCompanyRequest) {
    return axios.get<UserCompanyResponse>('/v1/user/company', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function updateUsersStatus(
    params: UpdateUserCompanyMappingRequest
) {
    return axios.put<string>('/v2/user/company', params, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestAddUser(body: AddUserRequest) {
    return axios.post('/v2/user/company', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestAddUsers(body: AddUserRequest[]) {
    return axios.post('/v1/user/company', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestAddInvite(body: IAddInviteRequest[]) {
    return axios.post('v1/user/company/admin/invite', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    })
}