import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError } from '../../../models';
import {
    requestCreateTaskGroup,
    requestDeleteTaskGroup,
    requestEditTaskGroup,
    requestGetTaskGroup,
    requestSwapTaskGroupIndex
} from '../datasources/taskGroup.datasource';
import {
    CreateTaskGroupRequest,
    EditTaskGroupRequest,
    SwapTaskGroupIndexRequest,
    TaskGroupCommonResponse,
    TaskGroupMapping
} from '../schemas';

export async function getTaskGroup(
    filter: string
): Promise<DatasourceReturnType<ResponseError, TaskGroupMapping[]>> {
    // params: GetTaskStoreRequest
    try {
        const data = (await requestGetTaskGroup(filter)).data;
        // console.log('[Debug] get task group success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] get task group error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function createTaskGroup(
    params: CreateTaskGroupRequest
): Promise<DatasourceReturnType<ResponseError, TaskGroupCommonResponse>> {
    try {
        const data = (await requestCreateTaskGroup(params)).data;
        // console.log('[Debug] create task group success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] create task group error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function editTaskGroup(
    params: EditTaskGroupRequest
): Promise<DatasourceReturnType<ResponseError, TaskGroupCommonResponse>> {
    try {
        const data = (await requestEditTaskGroup(params)).data;
        // console.log('[Debug] edit task group success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] edit task group error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function DeleteTaskGroup(
    group_id: string
): Promise<DatasourceReturnType<ResponseError, TaskGroupCommonResponse>> {
    try {
        const data = (await requestDeleteTaskGroup(group_id)).data;
        // console.log('[Debug] delete task group success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] delete task group error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function swapTaskGroupIndex(
    params: SwapTaskGroupIndexRequest
): Promise<DatasourceReturnType<ResponseError, TaskGroupCommonResponse>> {
    try {
        const data = (await requestSwapTaskGroupIndex(params)).data;
        // console.log('[Debug] swap task group success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] swap task group error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
