export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}
;
export async function getRedirectSSOUrl() {
  localStorage.removeItem('SSO_AUTH');
  await window.location.reload();
}
export function checkExp(e: any) {
  var exp = new Date(e.exp * 1000);
  var date_now = Date.now();
  var now = new Date(date_now);
  if (exp < now) {
    getRedirectSSOUrl();
  }
}
export function parseDateString(dateString: string) {
  if (dateString.length === 0) {
    return '';
  }
  const date = dateString.substring(6, 8);
  const month = dateString.substring(4, 6);
  const year = dateString.substring(0, 4);
  return `${date}/${month}/${year}`;
}
export function parseTimeString(timeString: string) {
  if (timeString.length === 0) return '';
  const hh = timeString.substring(0, 2);
  const mm = timeString.substring(2, 4);
  const ss = timeString.substring(4, 6);
  return `${hh}:${mm}:${ss}`;
}