import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as FApplication from '../../models/application/FApplication';
interface RequestFunctionAction {
  type: 'REQUEST_FAPPLICATION';
  version: number;
}
interface ReceiveFunctionAction {
  type: 'RECEIVE_FAPPLICATION';
  model: FApplication.ResponseFApplication;
  version: number;
}
type KnownAction = RequestFunctionAction | ReceiveFunctionAction;
export const actionCreators = {
  requestFunctionFApp: (version: number, method: string, body: FApplication.FApplication, app_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && method == "GET") {
      fetch(`/v1/fapp`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
        }
      }).then(response => (response.json() as Promise<FApplication.ResponseFApplication>)).then(data => {
        dispatch({
          type: 'RECEIVE_FAPPLICATION',
          model: data,
          version: version + 2
        });
      }).then(error => {
        dispatch({
          type: 'REQUEST_FAPPLICATION',
          version: version + 1
        });
      });
    }

    //if (appState && appState.file && method == "POST") {
    //    console.log(localStorage.getItem('DMS_APPLICATION'))
    //    fetch(`/v1/access-management`, {
    //        method: 'POST',
    //        headers: {
    //            'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(body),
    //    })
    //        .then(response => response.json() as Promise<AccessManagement.ResponseAccessManagement>)
    //        .then(data => {
    //            dispatch({ type: 'RECEIVE_ACCESSMANAGEMENT', model: data, version: version + 3 });
    //        })
    //        .then(error => {
    //            dispatch({ type: 'REQUEST_ACCESSMANAGEMENT', version: version + 1 });
    //        });
    //}

    //if (appState && appState.file && method == "PUT") {

    //    fetch(`/v1/access-management`, {
    //        method: 'PUT',
    //        headers: {
    //            'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(body),
    //    })
    //        .then(response => response.json() as Promise<AccessManagement.ResponseAccessManagement>)
    //        .then(data => {
    //            dispatch({ type: 'RECEIVE_ACCESSMANAGEMENT', model: data, version: version + 4 });
    //        })
    //        .then(error => {
    //            dispatch({ type: 'REQUEST_ACCESSMANAGEMENT', version: version + 1 });
    //        });
    //}
  }
};

const unloadedState: FApplication.CFApplicationState = {
  isLoadingFApp: false,
  responseFApp: undefined,
  versionFApp: 0
};
export const reducer: Reducer<FApplication.CFApplicationState> = (state: FApplication.CFApplicationState | undefined, incomingAction: Action): FApplication.CFApplicationState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = (incomingAction as KnownAction);
  switch (action.type) {
    case 'REQUEST_FAPPLICATION':
      return {
        isLoadingFApp: false,
        versionFApp: action.version
      };
    case 'RECEIVE_FAPPLICATION':
      return {
        isLoadingFApp: true,
        responseFApp: action.model,
        versionFApp: action.version
      };
    default:
      return state;
  }
};