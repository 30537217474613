import React from 'react';
import { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Badge } from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';
import { FaThLarge, FaThList } from 'react-icons/fa';
import { useLocalStorage } from 'usehooks-ts';
import { useLoader } from 'common/hooks';
import { useStore } from 'common/hooks';
import { getTaskLink } from 'data/link/respositories/taskRepository';
import { Task } from 'models/link/Link';
import UserDashboardComponentMain from 'components/user/UserDashboardComponentMain';
import TicketCard from './UserTickedCardComponent';
const TABS = ([{
  label: 'All',
  value: 'all',
  color: 'bg-[#e8e9bb]'
}, {
  label: 'On Hand',
  value: 'กำลังดำเนินการ',
  color: 'bg-[#bbd0e9]'
}, {
  label: 'Cancel',
  value: 'ถูกยกเลิก',
  color: 'bg-[#e9bfbb]'
}, {
  label: 'Finish',
  value: 'ปิดงานแล้ว',
  color: 'bg-[#bbe9c9]'
}] as const);
function UserTickedComponent() {
  const loader = useLoader();
  const {
    userCompanyStore
  } = useStore();
  const [tasks, setTasks] = useState<Task[]>();
  const [filter, setFilter] = useState<(typeof TABS)[number]['value']>('all');
  const [displayStyle, setDisplayStyle] = useLocalStorage<'grid' | 'list'>('USER-SUPPORT-DISPLAY_TICKET_STYLE', 'grid');
  const deferredFilter = useDeferredValue(filter);
  useEffect(() => {
    fetchTaskLink(userCompanyStore.email);
  }, []);
  const filteredTasks = useMemo(() => {
    if (!tasks) {
      return null;
    }
    if (deferredFilter === 'all') {
      return tasks;
    }
    return tasks.filter(task => task.activityOverviewStatus === deferredFilter);
  }, [tasks, deferredFilter]);
  const fetchTaskLink = useCallback(async (email: string) => {
    try {
      loader.show();
      const [, data] = await getTaskLink(email);
      if (data === null) {
        return;
      }
      setTasks(data);
    } catch {} finally {
      loader.hide();
    }
  }, []);
  return <div className="flex flex-1 flex-col justify-center gap-4">
            <div className="flex items-center justify-between">
                <Badge variant="subtle" colorScheme="primary" className="w-fit rounded-md px-2 py-1 text-xs">
                    {userCompanyStore.email}
                </Badge>

                <div className="relative flex w-fit overflow-hidden rounded-md border [&_>_button:not(:last-child)]:border-r">
                    <button className={twMerge('flex flex-1 items-center justify-center px-3 text-stone-300 transition-colors', displayStyle === 'grid' && 'bg-zinc-300 text-[#777777]')} onClick={() => setDisplayStyle('grid')}>
                        <FaThLarge />
                    </button>
                    <button className={twMerge('flex flex-1 items-center justify-center px-3 py-2 text-stone-300 transition-colors', displayStyle === 'list' && 'bg-zinc-300 text-[#777777]')} onClick={() => setDisplayStyle('list')}>
                        <FaThList />
                    </button>
                </div>
            </div>

            {displayStyle === 'grid' ? <React.Fragment>
                    <div className="relative flex w-fit overflow-hidden rounded-md border [&_>_button:not(:last-child)]:border-r">
                        {TABS.map(tab => <button key={tab.label} className={twMerge('px-4 py-2 transition-colors', filter === tab.value && tab.color)} onClick={() => setFilter(tab.value)}>
                                {tab.label}
                            </button>)}
                    </div>

                    <div className="grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-2">
                        {filteredTasks && filteredTasks.length > 0 ? filteredTasks.map((ticket, index) => <div key={index}>
                                    <TicketCard ticket={ticket} />
                                </div>) : <div className="col-span-2 text-center">No content...</div>}
                    </div>
                </React.Fragment> : <UserDashboardComponentMain />}
        </div>;
}
export default observer(UserTickedComponent);