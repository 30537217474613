import React from 'react';
import { observer } from 'mobx-react';
import SandboxRegisterViewModel from './SandboxRegister.viewmodel';
import { Button } from '../../../common/materials';
function SandboxRegister() {
  const {
    onClickCreateSandboxUser
  } = SandboxRegisterViewModel();
  return <React.Fragment>
            <Button onClick={() => onClickCreateSandboxUser()}>
                ขอเข้าร่วม Sandbox
            </Button>
        </React.Fragment>;
}
export default observer(SandboxRegister);