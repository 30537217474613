import React, { useEffect, useRef, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { OverlayPortal } from '../components';
import { twMerge } from 'tailwind-merge';
type OwnProps = {
  noPortal?: boolean;
  closeOnClickOverlay?: boolean;
  fullscreen?: boolean;
  center?: boolean;
  isOpen?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
};
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & OwnProps;
function Div(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return <div {...props} />;
}
function Modal(props: Props) {
  const {
    noPortal,
    closeOnClickOverlay,
    center,
    fullscreen,
    isOpen,
    showCloseButton,
    className,
    children,
    onClose,
    ...divProps
  } = props;
  const dimOverlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {}, [isOpen]);
  if (!isOpen) {
    return null;
  }
  const Wrapper = noPortal ? Div : OverlayPortal;
  return <Wrapper className="absolute">
            {/* Dimming overlay */}
            <div ref={dimOverlayRef} className={twMerge('fixed inset-0 z-20 bg-neutral-900/40 transition-opacity')} onClick={() => closeOnClickOverlay && onClose && onClose()} />

            {/* Modal content */}
            <div {...divProps} ref={modalRef} className={twMerge('fixed left-1/2 z-30 flex -translate-x-1/2 flex-col rounded-md bg-white p-6 shadow-md', 'w-[100%] max-w-[1280px] sm:w-[80%]', fullscreen ? 'bottom-12' : '', center && !fullscreen ? 'top-[calc(50%_+_51px)] -translate-y-1/2 lg:top-[calc(50%_+_49px)]' : 'top-[calc(3rem_+_51px)] lg:top-[calc(3rem_+_49px)]', className)}>
                {showCloseButton && <button className="absolute right-4 top-4" onClick={onClose}>
                        <BsX size={24} color="#000" />
                    </button>}

                {children}
            </div>
        </Wrapper>;
}
export default Modal;