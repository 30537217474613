import { makeAutoObservable, runInAction } from 'mobx';

import RootStore from '../Root.store';
import { addUserToSandboxPlatform } from '../../data/sandbox/repositories/sandboxRepository';

class SandboxStore {
    private _message: string = '';

    public get message() {
        return this._message;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchAddSandboxUser(sid: string, citizen_id: string) {
        const [error, responseAddSandboxUser] = await addUserToSandboxPlatform({
            sid,
            citizen_id
        });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._message = responseAddSandboxUser;
        });
    }
}

export default SandboxStore;
