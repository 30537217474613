import Swal from 'sweetalert2';

import { useLoader } from '../../common/hooks';
import { cancelTaskActivity } from '../../data/link/respositories/taskRepository';

import { cancelTask } from '../../data/taskStore/repositories/taskRepository';

import { CancelTaskViewProps } from './CancelTask.view';

function CancelTaskViewModel({ onClose, selectedTask }: CancelTaskViewProps) {
    const loader = useLoader();

    const handleClickCancelTaskActivity = async () => {
        try {
            loader.show();

            if (!selectedTask) {
                return;
            }

            // ? link-task
            // const [error, data] = await cancelTaskActivity(selectedTask.key);

            // ? app-pool-task-store
            const { key_id } = selectedTask;
            const [error, data] = await cancelTask({ key_id });

            if (error) {
                throw error;
            }
            if (!data) {
                return;
            }

            if (data.result) {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Cancel task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) {
                    onClose && onClose();
                    window.location.reload();
                }
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();

            onClose && onClose();
        }
    };

    return { handleClickCancelTaskActivity };
}

export default CancelTaskViewModel;
