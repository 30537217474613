import Swal from 'sweetalert2';

import { useLoader } from '../../common/hooks';
import { releaseTask } from '../../data/taskStore/repositories/taskRepository';
import { ReleaseTaskRequest } from '../../data/taskStore/schemas';

import { ReleaseTaskViewProps } from './ReleaseTask.view';

function ReleaseTaskViewModel({ onClose, selectedTask, onRefresh }: ReleaseTaskViewProps) {
    const loader = useLoader();

    const handleClickReleaseTask = async () => {
        try {
            loader.show();

            if (!selectedTask) {
                return;
            }

            const { key_id, is_release } = selectedTask;

            const params: ReleaseTaskRequest = {
                key_id,
                is_release: is_release ? 'false' : 'true'
            };

            const [error, data] = await releaseTask(params);

            if (error) {
                throw error;
            }

            if (data.result) {
                const message = is_release ? 'Unreleased' : 'Release';
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `${message} task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });
                if (result.isConfirmed) {
                    console.log('jj confirm refresh');
                    onClose && onClose();
                    // onRefresh && onRefresh();
                    window.location.reload();
                }
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();

            // onClose && onClose();
            // onRefresh && onRefresh();
        }
    };

    return { handleClickReleaseTask };
}

export default ReleaseTaskViewModel;
