import * as React from 'react';
import { Route, Redirect } from 'react-router';
export const PrivateRoute = ({
  component: Component,
  ...rest
}: any) => <Route {...rest} render={props => localStorage.getItem('SSO_AUTH') ? <Component {...props} /> : <Redirect to={{
  pathname: '/login',
  state: {
    from: props.location
  }
}} />} />;