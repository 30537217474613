import React from 'react';
import { nameof } from '../../../common/helpers';
import { UserInformationResponse } from '../../../models/citizen/Citizen';
import ChangeDetectionInput from './ChangeDetectionInput';
type Props = {
  userInformation: UserInformationResponse;
  copyUserInformation: UserInformationResponse;
  handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
function NationInfo(props: Props) {
  const {
    userInformation,
    copyUserInformation,
    handleChangeCopyUserInformation
  } = props;
  const {
    nation_id,
    passport_id,
    driver_license_id,
    nationality
  } = copyUserInformation;
  const comparers = ([userInformation, copyUserInformation] as [UserInformationResponse, UserInformationResponse]);
  return <React.Fragment>
            <p className="text-lg font-medium">Nation Information</p>
            <hr />

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput comparers={comparers} compareKey="nation_id" label="Nation ID" name={nameof('nation_id', userInformation)} placeholder="Nation ID" value={nation_id ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput comparers={comparers} compareKey="passport_id" label="Passport ID" name={nameof('passport_id', userInformation)} placeholder="Passport ID" value={passport_id ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
            </div>

            <div className="flex space-x-4 ">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput comparers={comparers} compareKey="driver_license_id" label="Driver License ID" name={nameof('driver_license_id', userInformation)} placeholder="Driver License ID" value={driver_license_id ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput comparers={comparers} compareKey="nationality" label="Nationality" name={nameof('nationality', userInformation)} placeholder="Nationality" value={nationality ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
            </div>
        </React.Fragment>;
}
export default NationInfo;