import React from 'react';
import { UserInformationResponse } from '../../../models/citizen/Citizen';
import ChangeDetectionTextArea from './ChangeDetectionTextArea';
import { nameof } from '../../../common/helpers';
type Props = {
  userInformation: UserInformationResponse;
  copyUserInformation: UserInformationResponse;
  handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
function AddressInfo(props: Props) {
  const {
    userInformation,
    copyUserInformation,
    handleChangeCopyUserInformation
  } = props;
  const {
    house_address,
    official_address,
    office_address,
    other_address1,
    other_address2
  } = copyUserInformation;
  const comparers = ([userInformation, copyUserInformation] as [UserInformationResponse, UserInformationResponse]);
  return <React.Fragment>
            <p className="text-lg font-medium">Address Information</p>
            <hr />

            <div className="flex flex-col space-y-2">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionTextArea rows={3} comparers={comparers} compareKey="house_address" label="House Address" name={nameof('house_address', userInformation)} value={house_address ?? ''} onChange={(handleChangeCopyUserInformation as any)} className="flex-1" />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionTextArea rows={3} comparers={comparers} compareKey="official_address" label="Official Address" name={nameof('official_address', userInformation)} value={official_address ?? ''} onChange={(handleChangeCopyUserInformation as any)} className="flex-1" />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionTextArea rows={3} comparers={comparers} compareKey="office_address" label="Office Address" name={nameof('office_address', userInformation)} value={office_address ?? ''} onChange={(handleChangeCopyUserInformation as any)} className="flex-1" />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionTextArea rows={3} comparers={comparers} compareKey="other_address1" label="Other Address 1" name={nameof('other_address1', userInformation)} value={other_address1 ?? ''} onChange={(handleChangeCopyUserInformation as any)} className="flex-1" />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionTextArea rows={3} comparers={comparers} compareKey="other_address2" label="Other Address 2" name={nameof('other_address2', userInformation)} value={other_address2 ?? ''} onChange={(handleChangeCopyUserInformation as any)} className="flex-1" />
                </div>
            </div>
        </React.Fragment>;
}
export default AddressInfo;