import React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;
function Th(props: Props) {
  const {
    className,
    children,
    ...thProps
  } = props;
  return <th {...thProps} className={twMerge('border border-zinc-300 p-2.5 text-left font-medium', className)}>
            {children}
        </th>;
}
export default Th;