import USER from './images/user.png';
import P_NOK_USER from './images/p-nok-user.jpg';
import ADD_ASSIGN_ROLE_PROFILE from './images/add-assign-role-profile.png';
import REMOVE_ASSIGN_ROLE_PROFILE from './images/remove-assign-role-profile.png';
import ADD_ALL_ASSIGN_ROLE_PROFILE from './images/add-all-assign-role-profile.png';
import REMOVE_ALL_ASSIGN_ROLE_PROFILE from './images/remove-all-assign-role-profile.png';
import APP_POOL_INGRESS from './images/app_pool_ingress.jpg';

export default {
    USER,
    P_NOK_USER,
    ADD_ASSIGN_ROLE_PROFILE,
    REMOVE_ASSIGN_ROLE_PROFILE,
    ADD_ALL_ASSIGN_ROLE_PROFILE,
    REMOVE_ALL_ASSIGN_ROLE_PROFILE,
    APP_POOL_INGRESS
};
