import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { extractAccessToken } from 'common/helpers/local-storage';
import { useLoader } from 'common/hooks';
import { UserInformationResponse } from 'models/citizen/Citizen';
import { isEmailValid } from 'common/helpers';
type AttachFilesType = Record<keyof Pick<UserInformationResponse, 'image' | 'image_signature' | 'digital_signature'>, File | undefined>;
function ProfileViewModel() {
  const loader = useLoader();
  const [userInformation, setUserInformation] = useState<UserInformationResponse>();
  const [copyUserInformation, setChangedCopyUserInformation] = useState<UserInformationResponse>();
  const [attachFiles, setAttachFiles] = useState<AttachFilesType>(({} as AttachFilesType));
  const previewImageBlobUrl = useMemo(() => attachFiles.image && URL.createObjectURL(attachFiles.image), [attachFiles.image]);
  const previewImageSignatureBlobUrl = useMemo(() => attachFiles.image_signature && URL.createObjectURL(attachFiles.image_signature), [attachFiles.image_signature]);
  useEffect(() => {
    fetchCitizen();
  }, []);
  const handleRemoveAttachFile = useCallback((key: keyof AttachFilesType) => {
    setAttachFiles(prevState => ({
      ...prevState,
      [key]: new File([], 'empty-image-profile')
    }));
  }, [setAttachFiles]);
  const handleChangeAttachFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    const key = event.target.name;
    setAttachFiles(prevState => ({
      ...prevState,
      [key]: files[0]
    }));
  }, [setAttachFiles]);
  const handleChangeCopyUserInformation = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const key = event.target.name;
    setChangedCopyUserInformation(prevState => {
      if (!prevState || !(key in prevState)) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: event.target.value
      };
    });
  }, [copyUserInformation]);
  const fetchCitizen = useCallback(async () => {
    try {
      loader.show();
      const data = (await axios.get<UserInformationResponse>('v1/citizen', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        },
        params: {
          sid: 'SSO',
          user_id: extractAccessToken().citizen_id
        }
      })).data;
      setUserInformation(data);
      setChangedCopyUserInformation({
        ...data
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        Swal.fire('Error', error.response?.data, 'error');
      }
    } finally {
      loader.hide();
    }
  }, []);
  const handleSaveChange = useCallback(async () => {
    try {
      loader.show();
      if (!copyUserInformation) {
        throw new Error('เกิดข้อผิดพลาด');
      }
      if (!isEmailValid(copyUserInformation.email ?? '')) {
        throw new Error('รูปแบบอีเมล์ไม่ถูกต้อง');
      }
      const formData = (new FormData() as TypedFormData<UserInformationResponse>);
      const userInformationEntries = (Object.entries(copyUserInformation) as [keyof UserInformationResponse, string | null][]);

      // Append common information
      for (const [key, value] of userInformationEntries) {
        // Skip, if value is null or empty string
        if (!value) {
          continue;
        }
        formData.append(key, value);
      }

      // Append attach files
      for (const key of (['image', 'image_signature', 'digital_signature'] as Array<keyof AttachFilesType>)) {
        const file = attachFiles[key];
        if (file) {
          formData.append(key, file, file.name);
        }
      }
      await axios.post('/v1/citizen', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
      });
      await Swal.fire('Success', 'บันทึกสำเร็จ', 'success');
      fetchCitizen();
    } catch (error) {
      if (error instanceof Error) {
        Swal.fire('Error', error.message, 'error');
      }
    } finally {
      loader.hide();
    }
  }, [copyUserInformation, attachFiles]);
  return {
    previewImageBlobUrl,
    previewImageSignatureBlobUrl,
    userInformation,
    copyUserInformation,
    handleRemoveAttachFile,
    handleChangeCopyUserInformation,
    handleChangeAttachFile,
    handleSaveChange
  };
}
export default ProfileViewModel;