import 'react-datepicker/dist/react-datepicker.css';
import React, { forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import { Input, Label, TextArea } from '../../common/materials';
import { TaskStore } from '../../models/taskStore/Task';
import EditAnnouncementViewModel from './EditAnnouncement.viewmodel';
export type EditAnnouncementHandle = {
  handleClickSaveEditAnnouncement: () => Promise<void>;
};
export type EditAnnouncementViewProps = {
  onClose?: () => void;
  onRefresh?: () => void;
  selectedAnnouncement?: TaskStore;
};
const EditAnnouncementView = forwardRef<EditAnnouncementHandle, EditAnnouncementViewProps>((props, ref) => {
  const {
    announcementForm,
    handleChangeAnnouncementForm,
    handleChangeDateAnnouncementForm,
    handleClickSaveEditAnnouncement
  } = EditAnnouncementViewModel(props);
  const {
    subject,
    start_date_time,
    end_date_time,
    description
  } = announcementForm;
  useImperativeHandle(ref, () => {
    return {
      handleClickSaveEditAnnouncement
    };
  });
  return <React.Fragment>
                <span className="font-semibold text-gray-500">Add Information</span>
                <div className="flex flex-col gap-2 p-2">
                    <Label>Subject</Label>
                    <Input name="subject" value={subject} onChange={handleChangeAnnouncementForm} />
                </div>
                <div className="flex gap-2 p-2">
                    <div className="flex flex-1 flex-col">
                        <Label>Start Date Time</Label>
                        <DatePicker data-name="startDate" selected={start_date_time} onChange={handleChangeDateAnnouncementForm('start_date_time')} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                    </div>
                    <div className="flex flex-1 flex-col">
                        <Label>End Date Time</Label>
                        <DatePicker data-name="endDate" selected={end_date_time} onChange={handleChangeDateAnnouncementForm('end_date_time')} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                    </div>
                </div>
                {/* <div className="flex flex-col gap-2 p-2">
                    <Label>Activity Type</Label>
                    <Input
                        name="activityType"
                        placeholder="announce"
                        onChange={handleChangeAnnouncementForm}
                    />
                 </div> */}
                <div className="flex flex-1 flex-col gap-2 p-2">
                    <Label>Description</Label>
                    <TextArea value={description} name="description" className="flex-1" onChange={handleChangeAnnouncementForm} />
                </div>
            </React.Fragment>;
});
export default EditAnnouncementView;