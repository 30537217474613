import { block as _block$ } from "million/react";
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { cn } from '../helpers';
import { getToken } from '../../data/token/repositories/tokenRepository';
import { arrGetCompanyApplicationMappingMain } from '../../models/company/CompanyApplicationMapping';
import { useLoader, useStore } from '../hooks';
type Props = {
  dataItem: arrGetCompanyApplicationMappingMain;
};
const M$CardApplication = function CardApplication(props: Props) {
  const {
    dataItem
  } = props;
  const isInactive = dataItem.application_status === 'inactive';
  const url = isInactive ? undefined : dataItem.application_ingress;
  const {
    tokenStore,
    userCompanyStore,
    subDashboardStore
  } = useStore();
  //const { token } = tokenStore;
  const {
    citizenId,
    email,
    firstName,
    lastName
  } = userCompanyStore;
  const companyToken = subDashboardStore.selectedCompanyToken;
  const screenLoader = useLoader();
  useEffect(() => {
    getUserInformation('', '', 'active');
  }, []);
  const getUserInformation = useCallback(async (sid: string, citizenId: string, status: string) => {
    await userCompanyStore.fetchUserCompany(sid, citizenId, status);
  }, []);
  const alertNoPermission = async (isInactive: boolean, url = '') => {
    try {
      screenLoader.show();
      if (isInactive) {
        throw new Error('คุณไม่ได้รับอนุญาติให้ใช้แอปพลิเคชันนี้');
      }
      if (!companyToken) {
        throw new Error('ไม่พบบริษัทนี้');
      }
      var userId = citizenId;
      //if (companyToken != '1IbuASmpqIapjmhVfzcLF4XPVY9'
      //    && companyToken != '2KlP5DjCQeyOSgyAH0vNezHzu18') {
      //    userId = citizenId.split('@')[0];
      //}

      const [error, getTokenResponse] = await getToken({
        token: companyToken,
        citizen_id: userId,
        email: email,
        first_name: firstName,
        last_name: lastName
      });

      //console.log("[Debug] token", getTokenResponse[1])
      // const composedUrlWithToken = url + '?token=' + getTokenResponse;
      const composedUrlWithToken = url + (url.indexOf('?') > -1 ? '&token=' : '?token=') + getTokenResponse;
      window.open(composedUrlWithToken);
    } catch (error) {
      if (error instanceof Error) {
        Swal.fire({
          title: '',
          text: error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ตกลง',
          allowOutsideClick: false
        });
      }
    } finally {
      screenLoader.hide();
    }
  };
  return <div tabIndex={0} className={cn('inline-flex flex-col py-[10px] px-[5px] w-[110px] h-[145px] m-[5px] border-2 border-[#E8E8E8] rounded-[6px] group transition-[box-shadow,transform]', dataItem.application_status != 'inactive' ? 'bg-white cursor-pointer hover:shadow-md hover:scale-105 focus:scale-105 focus:shadow-md focus:shadow-gray-500' : 'grayscale cursor-not-allowed bg-[#7A7A7A40]')} onClick={() => alertNoPermission(isInactive, url)}>
            <div className="flex flex-1 flex-col items-center">
                <img className="w-9 h-9" src={dataItem.application_img_url} />
            </div>
            <span className={cn('text-center text-[#0C0B0B] mt-[16px] text-[11px] leading-[15px] whitespace-pre-line break-words group-focus:[text-shadow:_0px_0px_1px_currentColor] transition-[text-shadow]', dataItem.application_status == 'inactive' ? 'text-[#7A7A7A]' : '')}>
                {dataItem.application_name}
            </span>
            <div className="flex flex-1 flex-row items-end justify-center">
                {dataItem.application_status == 'inactive' && <div>
                        <span className="text-[#7f7171] text-[12px] mt-[15px]">
                            INACTIVE
                        </span>
                    </div>}
            </div>
        </div>;
};
const CardApplication = M$CardApplication;
export default observer(CardApplication);