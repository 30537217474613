import React from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';
import { TaskGroup } from '../../../models/taskGroup/TaskGroup';
type AddTaskModalProps = {
  isOpen: boolean;
  addTaskGroup: TaskGroup;
  handleAddTaskGroup: (taskGroup: TaskGroup) => void;
  handleChangeAddTaskGroup: <K extends 'group_id' | 'group_description'>(key: K, value: {
    group_id: string;
    group_description: string;
  }[K]) => void;
  confirmAddTaskGroup: () => Promise<void>;
};
function AddTaskGroupModal(props: AddTaskModalProps) {
  const {
    addTaskGroup,
    isOpen,
    handleAddTaskGroup,
    handleChangeAddTaskGroup,
    confirmAddTaskGroup
  } = props;
  return <Modal isOpen={isOpen}>
            <ModalHeader>Create New Task Group</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <div className="flex flex-row gap-4">
                    <div className="flex w-1/5 flex-col">
                        <Label>Group Id</Label>
                        <Input value={addTaskGroup.group_id} onChange={event => handleChangeAddTaskGroup('group_id', event.target.value)} />
                    </div>

                    <div className="flex flex-1 flex-col">
                        <Label>Group Description</Label>
                        <Input value={addTaskGroup.group_description} onChange={event => handleChangeAddTaskGroup('group_description', event.target.value)} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="mt-4">
                <Button outline onClick={() => {
        handleAddTaskGroup({
          group_index: '',
          group_id: '',
          group_description: ''
        });
      }} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={confirmAddTaskGroup} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default AddTaskGroupModal;