import { makeAutoObservable, runInAction } from 'mobx';
import { getApplicationBuildVersion } from '../../data/version/repositories/versionRepository';
import RootStore from '../Root.store';

class ApplicationBuildVersionStore {
    private _applicationVersion = '';

    public get applicationVersion() {
        return this._applicationVersion;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchApplicationBuildVersion() {
        const [error, responseApplicationVersion] =
            await getApplicationBuildVersion();

        console.log('version', responseApplicationVersion);

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._applicationVersion = responseApplicationVersion.message;
        });
    }
}

export default ApplicationBuildVersionStore;
