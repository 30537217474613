import { block as _block$ } from "million/react";
import React, { useEffect } from 'react';
type Props<T extends readonly string[]> = {
  names: T;
  selectedName?: T[number];
  onSelect: (name: T[number]) => void;
};
const garbageFunction = () => void 0;
const M$ToggleSwitches = function ToggleSwitches(props: Props<T>) {
  const {
    names,
    selectedName,
    onSelect = garbageFunction
  } = props;
  const indicatorWidth = 100 / names.length;
  useEffect(() => {
    if (selectedName) {
      return;
    }
    onSelect(names[0]);
  }, []);
  return <div className="flex relative bg-[#d6e9f7]" style={{
    borderRadius: '12px',
    overflow: 'hidden'
  }}>
            <div className="absolute z-10 bg-[#0970BB] h-[100%]" style={{
      width: `${indicatorWidth}%`,
      transform: `translateX(${100 * names.indexOf(selectedName ?? names[0])}%)`,
      transition: 'transform ease 0.2s'
    }} />

            {names.map((name, index) => <span key={name} className={`flex-1 z-20 inline ml-3 mr-3 p-2 mx-1 ${index > 0 && ''} ${name === (selectedName ?? names[0]) && 'text-white'}`} style={{
      transition: 'color ease 0.2s'
    }} onClick={() => onSelect(name)}>
                    {name}
                </span>)}
        </div>;
};
const ToggleSwitches = M$ToggleSwitches;
export default ToggleSwitches;