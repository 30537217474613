import * as React from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
class PlatformDashboardComponent extends React.Component<any, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {}
  render() {
    return <div>
                <div>
                    <Row>
                        <Col md="3">
                            ระบุ Comany ID
                            </Col>
                        <Col md="6">
                            <Input type="text" />
                        </Col>
                        <Col md="3">
                            <Button color="primary">เชิญ Company</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md="3">
                            ระบุ User ID
                            </Col>
                        <Col md="6">
                            <Input type="text" />
                        </Col>
                        <Col md="3">
                            <Button color="primary">เชิญ User</Button>
                        </Col>
                    </Row>
                </div>
                <br />
                <br />

                <div>
                    <h6>Wait for Active Company</h6>
                    <table className='table table-bordered'>
                        <thead>
                            <tr style={{
              background: "rgba(9, 132, 227, 1)",
              color: "#fff",
              fontSize: '10pt'
            }}>
                                <th>Company Code</th>
                                <th>Company Name</th>
                                <th>Status</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>005</td>
                                <td>mno</td>
                                <td>Pending</td>
                                <td>
                                    <Button color="primary">Active</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

                <div>
                    <h6>Wait for Active User</h6>
                    <table className='table table-bordered'>
                        <thead>
                            <tr style={{
              background: "rgba(9, 132, 227, 1)",
              color: "#fff",
              fontSize: '10pt'
            }}>
                                <th>User Code</th>
                                <th>FirstName</th>
                                <th>LastName</th>
                                <th>Status</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>005</td>
                                <td>qwe</td>
                                <td>asd</td>
                                <td>Pending</td>
                                <td>
                                    <Button color="primary">Active</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>;
  }
}
export default PlatformDashboardComponent;