import React, { forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import { AiFillPlayCircle, AiOutlineCloudUpload, AiOutlineFile } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { PiUserCirclePlusFill } from 'react-icons/pi';
import { QuillEditor } from 'common/components';
import { Input, Label } from 'common/materials';
import { Option } from 'models/taskGroup/TaskGroup';
import { TaskStore } from 'models/taskStore/Task';
import NormalModeViewModel from './NormalMode.viewmodel';
type NormalModeHandle = {
  getNormalFormData: () => Promise<FormData | undefined>;
};
type NormalModeViewProps = {
  onClose?: () => void;
  onRefresh?: () => void;
  selectedTask?: TaskStore;
  taskGroupList: Option[];
};
const NormalModeView = forwardRef<NormalModeHandle, NormalModeViewProps>((props, ref) => {
  const {
    profileInputRef,
    profileUpload,
    setProfileUpload,
    handleChangeProfile,
    task,
    handleChangeTask,
    taskGroupList,
    selectedTaskGroup,
    setSelectedTaskGroup,
    model,
    setModel,
    fileInputRef,
    filesUpload,
    handleDeleteFile,
    handleChangeFile,
    handleClickAddTask,
    getNormalFormData
  } = NormalModeViewModel(props);
  useImperativeHandle(ref, () => {
    return {
      getNormalFormData
    };
  });
  return <React.Fragment>
            <div className="flex flex-row items-center gap-2">
                <div className="flex justify-end">
                    {profileUpload ? <div className="relative p-2">
                            <MdDelete className="absolute -right-1 -top-0 z-20 h-[20px] w-[20px] cursor-pointer text-red-600 hover:animate-bounce" onClick={() => setProfileUpload(undefined)} />
                            <div key={profileUpload.name} className="flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full border bg-white">
                                {profileUpload.name.includes('.jpg') || profileUpload.name.includes('.jpeg') || profileUpload.name.includes('.png') ? <img src={URL.createObjectURL(profileUpload)} alt="image" className="h-full w-full object-cover" /> : undefined}
                            </div>
                        </div> : <PiUserCirclePlusFill className="cursor-pointer " onClick={() => profileInputRef.current?.click()} style={{
          width: '60px',
          height: '60px',
          color: '#A1A1A1'
        }} />}
                </div>
                <input ref={profileInputRef} type="file" onChange={event => handleChangeProfile(event.target.files)} className="hidden" />

                <div className="flex flex-col">
                    <Label>Created By</Label>
                    <Input value={task.created_by} onChange={event => handleChangeTask('created_by', event.target.value)} />
                </div>
            </div>

            <div className="mt-2 flex flex-col">
                <Label>Subject</Label>
                <Input value={task.subject} onChange={event => handleChangeTask('subject', event.target.value)} />
            </div>

            <div className="relative z-50 mt-2 flex flex-col">
                <Label>Group</Label>
                <Select isClearable className="w-[300px] " onChange={selectOption => {
        setSelectedTaskGroup(selectOption);
      }} options={taskGroupList} value={selectedTaskGroup} />
            </div>

            <div className="mt-2 flex flex-1 flex-col">
                <Label>Description</Label>
                <QuillEditor value={model} onChange={setModel} editorClassName="[&>.ql-container]:min-h-[85px]" />
            </div>

            <div className="mt-2 flex gap-2">
                <div className="flex-1">
                    <Label>Attachment</Label>
                    <div className="flex h-8 cursor-pointer items-center justify-center gap-2 rounded-md border border-neutral-300" onClick={() => fileInputRef.current?.click()}>
                        <AiOutlineCloudUpload size={18} />
                        <span className="text-sm text-neutral-500">
                            Drop file to attach or <span className="text-[#E55C00]">browse</span>
                        </span>
                    </div>
                </div>
                <div className="flex-1">
                    <Label>Assignee</Label>
                    <Input />
                </div>
            </div>

            <div className="mt-3 flex flex-wrap gap-2">
                {filesUpload.map((file, index) => <div className="relative">
                        <MdDelete className="absolute -right-2 -top-2 z-20 h-[20px] w-[20px] cursor-pointer text-red-600 hover:animate-bounce" onClick={() => handleDeleteFile(index)} />
                        <div key={file.name} className="flex h-[140px] w-[140px] items-center justify-center overflow-hidden rounded-sm border bg-white">
                            {file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png') ? <img src={URL.createObjectURL(file)} alt="image" className="h-full w-full object-cover" /> : file.name.includes('.mp4') ? <div className="relative">
                                    <video autoPlay loop muted>
                                        <source src={URL.createObjectURL(file)} type="video/mp4" />
                                    </video>
                                    <AiFillPlayCircle className="absolute left-1/2 top-1/2 z-20 h-[32px] w-[32px] -translate-x-1/2 -translate-y-1/2 text-white opacity-80" />
                                </div> : <React.Fragment>
                                    <AiOutlineFile size={36} />
                                    <span className="text-center">{file.name}</span>
                                </React.Fragment>}
                        </div>
                    </div>)}

                <input ref={fileInputRef} type="file" onChange={event => handleChangeFile(event.target.files)} className="hidden" />
            </div>
        </React.Fragment>;
});
export default NormalModeView;