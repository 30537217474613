import { parseResponseError } from '../../../common/helpers';
import {
    DatasourceReturnType,
    ResponseError,
    ResponseList
} from '../../../models';
import {
    ClientSystemProgramRequest,
    ClientSystemProgramResponse
} from '../schemas';

import { get } from '../datasources/clientSystemProgram.datasource';
import { ClientSystemProgram } from '../../../models/clientSystem/clienySystemProgram';

export async function getClientSystemProgram(
    params: ClientSystemProgramRequest
): Promise<
    DatasourceReturnType<
        ResponseError,
        ResponseList<ClientSystemProgram>
    >
> {
    try {
        const data = (await get(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
