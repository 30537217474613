import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as PendingModel from '../../models/platform/Pending';

interface RequestFunctionAction {
    type: 'REQUEST_PENDING';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_PENDING';
    model: PendingModel.ResponsePending;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (pageload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
    
        if (appState && appState.pending && appState.pending.isLoading == false && pageload == true) {
           
            fetch(`/v1/pending`, {
                method: 'GET'
            })
                .then(response => response.json() as Promise<PendingModel.ResponsePending>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_PENDING', model: data });
                })
                .then(error => {
                   
                });

            dispatch({ type: 'REQUEST_PENDING'});
        }
    }
};

const unloadedState: PendingModel.PenState = { isLoading: false, response: undefined };

export const reducer: Reducer<PendingModel.PenState> = (state: PendingModel.PenState | undefined, incomingAction: Action): PendingModel.PenState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_PENDING':
            return {
                isLoading: false
            };
        case 'RECEIVE_PENDING':
            return {
                isLoading: true,
                response: action.model
            };

        default: return state;
    }
};