import * as React from 'react';
class CallbackComponent extends React.Component<any, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    var token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFycVhEaU1QQlZYV1ZuR25CdGJINWUxWGxETiIsInNlciI6IlMwMDEzIiwiYXBwIjoiMUlidUFTbXBxSWFwam1oVmZ6Y0xGNFhQVlk5Iiwia2V5IjoiMWZsU1hRc3dVcnBxcFFGVHVHOUQ0cDE4Q2ZYIiwiZXh0cmEiOiJ7XCJjaXRpemVuX2lkXCI6XCJ3ZjAwMVwiLFwidXNlcl9pZFwiOm51bGx9IiwiZXhwIjoxNjIyMjk2NDc1LCJpYXQiOjE2MTk3MDQ0NzUsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.KWK7CXgbNvDOeyUeLr_UMo4E3FmTV3KdhhxUzamAAQAcdpOt9tTR_a6khHGfFoaRH3XRHg1VECSsLuIsUMB2dYM-r73RvPNmQvgD6_xEs1HmsQttYA8e-Xc_2MZIVSEC9byR99nHTJBvCoVIBFwAE8aMvFLYaDzLvjq4SRJ0JHQ';
    var url_citizen = 'https://sso.sonarinno.com/config/callback?token=' + token;
    //var url_citizen = 'http://localhost:8081/config/callback?token=' + token;
    var myContent = "<iframe style='width: 100%; display: block; height: 100%;' src='" + url_citizen + "' />";
    return <div style={{
      "width": "100 %",
      "display": "block",
      "height": "100%"
    }} dangerouslySetInnerHTML={{
      __html: myContent
    }} />;
  }
}
export default CallbackComponent;