import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { Gifs } from '../../assets';
import LineConnectViewModel from './LineConnect.ViewModel';
function LineConnectView() {
  const {
    fetching,
    isConfirmed,
    error,
    username,
    name,
    userLineID,
    pictureUrl,
    updateUserLineId,
    closeTab
  } = LineConnectViewModel();
  if (!userLineID) {
    return null;
  }
  return <div className="flex h-screen w-screen flex-col justify-center">
            <div className="mx-auto max-w-xs space-y-5 rounded-md p-10 shadow-[0px_0px_8px_0px_rgba(69,69,69,0.2)]">
                {isConfirmed ? <div className="flex flex-col items-center space-y-5">
                        <AiFillCheckCircle size={52} className="text-[#06C755]" />
                        <p className="text-center text-neutral-800">
                            <span className="font-semibold">{username}</span>{' '}
                            สามารถรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line Chat
                        </p>

                        <button className="w-full flex-1 rounded-sm bg-[#06C755] px-4 py-2 text-white shadow-md active:brightness-95 disabled:opacity-60" onClick={closeTab}>
                            ปิด
                        </button>
                    </div> : <React.Fragment>
                        {/* Profile */}
                        <div className="flex flex-col items-center gap-3">
                            <img src={pictureUrl} alt="line-profile-pic" className="h-20 w-20 rounded-full object-cover" />

                            <div className="text-center">
                                <p className="text-sm text-neutral-600">เข้าสู่ระบบด้วยผู้ใช้</p>
                                <p className="font-semibold text-neutral-800">{name}</p>
                            </div>
                        </div>

                        <p className="text-center text-neutral-800">
                            คุณต้องการรับการแจ้งเตือนของ{' '}
                            <span className="font-semibold">{username}</span> ผ่าน Line Chat{' '}
                            <span className="font-semibold">
                                focus
                                <span className="font-extrabold text-[#ffa500]">O</span>
                                ne
                            </span>
                        </p>

                        <div className="flex flex-col gap-2">
                            {fetching ? <img src={Gifs.F1_LOADINGGIF} alt="" className="mx-auto h-12 w-12" /> : <React.Fragment>
                                    {error && <div className="mb-4 flex items-center gap-1 text-red-500">
                                            <CgDanger size={16} className="relative bottom-[1px]" />
                                            <p className="italic">{error}</p>
                                        </div>}
                                    <button disabled={fetching} className="flex-1 rounded-sm bg-[#06C755] px-4 py-2 text-white shadow-md active:brightness-95 disabled:opacity-60" onClick={updateUserLineId}>
                                        ใช่
                                    </button>
                                    <button disabled={fetching} className="flex-1 rounded-sm bg-red-500/95 px-4 py-2 text-white shadow-md active:brightness-95 disabled:opacity-60" onClick={closeTab}>
                                        ไม่ใช่
                                    </button>
                                </React.Fragment>}
                        </div>

                        {/* <a href="#">เข้าสู่ระบบด้วยบัญชีอื่น</a> */}
                    </React.Fragment>}
            </div>
        </div>;
}
export default LineConnectView;