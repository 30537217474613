import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Checkbox, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, useBoolean } from '@chakra-ui/react';
import { FaPen } from 'react-icons/fa';
import { Button, Input, Label, Select } from 'common/materials';
import ChangePasswordModal from './ChangePasswordModal';
import { Employee, EmployeeBranch, EmployeePrefix, GetEmployeeBranchParams, GetEmployeePrefixParams } from 'models/user/Employee';
import { CompanyMaster, CompanyPlatformMapping } from 'data/company/schemas';
import { useLoader } from 'common/hooks';
import { canChangePassword, getEmployeeBranch, getEmployeePrefix } from 'data/user/repositories/employee.repository';
import Swal from 'sweetalert2';
import { ResponseError } from 'models';
import { SingleValue } from 'react-select';
type Props = {
  isOpen: boolean;
  selectedCompany?: CompanyMaster;
  selectedPlatform?: CompanyPlatformMapping;
  employee?: Employee;
  onClose: () => void;
  onClickSave: (changedEmployee: Employee) => void;
  onClickSaveNewPassword: (newPassword: string) => void;
};
function ManageEmployeeModal(props: Props) {
  const {
    isOpen,
    employee,
    selectedCompany,
    selectedPlatform,
    onClose,
    onClickSave,
    onClickSaveNewPassword
  } = props;
  const loader = useLoader();
  const [showChangePasswordModal, setShowChangePasswordModal] = useBoolean();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [disabledChangePassword, setDisabledChangePassword] = useState(true);
  const [changedEmployee, setChangedEmployee] = useState<Employee>();
  const [selectedPrefix, setSelectedPrefix] = useState<EmployeePrefix>();
  const [selectedBranch, setSelectedBranch] = useState<EmployeeBranch>();

  // Lazy
  const [prefixes, setPrefixes] = useState<EmployeePrefix[]>([]);
  const [branches, setBranches] = useState<EmployeeBranch[]>([]);
  useEffect(() => {
    if (!isOpen) {
      setChangedEmployee(undefined);
      return;
    }
    setChangedEmployee(employee);
  }, [isOpen, employee]);

  // Lazy fetch prefixes and branches
  useEffect(() => {
    // If already have prefixes or branches it will not fetch twice
    if (!employee || prefixes.length !== 0 || branches.length !== 0 || !selectedCompany || !selectedPlatform) {
      return;
    }
    fetchEmployeePrefixesAndBranches({
      sid: selectedCompany.sid,
      platform_id: selectedPlatform.platform_id
    });
  }, [employee, prefixes, branches, selectedCompany, selectedPlatform]);

  // Set selected prefix and selected branch when open this modal
  useEffect(() => {
    if (!isOpen) {
      setSelectedPrefix(undefined);
      setSelectedBranch(undefined);
      return;
    }
    if (employee && prefixes && branches) {
      setSelectedPrefix(prefixes.find(prefix => prefix.TitleCode === employee.NamePrefix));
      setSelectedBranch(branches.find(branch => branch.BRANCHCODE === employee.BranchCode));
    }
  }, [isOpen, employee, prefixes, branches]);
  useEffect(() => {
    if (!employee) {
      return;
    }
    fetchCanChangePassword();
  }, [employee]);
  const fetchEmployeePrefixesAndBranches = async (params: GetEmployeePrefixParams | GetEmployeeBranchParams) => {
    try {
      loader.show();
      const [prefixResponse, branchResponse] = await Promise.allSettled([getEmployeePrefix(params), getEmployeeBranch(params)]);
      if (prefixResponse.status === 'rejected' || branchResponse.status === 'rejected') {
        throw Error('Cannot fetch prefixes or branches.');
      }
      const [prefixError, prefixData] = prefixResponse.value;
      if (prefixError) {
        throw prefixError;
      }
      setPrefixes(prefixData.result_list);
      const [branchError, branchData] = branchResponse.value;
      if (branchError) {
        throw branchError;
      }
      setBranches(branchData.result_list);
    } catch (error) {
      Swal.fire('Error!', (error as ResponseError).message, 'error');
    } finally {
      loader.hide();
    }
  };
  const fetchCanChangePassword = async () => {
    try {
      loader.show();
      const [error] = await canChangePassword();
      if (error) {
        return;
      }
      setDisabledChangePassword(false);
    } catch (error) {} finally {
      loader.hide();
    }
  };
  const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChangedEmployee(prevState => !prevState ? undefined : {
      ...prevState,
      [event.target.name]: event.target.value
    });
  }, [setChangedEmployee]);
  const handleCheckSaleRepresent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChangedEmployee(prevState => !prevState ? undefined : {
      ...prevState,
      IsSaleRepresent: event.target.checked ? 'True' : 'False'
    });
  }, []);
  const handleChangePrefix = useCallback((prefix: SingleValue<EmployeePrefix>) => {
    if (prefix) setSelectedPrefix(prefix);
  }, []);
  const handleChangeBranch = useCallback((branch: SingleValue<EmployeeBranch>) => {
    if (branch) setSelectedBranch(branch);
  }, []);
  const handleClickAddProfileImage = useCallback(() => {
    inputFileRef.current?.click();
  }, []);
  const handleOpenChangePasswordModal = useCallback(() => {
    if (disabledChangePassword) {
      return;
    }
    setShowChangePasswordModal.on();
  }, [disabledChangePassword]);
  const handleClickSave = useCallback(() => {
    if (!changedEmployee) {
      return;
    }
    onClickSave({
      ...changedEmployee,
      NamePrefix: selectedPrefix?.TitleCode ?? changedEmployee.NamePrefix,
      BranchCode: selectedBranch?.BRANCHCODE ?? changedEmployee.BranchCode
    });
  }, [changedEmployee, onClickSave]);
  const getPrefixLabel = useCallback((prefix: EmployeePrefix) => prefix.TitleName, []);
  const getPrefixValue = useCallback((prefix: EmployeePrefix) => prefix.TitleCode, []);
  const getBranchLabel = useCallback((branch: EmployeeBranch) => branch.BRANCHNAME_TH, []);
  const getBranchValue = useCallback((branch: EmployeeBranch) => branch.BRANCHCODE, []);
  return <Modal isCentered size="6xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody className="flex gap-7">
                    <div className="flex flex-1 flex-col items-center justify-center gap-4">
                        <div className="relative">
                            <Avatar size="2xl" name={`${changedEmployee?.FirstName ?? ''} ${changedEmployee?.LastName ?? ''}`} className="border border-zinc-300" />
                            <IconButton aria-label="pencil" size="sm" icon={<FaPen size={18} className="text-neutral-500" />} onClick={handleClickAddProfileImage} className="absolute bottom-0 right-0 rounded-full bg-white shadow-lg" />
                            <input ref={inputFileRef} type="file" className="hidden" />
                        </div>
                        {!disabledChangePassword && <Button className="rounded-xl bg-primary-900 px-2 text-white" onClick={handleOpenChangePasswordModal}>
                                Change password
                            </Button>}
                    </div>

                    <div className="flex flex-[2] flex-col gap-3 [&_>_div.grid]:gap-5">
                        <div className="border-b border-zinc-300 py-2">
                            <h2 className="text-lg font-semibold text-black">Profile</h2>
                        </div>

                        <div className="grid grid-cols-2">
                            <div>
                                <Label>Employee</Label>
                                <Input name="EmployeeCode" value={changedEmployee?.EmployeeCode ?? ''} onChange={handleChangeInput} />
                            </div>
                            <div>
                                <Label>User ID</Label>
                                <Input disabled name="Username" value={changedEmployee?.Username ?? ''}
              // onChange={handleChangeInput}
              />
                            </div>
                        </div>

                        <div className="grid grid-cols-6">
                            <div className="col-span-1">
                                <Label>คำนำหน้า</Label>
                                <Select options={prefixes} getOptionLabel={getPrefixLabel} getOptionValue={getPrefixValue} placeholder="" value={selectedPrefix} onChange={handleChangePrefix} />
                            </div>
                            <div className="col-span-2">
                                <Label>ชื่อ (ไทย)</Label>
                                <Input name="FirstName_TH" value={changedEmployee?.FirstName_TH ?? ''} onChange={handleChangeInput} />
                            </div>
                            <div className="col-span-3">
                                <Label>นามสกุล (ไทย)</Label>
                                <Input name="LastName_TH" value={changedEmployee?.LastName_TH ?? ''} onChange={handleChangeInput} />
                            </div>
                        </div>

                        <div className="grid grid-cols-6">
                            <div className="invisible col-span-1">
                                <Label>คำนำหน้า</Label>
                                <Select placeholder="" />
                            </div>
                            <div className="col-span-2">
                                <Label>ชื่อ (อังกฤษ)</Label>
                                <Input name="FirstName" value={changedEmployee?.FirstName ?? ''} onChange={handleChangeInput} />
                            </div>
                            <div className="col-span-3">
                                <Label>นามสกุล (อังกฤษ)</Label>
                                <Input name="LastName" value={changedEmployee?.LastName ?? ''} onChange={handleChangeInput} />
                            </div>
                        </div>

                        <div className="grid grid-cols-2">
                            <div>
                                <Label>Email</Label>
                                <Input name="Email" value={changedEmployee?.Email ?? ''} onChange={handleChangeInput} />
                            </div>
                            <div>
                                <Label>Branch</Label>
                                <Select options={branches} getOptionLabel={getBranchLabel} getOptionValue={getBranchValue} placeholder="" value={selectedBranch} onChange={handleChangeBranch} />
                            </div>
                        </div>

                        <div className="grid grid-cols-2">
                            <div>
                                <Label>Department</Label>
                                <Input name="Department" value={changedEmployee?.Department ?? ''} onChange={handleChangeInput} />
                            </div>
                            <div>
                                <Label>Position</Label>
                                <Input name="PositionCode" value={changedEmployee?.PositionCode ?? ''} onChange={handleChangeInput} />
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <Checkbox id="sale-represent" name="IsSaleRepresent" size="md" colorScheme="primary" isChecked={changedEmployee?.IsSaleRepresent === 'True'} onChange={handleCheckSaleRepresent} />
                            <Label htmlFor="sale-represent" className="leading-none">
                                Sale Represent
                            </Label>
                        </div>
                    </div>

                    <ChangePasswordModal isOpen={showChangePasswordModal} onClose={setShowChangePasswordModal.off} onClickSaveNewPassword={onClickSaveNewPassword} />
                </ModalBody>
                <ModalFooter className="gap-2">
                    <Button outline onClick={onClose} className="border-primary-900 text-primary-900">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSave} className="bg-primary-900 text-white">
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>;
}
export default ManageEmployeeModal;