import axios from 'axios';

import {
    CompanyApplicationMappingRequest,
    CompanyApplicationMappingResponse
} from '../schemas';

export async function getAll(params: CompanyApplicationMappingRequest) {
    return axios.get<CompanyApplicationMappingResponse>(
        '/v1/company/application_mapping',
        {
            params,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            }
        }
    );
}
