import { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { useDisclosure, useStore } from 'common/hooks';
import { UserCompany } from 'models/user/UserCompany';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useLoader } from 'common/hooks';
import { AddUserRequest } from 'data/user/schemas';
import { addUsers } from 'data/user/repositories/userCompanyRepository';

import { TabLabel } from './UserVerification.view';

interface Option {
    readonly label: string;
    readonly value: string | UserValueOption;
}

interface UserValueOption {
    userId: string;
    sid: string;
}

export interface EmailParameter {
    Body: string;
    FromEmail: string;
    ToEmails: string;
    Subject: string;
    FromName: string;
    CcEmails: string;
    BccEmails: string;
}

const SELECT_USER_LIMIT = 10;
const DEFAULT_SELECTED_COMPANY = {
    label: 'Select...',
    value: '*'
};

const ROLES = ['User', 'Admin'] as const;

interface UserVerifyExportType {
    // No: number;
    Company: string;
    User: string;
    'User Type': string;
    Status: string;
}

const TAB_VALUES = new Map<TabLabel, string>([
    ['Pending', 'pending'],
    ['Approved', 'active'],
    ['Rejected', 'rejected']
]);

function UserVerificationViewModel() {
    const loader = useLoader();
    const { userCompanyStore, companyMasterStore } = useStore();
    const { userRole, citizenId, userCompanyMapping, message, currentAdminCompany, email } =
        userCompanyStore;
    const companyMaster = companyMasterStore.companyMaster;

    const displayRoles = useMemo(
        () => ROLES.slice(0, userRole === 'Super Admin' ? 2 : 1),
        [userRole]
    );

    const [selectedCompany, setSelectedCompany] = useState<Option>(DEFAULT_SELECTED_COMPANY);
    const [selectedUsers, setSelectedUsers] = useState<MultiValue<Option>>([]);
    const [companyOptions, setCompanyOptions] = useState<Option[]>([]);
    const [activeTab, setActiveTab] = useState<TabLabel>('Approved');
    const [showModal, setShowModal] = useState(false);

    // Searching
    const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
    const [searchUser, setSearchUser] = useState('');
    const [advanceSearchUser, setAdvanceSearchUser] = useState<Option[]>([]);

    const deferredUserCompanyMapping = useDeferredValue(userCompanyMapping);
    const deferredActiveTab = useDeferredValue(activeTab);
    const deferredSelectedCompany = useDeferredValue(selectedCompany);
    const deferredIsAdvanceSearch = useDeferredValue(isAdvanceSearch);
    const deferredSearchUser = useDeferredValue(searchUser);
    const deferredAdvanceSearchUser = useDeferredValue(advanceSearchUser);
    const filteredUsers = useMemo(() => {
        if (deferredUserCompanyMapping.length === 0) {
            return [];
        }

        return deferredUserCompanyMapping.filter(user => {
            let isCompanyMatched =
                deferredSelectedCompany.value !== '*'
                    ? user.sid === deferredSelectedCompany.value
                    : true;
            let isUserMatched =
                TAB_VALUES.get(deferredActiveTab) === 'active'
                    ? user.status === 'active' || user.status === 'inactive'
                    : user.status === deferredActiveTab;
            let isSearchUser = !deferredIsAdvanceSearch
                ? deferredSearchUser !== ''
                    ? user.citizen_id.includes(deferredSearchUser)
                    : true
                : deferredAdvanceSearchUser.length !== 0
                ? deferredAdvanceSearchUser.some(searchUser =>
                      user.citizen_id.includes((searchUser.value as string) ?? '')
                  )
                : true;

            return isCompanyMatched && isUserMatched && isSearchUser;
        });
    }, [
        deferredUserCompanyMapping,
        deferredActiveTab,
        deferredSelectedCompany,
        deferredIsAdvanceSearch,
        deferredSearchUser,
        deferredAdvanceSearchUser
    ]);

    useEffect(() => {
        if (!userRole) {
            return;
        }

        (async () => {
            try {
                loader.show();
                await Promise.all([fetchUserCompany(), fetchCompanyMaster()]);
            } catch {
            } finally {
                loader.hide();
            }
        })();
    }, [userRole]);

    useEffect(() => {
        if (companyOptions.length === 0 && companyMaster && companyMaster.length > 0) {
            const companySelectOption: Option[] = [{ label: 'กรุณาเลือก Company...', value: '*' }];

            companyMaster.forEach(company => {
                companySelectOption.push({
                    label: company.company_name,
                    value: company.sid
                } as Option);
            });

            setCompanyOptions(companySelectOption);
        }
    }, [companyMaster, companyOptions]);

    useEffect(() => {
        if (userRole === '' || !currentAdminCompany) {
            return;
        }

        const selectedCompany = {
            label: currentAdminCompany.company_name,
            value: currentAdminCompany.sid
        };

        setSelectedCompany(selectedCompany);
    }, [userRole]);

    useEffect(() => {
        if (userCompanyMapping.length === 0 || selectedCompany.value === '*') {
            return;
        }

        onSelectCompany(selectedCompany);
    }, [userCompanyMapping, selectedCompany]);

    const fetchUserCompany = async () => {
        try {
            loader.show();
            await userCompanyStore.fetchUserCompany('', '', '');
        } catch (error) {
        } finally {
            loader.hide();
        }
    };

    const fetchCompanyMaster = async () => {
        await companyMasterStore.fetchCompanyMasterStore('', 'active');
    };

    const onSelectCompany = (company: SingleValue<Option>) => {
        setSelectedCompany(company ?? DEFAULT_SELECTED_COMPANY);
    };

    const toggleIsAdvanceSearchUser = () => {
        setIsAdvanceSearch(!isAdvanceSearch);
    };

    const handleChangeAdvanceSearchUser = (users: Option[]) => {
        const advanceSearchUser = users.reduce<Option[]>((accumulate, current) => {
            const userOptions = (current.value as string)
                .split(' ')
                .map<Option>(email => ({ label: email, value: email }));

            accumulate.push(...userOptions);

            return accumulate;
        }, []);

        setAdvanceSearchUser(advanceSearchUser);
    };

    const onSelectUser = (users: MultiValue<Option>) => {
        if (users.length > SELECT_USER_LIMIT) {
            fireSwal('Error!', 'Cannot select user more than 10.', 'error');

            return;
        }

        setSelectedUsers(users);
    };

    const handleClickAddUser = useCallback(
        async (userType: (typeof ROLES)[number]) => {
            try {
                loader.show();

                if (selectedCompany.value === '*') {
                    fireSwal('Error!', 'กรุณาเลือก Company', 'error');
                    loader.hide();

                    return;
                }

                if (selectedUsers.length === 0) {
                    fireSwal('Error!', 'กรุณากรอก User', 'error');
                    loader.hide();

                    return;
                }

                const prepareAddUsers = selectedUsers.reduce<AddUserRequest[]>(
                    (accumulate, user) => {
                        if ((user.value as string).trim() === '') {
                            return accumulate;
                        }

                        accumulate.push({
                            sid: selectedCompany.value as string,
                            citizen_id: user.value as string,
                            type: userType.toLowerCase()
                        });

                        return accumulate;
                    },
                    []
                );

                addUsers(prepareAddUsers);

                await fireSwal('Success!', 'เพิ่ม User สำเร็จ', 'success');

                window.location.reload();
            } catch (error) {
                fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
            }
        },
        [selectedUsers, selectedCompany]
    );

    const onClickEditUserStatusOnce = async (sid: string, citizenId: string, status: string) => {
        try {
            console.log('ngame', citizenId, status);

            loader.show();

            await userCompanyStore.featchUpdateUsersStatus(status, sid, [citizenId]);
            fireSwal('Success!', 'Update user status success.', 'success');
        } catch (error) {
            if (error instanceof Error) {
                fireSwal('Error!', error.message, 'error');
            }
        } finally {
            fetchUserCompany();
            loader.hide();
        }
    };

    const sendEmail = async (props: EmailParameter) => {
        await fetch(`/v1/user/verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            },
            body: JSON.stringify(props)
        });
    };

    const searchPendingUser = (userOptions: FilterOptionOption<Option>, inputUser: string) => {
        if (inputUser === '') return false;

        if (userOptions.label.includes(inputUser)) return true;

        return false;
    };

    const handleInviteSuccess = async (selectedCompany: Option, selectedEmails: Option[]) => {
        await fetchUserCompany();
        onSelectCompany(selectedCompany);
        setIsAdvanceSearch(true);
        setAdvanceSearchUser(selectedEmails);
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        },
        []
    );

    const { isOpen: showModalInvite, onToggle: toggleShowModalInvite } = useDisclosure();

    const exportExcel = () => {
        try {
            loader.show();

            if (userCompanyMapping.length === 0) {
                throw new Error('No user for export.');
            }

            // Prepare Data
            let exportUsers: UserVerifyExportType[] = [];
            if (filteredUsers.length > 0 && selectedCompany.value !== '*') {
                exportUsers = _prepareExportData(filteredUsers);
            } else {
                exportUsers = _prepareExportData(userCompanyMapping);
            }

            // Export Excel
            const date = new Date();
            const fileName = 'UserVerification_' + date.getTime().toString() + '.xlsx';
            const worksheet = XLSX.utils.json_to_sheet(exportUsers);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, 'User Verification');
            XLSX.writeFile(workbook, fileName);
        } catch (error) {
            if (error instanceof Error) {
                fireSwal('Error!', error.message, 'error');
            }
        } finally {
            loader.hide();
        }
    };

    const _prepareExportData = (users: UserCompany[]) => {
        const exportData = users
            .reduce<UserVerifyExportType[]>((accumulate, user, index) => {
                accumulate.push({
                    // No: index + 1,
                    Company: user.company_name,
                    User: user.citizen_id,
                    'User Type': user.type,
                    Status: user.status
                });

                return accumulate;
            }, [])
            .sort((a, b) => {
                switch (b.Status) {
                    case 'active':
                    case 'inactive': {
                        return -1;
                    }
                    case 'pending': {
                        return 0;
                    }
                    default: {
                        // rejected
                        return 1;
                    }
                }
            })
            .reverse()
            .sort((a, b) => (a.Company < b.Company ? -1 : a.Company > b.Company ? 1 : 0));

        return exportData;
    };

    return {
        email,
        userRole,
        showModal,
        isAdvanceSearch,
        searchUser,
        advanceSearchUser,
        displayRoles,
        filteredUsers,
        selectedCompany,
        companyOptions,
        activeTab,
        selectedUsers,
        userCompanyMapping,
        showModalInvite,
        onSelectCompany,
        setSelectedCompany,
        toggleIsAdvanceSearchUser,
        handleChangeAdvanceSearchUser,
        setActiveTab,
        searchPendingUser,
        onSelectUser,
        onClickEditUserStatusOnce,
        handleClickAddUser,
        toggleShowModalInvite,
        sendEmail,
        setShowModal,
        exportExcel,
        setSearchUser,
        handleInviteSuccess
    };
}

export default UserVerificationViewModel;
