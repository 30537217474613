import React, { useRef } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, RadioButtonGroup } from '../../common/materials';
import { TaskStore } from '../../models/taskStore/Task';
import { Option } from '../../models/taskGroup/TaskGroup';

// import EditTaskView, { EditTaskHandle } from './EditTask.view';
import NormalModeView from './components/NormalMode/NormalMode.view';
import EditTaskViewModel from './EditTask.viewmodel';
import PosterModeView from './components/PosterMode/PosterMode.view';
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  selectedTask?: TaskStore;
  taskGroupList: Option[];
};
const editorBackgroundColors = ['red', 'blue', 'green'];
function EditTaskModal(props: Props) {
  const {
    isOpen,
    onClose,
    onRefresh,
    selectedTask,
    taskGroupList
  } = props;
  const {
    normalRef,
    posterRef,
    mode,
    selectedMode,
    handleChangeMode,
    handleClickEditTask
  } = EditTaskViewModel(props);
  return <Modal noPortal fullscreen
  // closeOnClickOverlay
  isOpen={isOpen} onClose={onClose}>
            <ModalHeader>Edit Information Update</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                {/* <EditTaskView
                    onClose={onClose}
                    onRefresh={onRefresh}
                    ref={ref}
                    selectedTask={selectedTask}
                    taskGroupList={taskGroupList}
                 /> */}
                <React.Fragment>
                    <div className="mt-2">
                        <NormalModeView ref={normalRef} onClose={onClose} onRefresh={onRefresh} selectedTask={selectedTask} taskGroupList={taskGroupList} />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <Label>Thumbnail Mode</Label>
                        {selectedMode && <RadioButtonGroup outline values={mode} defaultSelectedValue={selectedMode} onChange={handleChangeMode} />}
                    </div>
                    {selectedMode && selectedMode.toLowerCase() === mode[1].toLowerCase() ? <div className="mb-6 mt-6">
                            <PosterModeView ref={posterRef} onClose={onClose} onRefresh={onRefresh} selectedTask={selectedTask} taskGroupList={taskGroupList} />
                        </div> : undefined}
                </React.Fragment>
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button outline onClick={onClose} className="text-neutral-400">
                    Cancel
                </Button>
                <Button
      // onClick={() => {
      //     ref.current?.handleClickEditTask();
      // }}
      onClick={handleClickEditTask} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default EditTaskModal;