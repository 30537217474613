import * as React from 'react';
import { Redirect } from "react-router";
class SignOut extends React.Component<any, {
  isLoading: boolean;
}> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  componentDidMount() {
    var myClient1 = window.open("https://qmix.thailanderp.com/Logout.aspx", '', 'width=,height=,resizable=no');
    var myClient2 = window.open("https://qmix-o2c.thailanderp.com/Logout.aspx", '', 'width=,height=,resizable=no');
    var myClient3 = window.open("https://qmix-p2p.thailanderp.com/Logout.aspx", '', 'width=,height=,resizable=no');
    var myClient4 = window.open("https://qmix-crm.thailanderp.com/Logout.aspx", '', 'width=,height=,resizable=no');
    var myClient5 = window.open("https://scg-wf.sonarinno.com/logout", '', 'width=,height=,resizable=no');
    setTimeout(() => {
      if (myClient1) {
        myClient1.close();
      }
      if (myClient2) {
        myClient2.close();
      }
      if (myClient3) {
        myClient3.close();
      }
      if (myClient4) {
        myClient4.close();
      }
      if (myClient5) {
        myClient5.close();
      }
    }, 2000);

    //var myClient6 = window.open("https://scg-dms.sonarinno.com/auth/signout", '', 'width=,height=,resizable=no');
    //setTimeout(() => {
    //    if (myClient6) {
    //        myClient6.close();
    //    }
    //}, 1000);

    //fetch("https://qmix.thailanderp.com/Logout.aspx", {
    //    mode: 'no-cors'
    //})
    //    .then(res => {
    //        this.setState({
    //            isLoading: false
    //        });
    //    })
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 5000);
    //await fetch("https://qmix-o2c.thailanderp.com/Logout.aspx")
    //    .then(res => {
    //        //this.setState({
    //        //    isLoading: false
    //        //});
    //    })
    //await fetch("https://qmix-p2p.thailanderp.com/Logout.aspx")
    //    .then(res => {
    //        //this.setState({
    //        //    isLoading: false
    //        //});
    //    })
    //await fetch("https://qmix-crm.thailanderp.com/Logout.aspx")
    //    .then(res => {
    //        //this.setState({
    //        //    isLoading: false
    //        //});
    //    })
    //await fetch("https://scg-wf.sonarinno.com/logout")
    //    .then(res => {
    //        //this.setState({
    //        //    isLoading: false
    //        //});
    //    })
    //await fetch("https://scg-dms.sonarinno.com/auth/signout")
    //    .then(res => {
    //        this.setState({
    //            isLoading: false
    //        });
    //    })

    //fetch("/v1/logout", {
    //    method: 'DELETE',
    //    headers: {
    //        'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
    //    },
    //})
    //    .then(res => {
    //        this.setState({
    //            isLoading: false
    //        });
    //    })
  }

  public render() {
    let {
      isLoading
    } = this.state;
    if (!isLoading) {
      localStorage.removeItem('SSO_AUTH');
      return <Redirect to="/login" />;
    } else {
      return <div>
                    <h2 style={{
          textAlign: "center"
        }}>Signing Out...</h2>
                </div>;
    }
  }
}
export default SignOut;