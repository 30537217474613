import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { ApplicationState } from '../../store';
import * as CompanyStore from '../../store/company/Company';
import { CompanyState, CompanyMaster } from '../../models/company/Company';
import * as ApplicationStore from '../../store/platform/Application';
import { ApplicationState as AppStateModel, RequestBodyEditApplication, ResponseApplicationMappingEndpoint } from '../../models/platform/Application';
import { parseJwt } from '../../modules/common';
import LoadingScreen from '../_helpers/LoadingScreen';
import { UserContextType, UserContext } from '../../context/UserProvider';
import { Label, Input, Button, TextArea, Table, Thead, Td, Tbody, Tr, Th, ButtonGroup } from '../../common/materials';
import { withLoader } from 'common/hocs';
import { WithLoaderProps } from 'common/hocs/withLoader';
interface State {
  selectedApp: AppSelector[];
  prevSelectedApp: AppSelector[];
  citizenId: string;
  isOnChangeApp: boolean;
  allowEditApp: boolean;
  userType: string;
}
interface ComponentProps {
  company: CompanyState;
  application: AppStateModel;
}
type Props = ComponentProps & typeof CompanyStore.actionCreators & typeof ApplicationStore.actionCreators & WithLoaderProps;
type AppSelector = {
  appName: string[];
  companyCode: string;
};
type SaveSelectedApp = {
  selectedApplications: AppSelector[];
  previousApplications: AppSelector[];
};
class UserRegistration extends React.Component<Props, State> {
  static contextType: React.Context<any> | undefined = UserContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedApp: [],
      prevSelectedApp: [],
      citizenId: '',
      isOnChangeApp: false,
      allowEditApp: false,
      userType: ''
    };
  }
  public componentDidMount() {
    let citizenId = '';
    //let firstName = '';
    //let lastName = '';
    //let email = '';

    const ls = localStorage.getItem('SSO_AUTH');
    if (ls) {
      const jls: any = JSON.parse(ls);
      const accessToken = jls.access_token;
      const jwt = parseJwt(accessToken);
      const extra = JSON.parse(jwt.extra);
      if (extra.username) {
        citizenId = extra.username;
      } else if (extra.citizen_id) {
        citizenId = extra.citizen_id;
        //firstName = extra.first_name
        //lastName = extra.last_name
        //email = extra.email
      }
    }

    this.props.requestGetCompanyMaster(true, '', '');
    this.props.requestGetApplicationMaster(true, '');
    this.props.requestGetUserApplicationMappingEndpoint(true, citizenId);
    this.setState({
      citizenId
    });
  }
  public componentDidUpdate() {
    const initialSelectedApp: AppSelector[] = [];
    const {
      responseAppMappingEndpoint
    } = this.props.application;
    const {
      userCompany,
      setUserCompany
    } = (this.context as UserContextType);
    if (responseAppMappingEndpoint && this.state.selectedApp.length === 0) {
      const {
        result_group
      } = responseAppMappingEndpoint;
      if (result_group && result_group.length > 0) {
        result_group.forEach(r => {
          initialSelectedApp.push({
            appName: r.application_name,
            companyCode: r.company_code
          });
        });
      }
    }

    //console.log("[Debug] user company master", userCompany)
    // Set permission edit applications
    if (userCompany && userCompany.type && this.state.userType === '') {
      switch (userCompany.type) {
        case 'super_admin':
          {
            this.setState({
              userType: userCompany.type,
              allowEditApp: true
            });
            break;
          }
        case 'admin':
          {
            this.setState({
              userType: userCompany.type,
              allowEditApp: true
            });
            break;
          }
        case 'user':
          {
            this.setState({
              userType: userCompany.type,
              allowEditApp: false
            });
            break;
          }
        default:
          break;
      }
    }
    if (initialSelectedApp.length > 0) this.setState({
      selectedApp: this.filterAppInCompanyMaster(initialSelectedApp)
    });

    // Set Loading screen
    if (!this.props.company.isLoading && !this.props.application.isLoading && !this.props.loader.isLoading && this.state.isOnChangeApp) {
      this.props.loader.show();
    } else if (this.props.company.isLoading && this.props.application.isLoading && this.props.loader.isLoading && !this.state.isOnChangeApp) {
      this.props.loader.hide();
    }
  }
  public filterAppInCompanyMaster(selectedApp: AppSelector[]): AppSelector[] {
    const companyMaster = this.props.company.responseCompanyMaster;
    if (companyMaster) return selectedApp.filter(app => companyMaster.result_list.find(company => company.company_code === app.companyCode));
    return [];
  }
  public async handleSelectApp(companyCode: string, applications: string[]) {
    if (!this.state.allowEditApp) {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Permission Denied!',
        text: 'You have no permission to edit any applications'
      });
      if (result.isConfirmed) return;
    }
    this.props.loader.show();
    this.setState({
      isOnChangeApp: true
    });
    const cloneSelectedApps = [...this.state.selectedApp];
    const prevSelectedApp = (JSON.parse(JSON.stringify(this.state.selectedApp)) as AppSelector[]); // Deep Copy Objects
    const foundApp = cloneSelectedApps.find(appSelector => appSelector.companyCode === companyCode);
    let differenceSelectedApp: RequestBodyEditApplication;

    //console.log("[Debug]", cloneSelectedApps === this.state.selectedApp)
    if (!foundApp) {
      //console.log("[Debug] app not found")

      const initFirstSelectedApp: AppSelector = {
        appName: [...applications],
        companyCode
      };
      cloneSelectedApps.push(initFirstSelectedApp);
      differenceSelectedApp = this.prepareDataChangedApplication(initFirstSelectedApp, prevSelectedApp);
    } else {
      //console.log("[Debug] found app")
      //console.log("[Debug]", foundApp === this.state.selectedApp[4])

      //foundApp.appName = Array.from(new Set([...foundApp.appName, ...applications]));
      foundApp.appName = [...applications];
      differenceSelectedApp = this.prepareDataChangedApplication(foundApp, prevSelectedApp);
      const indexOfFoundApp = cloneSelectedApps.indexOf(foundApp);
      if (foundApp.appName.length === 0 && indexOfFoundApp > -1) cloneSelectedApps.splice(indexOfFoundApp, 1); // Clear all application in the company
    }

    console.log('[Debug] diff app', differenceSelectedApp);
    const saveSelectedAppModel: SaveSelectedApp = {
      selectedApplications: cloneSelectedApps,
      previousApplications: prevSelectedApp
    };
    if (differenceSelectedApp.application_name) {
      await this.callEditUserApplication(differenceSelectedApp, this.state.citizenId, saveSelectedAppModel);
    }
    this.props.loader.hide();
    this.setState({
      isOnChangeApp: false
    });
  }
  public prepareDataChangedApplication(selectedApp: AppSelector, prevSelectedApps: AppSelector[]): RequestBodyEditApplication {
    const filterPrevAppByCompany = prevSelectedApps.filter(appSelector => appSelector.companyCode === selectedApp.companyCode);
    const initialValue = ({
      appName: [],
      companyCode: ''
    } as AppSelector);
    const previousAppName = filterPrevAppByCompany.reduce<AppSelector>((accumulate, prevSelectedApp) => {
      if (!accumulate.appName) accumulate.appName = [];
      const foundSameAppName = selectedApp.appName.find(app => accumulate.appName.includes(app) && accumulate.companyCode === selectedApp.companyCode);
      if (!foundSameAppName) return prevSelectedApp;
      return accumulate;
    }, initialValue);
    let differenceSelectedApp: RequestBodyEditApplication;

    // Make request body while remove application.
    differenceSelectedApp = {
      application_name: previousAppName.appName.filter(x => !selectedApp.appName.includes(x))[0],
      company_code: previousAppName.companyCode,
      sid: this.findCompanySid(previousAppName.companyCode),
      status: 'inactive'
    };

    // Make request body while add application.
    if (!differenceSelectedApp.application_name) {
      differenceSelectedApp = {
        application_name: selectedApp.appName.filter(x => !previousAppName.appName.includes(x))[0],
        company_code: selectedApp.companyCode,
        sid: this.findCompanySid(selectedApp.companyCode),
        status: 'active'
      };
    }
    return differenceSelectedApp;
  }
  public findCompanySid(companyCode: string): string {
    const companyMaster = this.props.company.responseCompanyMaster;
    if (companyMaster) {
      const foundSid = companyMaster.result_list.find(companyMaster => companyMaster.company_code === companyCode)?.sid;
      return foundSid ?? '';
    }
    return '';
  }
  public findApplicationByCompany(selectedApp: AppSelector[], companyCode: string): string[] | undefined {
    return selectedApp.find(app => app.companyCode === companyCode)?.appName;
  }
  private async callEditUserApplication(reqBody: RequestBodyEditApplication, userId: string, saveApplicationOnSuccess: SaveSelectedApp) {
    try {
      const response = await fetch(`v1/applications/endpoint` + '?user_id=' + userId, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqBody)
      });
      const data: ResponseApplicationMappingEndpoint = await response.json();
      if (!response.ok) throw new Error(data.message);
      console.log('[Debug] req edit app success. ->', data);
      if (data.message && data.message === 'success') {
        const result = await Swal.fire({
          title: 'Success!',
          text: data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false
        });
        if (result.isConfirmed) {
          this.setState({
            selectedApp: this.filterAppInCompanyMaster(saveApplicationOnSuccess.selectedApplications),
            prevSelectedApp: saveApplicationOnSuccess.previousApplications
          });
        }
      }
    } catch (e) {
      console.log('[Debug] req edit app failed. ->', e);
      const result = await Swal.fire({
        title: 'Error!',
        text: (e as any).message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      });
      if (result.isConfirmed) window.location.reload();
    }
  }
  render() {
    const {
      responseCompanyMaster
    } = this.props.company;
    const {
      responseAppMaster
    } = this.props.application;
    return <React.Fragment>
                <div className="mt-3 flex flex-col gap-4">
                    <p className="text-2xl font-semibold">User Register</p>
                    <div className="bg-white p-4">
                        <div className="grid grid-cols-2 gap-2">
                            <p className="col-span-2 text-lg font-semibold">User Information</p>
                            <div className="flex flex-col">
                                <Label>User Code</Label>
                                <Input type="text" />
                            </div>
                            <div className="flex flex-col">
                                <Label>Username</Label>
                                <Input type="text" />
                            </div>
                            <div className="flex flex-col">
                                <Label>Password</Label>
                                <div className="flex gap-2">
                                    <Input type="password" />
                                    <Button size="sm" outline className="border-primary-900 text-primary-900">
                                        Manage
                                    </Button>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Label>Email</Label>
                                <Input type="email" />
                            </div>
                            <div className="col-span-2 flex flex-col">
                                <Label>Description</Label>
                                <TextArea rows={6}></TextArea>
                            </div>
                            <div className="flex flex-col">
                                <Label>Position</Label>
                                <Input type="text" />
                            </div>
                            <div className="flex flex-col">
                                <Label>Department</Label>
                                <Input type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4">
                        <span className="flex text-lg font-semibold">Manage Application</span>
                        <div className="p-3">
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th className="border-x-0">Company Code</Th>
                                        <Th colSpan={2} className="border-x-0">
                                            Company Name
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.props.company.isLoading && responseCompanyMaster && responseCompanyMaster.result_list.length > 0 ? responseCompanyMaster.result_list.map((company, index) => {
                  return <Tr key={index}>
                                                    <Td className="border-x-0">
                                                        {company.company_code}
                                                    </Td>
                                                    <Td className="border-x-0">
                                                        {company.company_name}
                                                    </Td>
                                                    <Td className="flex justify-end border-x-0">
                                                        {this.props.application.isLoading && responseAppMaster && <ButtonGroup disabled={!this.state.allowEditApp} values={responseAppMaster.result_list.map(app => app.app_name)} defaultSelectedValues={this.findApplicationByCompany(this.state.selectedApp, company.company_code) ?? []} onChange={values => this.handleSelectApp(company.company_code, values)} />}
                                                    </Td>
                                                </Tr>;
                }) : <Tr>
                                            <Td colSpan={3} className="text-center font-bold">
                                                No data...
                                            </Td>
                                        </Tr>}
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button outline className="border-gray-400 text-gray-600">
                            Cancel
                        </Button>
                        <Button className="bg-primary-900 text-white">Create</Button>
                    </div>
                </div>
            </React.Fragment>;
  }
}
export default connect((appState: ApplicationState, componentProps) => ({
  ...componentProps,
  company: appState.company,
  application: appState.application
}), {
  ...CompanyStore.actionCreators,
  ...ApplicationStore.actionCreators
})((withLoader(UserRegistration) as any));