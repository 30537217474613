import { Badge, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Progress } from '@chakra-ui/react';
import { formatDateTimeString } from 'common/helpers';
import { Button } from 'common/materials';
import { Task } from 'models/link/Link';
import { TaskActivity } from 'models/link/Task';
import ParticipantMessage from './ParticipantMessage';
type Props = {
  isOpen: boolean;
  task?: Task;
  taskActivity?: TaskActivity;
  onClose: () => void;
};
function TicketDetailModal(props: Props) {
  const {
    isOpen,
    task,
    taskActivity,
    onClose
  } = props;
  return <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            {task && taskActivity && <ModalContent>
                    <ModalBody className="space-y-4 py-4">
                        <div className="rounded-md border border-l-[6px] border-primary-900 bg-primary-50 px-2 py-2 text-sm">
                            <span>Ticket No. :</span> <span>{task.ticketNO}</span>
                        </div>

                        <div className="space-y-2">
                            <div>
                                <p className="font-semibold">
                                    <span className="">Work Group</span>{' '}
                                    <span className="text-primary-900">{task.projectname}</span>{' '}
                                    <span>/ {task.projectcategory ?? '*'}</span>
                                </p>
                                <div className="flex items-center gap-2">
                                    <Badge className="bg-primary-900 text-sm text-white">
                                        {task.itemTypeName}
                                    </Badge>
                                    <p className="text-lg font-semibold text-primary-900">
                                        {task.jobdescription}
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Progress value={parseFloat(task.taskStatusPercent)} colorScheme="primary" className="max-w-xs rounded-md" />
                                    <span>{task.taskStatusPercent} %</span>
                                </div>
                                <p>{task.taskStatusDesc}</p>
                            </div>
                        </div>

                        <div className="time-stamp">
                            <div>
                                <p className="font-semibold">จาก</p>
                                <p className="px-2">
                                    {formatDateTimeString(task.checkIn_Date, task.checkIn_Time)}
                                </p>
                            </div>

                            <div>
                                <p className="font-semibold">ถึง</p>
                                <p className="px-2">
                                    {formatDateTimeString(task.checkOut_Date, task.checkOut_Time)}
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold">Response Person</p>
                            <p className="px-2">{task.leader}</p>
                        </div>

                        <div className="hidden">
                            <p className="font-semibold">Attachments</p>
                            <div className="px-2">
                                {taskActivity.activity_participant_message && (() => {
              const firstActivity = taskActivity.activity_participant_message[0];
              return firstActivity.files && firstActivity.files.map(file => <img key={file.key} src={file.url} alt="" className="w-24" />);
            })()}
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold">รายละเอียด</p>
                            <div className="mt-1 space-y-2 px-2">
                                {taskActivity.activity_participant_message && taskActivity.activity_participant_message.map(activity => <ParticipantMessage activity={activity} />)}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline onClick={onClose} className="border-primary-900 text-primary-900">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>}
        </Modal>;
}
export default TicketDetailModal;