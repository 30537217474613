import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError } from '../../../models';
import { SandboxParticipateRequest, SandboxRegisterRequest } from '../schemas';
import { createSandboxUser, participateCompanySandbox } from '../datasources/sandbox.datasource';

export async function addUserToSandboxPlatform(
    params: SandboxRegisterRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await createSandboxUser(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function participateUserToSandboxPlatform(
    params: SandboxParticipateRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await participateCompanySandbox(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
