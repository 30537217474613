import { makeAutoObservable, runInAction } from 'mobx';

import { getTasksSchedule } from '../../data/link/respositories/taskRepository';
import { LinkMapping } from '../../models/link/Task';
import RootStore from '../Root.store';

class FocusoneLinkStore {
    private _taskScheduleList: LinkMapping[] = [];

    public get taskScheduleList() {
        return this._taskScheduleList;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchTasksSchedule(datetime_now: string, only_today: boolean) {
        const [error, responseTaskScheduleList] = await getTasksSchedule({
            datetime_now,
            only_today
        });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._taskScheduleList = responseTaskScheduleList.result_list;
        });
    }
}

export default FocusoneLinkStore;
