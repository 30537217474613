import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import axios from 'axios';
import { arrGetSubDashboardComponentV2Detail } from '../../models/dashboard/SubDashboardComponentV2';
import { parseJwt } from '../../modules/common';
import { store } from '../../store/index';
import { useStore, useLoader } from '../../common/hooks';
import { Images } from '../../assets';
type QueryString = {
  sid: string | null;
  token: string | null;
};
function Ingress() {
  const {
    subDashboardStore
  } = useStore();
  const dataSubDashBoard = subDashboardStore.groupedApplications;
  const location = useLocation();
  const history = useHistory();
  const screenLoader = useLoader();
  const [queryString, setQueryString] = useState<QueryString>({
    sid: '',
    token: ''
  });
  useEffect(() => {
    setToken();
  }, []);
  useEffect(() => {
    screenLoader.show();
    if (dataSubDashBoard.length > 0) {
      const matchingApplications = dataSubDashBoard.reduce((acc, group) => acc.concat(group.groupItems.reduce((groupAcc, item) => groupAcc.concat(item.application_list), ([] as arrGetSubDashboardComponentV2Detail[]))), ([] as arrGetSubDashboardComponentV2Detail[])).find(application => application.company_id === queryString.sid);
      if (matchingApplications) {
        subDashboardStore.setSelectedApplication(matchingApplications.group_name, matchingApplications.company_token);
        history.push('/user-applications-redesign');
        screenLoader.hide();
      } else {
        history.push('/feed-task');
        screenLoader.hide();
      }
    }
  }, [dataSubDashBoard]);
  const setToken = async () => {
    const searchParams = new URLSearchParams(location.search);
    var token = searchParams.get('token');
    const sid = searchParams.get('sid');
    if (sid && token) {
      setQueryString({
        sid,
        token
      });
    }
    try {
      const response = (await axios.post('/v1/renew', null, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })).data;
      token = response.token;
      // const response = (await axios.get('/v1/sso/ext')).data;
      // console.log(response);
      // setAuthenticationBody({
      //     waitLoading: false,
      // ssoWeb: response.result.endpoint,
      // ssoRedirectToken: response.result.token
      // });

      if (token) {
        const jwt = parseJwt(token);
        if (jwt.extra && jwt.extra.trim()) {
          const extra = JSON.parse(jwt.extra);
          const citizenId = extra.citizen_id || extra.username;
          if (citizenId && citizenId.trim()) {
            const jsonToken = {
              access_token: token
            };
            localStorage.setItem('SSO_AUTH', JSON.stringify(jsonToken));
            store.userCompanyStore.fetchUserFromLocalStorage();
            subDashboardStore.fetchSubDashboardMenu(citizenId);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return <div>
            <img src={Images.APP_POOL_INGRESS} alt="remove-all-role" className='h-auto w-full' />
        </div>;
}
export default observer(Ingress);