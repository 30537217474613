import { parseResponseError } from '../../../common/helpers';
import {
    DatasourceReturnType,
    ResponseError,
    ResponseList
} from '../../../models';
import {
    CompanyApplicationMappingRequest,
    CompanyApplicationMappingResponse
} from '../schemas';
import { getAll } from '../datasources/companyApplicationMapping.datasource';
import { arrGetCompanyApplicationMapping } from '../../../models/company/CompanyApplicationMapping';

export async function getApplicationsLinkedToTheCompany(
    params: CompanyApplicationMappingRequest
): Promise<
    DatasourceReturnType<
        ResponseError,
        ResponseList<arrGetCompanyApplicationMapping>
    >
> {
    try {
        const data = (await getAll(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
