import * as React from 'react';
// import './style/login.css'; // Deprecated
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as Authentication from 'store/authentication/Authentication';
import * as Models from 'models/authentication/Authentication';
import { Redirect } from 'react-router';
import { store } from 'store';
import { parseJwt } from 'modules/common';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import UserSupportComponent from 'components/user/UserSupportComponent';
type AuthenticationProps = any & Models.AuthenticationState & typeof Authentication.actionCreators & AuthenticationBody;
export interface AuthenticationBody {
  email: string;
  password: string;
  isHideAlert: boolean;
  waitLoading: boolean;
  ssoWeb: string;
  ssoRedirectToken: string;
  isopen: boolean;
}
class login extends React.Component<AuthenticationProps, AuthenticationBody> {
  constructor(props: any) {
    super(props);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: '',
      password: '',
      isHideAlert: false,
      waitLoading: true,
      ssoWeb: '',
      ssoRedirectToken: '',
      isopen: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const [token, mode] = ['token', 'mode'].map(key => searchParams.get(key));

    // console.log('ngame', token, mode);

    fetch('/v1/sso/ext', {
      method: 'GET'
    }).then(res => res.json()).then(result => {
      console.log(result);
      store.userCompanyStore.mode = mode ?? '';
      this.props.setMode(mode);
      if (token != null) {
        var jwt = parseJwt(token);
        if (jwt.extra != undefined) {
          var extra = JSON.parse(jwt.extra);
          var citizenId = extra.citizen_id;
          if (!citizenId) {
            var citizenId = extra.username;
          }
          console.log(extra);
          console.log(citizenId);
          if (citizenId != undefined) {
            var jsonToken = {
              access_token: token
            };
            localStorage.setItem('SSO_AUTH', JSON.stringify(jsonToken));
            store.userCompanyStore.fetchUserFromLocalStorage();
            //window.location.reload();
          }
        }
      }

      this.setState({
        waitLoading: false,
        ssoWeb: result.result.endpoint,
        ssoRedirectToken: result.result.token
      });
    }, error => {
      console.log(error);
    });
  }
  handleSubmit(e: any) {
    var {
      email,
      password
    } = this.state;
    this.props.requestAuthentication({
      email: email,
      password: password
    });
    this.setState({
      isHideAlert: false,
      waitLoading: true
    });
    e.preventDefault();
  }
  handleEmailChange(e: any) {
    this.setState({
      email: e.target.value
    });
  }
  handlePasswordChange(e: any) {
    this.setState({
      password: e.target.value
    });
  }
  handleToggle() {
    this.setState({
      isopen: !this.state.isopen
    });
  }
  render() {
    const {
      waitLoading,
      ssoWeb,
      ssoRedirectToken
    } = this.state;
    if (waitLoading) {
      return <div></div>;
    }
    var isLoginSuccess = localStorage.getItem('SSO_AUTH');
    if (isLoginSuccess) {
      return <Redirect to="/" />;
    } else {
      const {
        mode
      } = this.props;
      var appendedMode = '';
      if (mode) {
        appendedMode = '&mode=' + mode;
      }
      var redirect = window.location.href;
      const ssoAuth = ssoWeb + '/auth/signin?token=' + ssoRedirectToken + appendedMode + '&redirect=' + redirect;
      //const ssoAuth = ssoWeb + '/auth/signin_v2?token=' + ssoRedirectToken + appendedMode+ "&redirect=" + redirect;
      //const ssoAuth = 'http://localhost:8000/auth/signin?token=' + ssoRedirectToken + appendedMode + "&redirect=" + redirect;
      var myContent = "<iframe style='width: 100%;height: 100%' src='" + ssoAuth + "' />";
      return <div>
                    <div style={{
          height: '90vh',
          width: '100vw'
        }} dangerouslySetInnerHTML={{
          __html: myContent
        }} />
                    <div style={{
          textAlign: 'center'
        }}>
                        <span onClick={this.handleToggle} className="text-[#0366D6] hover:underline" style={{
            cursor: 'pointer'
          }}>
                            Contact Support
                        </span>
                    </div>

                    <Modal isOpen={this.state.isopen} className="my-0 max-w-[95vw_!important]" contentClassName="min-h-[95vh] lg:mt-[1.25rem]">
                        <ModalHeader toggle={this.handleToggle}>
                            <span>Support</span>
                        </ModalHeader>
                        <ModalBody>
                            <UserSupportComponent username="" email="" onClose={this.handleToggle} from="login" />
                        </ModalBody>
                    </Modal>
                </div>;
    }
  }
}
export default connect((state: ApplicationState) => state.authentication, Authentication.actionCreators)((login as any));