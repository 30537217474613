import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';
import LoadingOverlay from '../components/LoadingOverlay';
export type LoaderContextType = {
  isLoading: boolean;
  show: () => void;
  hide: () => void;
};
export const LoaderContext = createContext<LoaderContextType>(({} as LoaderContextType));
function LoaderProvider({
  children
}: PropsWithChildren<{}>) {
  const [isLoading, setIsLoading] = useState(false);
  const show = useCallback(() => {
    setIsLoading(true);
  }, []);
  const hide = useCallback(() => {
    setIsLoading(false);
  }, []);
  return <LoaderContext.Provider value={{
    hide,
    show,
    isLoading
  }}>
            <LoadingOverlay loading={isLoading} />
            {children}
        </LoaderContext.Provider>;
}
export default LoaderProvider;