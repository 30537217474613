import { makeAutoObservable, runInAction } from 'mobx';
import { extractAccessToken } from 'common/helpers';
import { getUserInformation, editUsersStatus } from 'data/user/repositories/userCompanyRepository';
import { UserCompany, UserCompanyMappingOption } from 'models/user/UserCompany';
import RootStore from 'store/Root.store';

class UserCompanyStore {
    public mode = '';

    private _userName = '';
    private _userRole = '';
    private _email = '';
    private _citizenId = '';
    private _firstName = '';
    private _lastName = '';
    private _userCompanyMappingOptions: UserCompanyMappingOption[] = [];
    private _userCompanyMapping: UserCompany[] = [];
    private _message = '';

    // Temporary
    private _currentAdminCompany?: UserCompany;

    public get firstName() {
        return this._firstName;
    }

    public get lastName() {
        return this._lastName;
    }

    public get userName() {
        return this._userName;
    }

    public get userRole() {
        return this._userRole;
    }

    public get email() {
        return this._email;
    }

    public get citizenId() {
        return this._citizenId;
    }

    public get userCompanyMapping() {
        return this._userCompanyMapping;
    }

    public get userCompanyMappingOptions() {
        return this._userCompanyMappingOptions;
    }

    public get message() {
        return this._message;
    }

    // Temporary
    public get currentAdminCompany() {
        return this._currentAdminCompany;
    }

    constructor(private rootStore: RootStore) {
        this.fetchUserFromLocalStorage();

        makeAutoObservable(this);
    }

    public fetchUserFromLocalStorage() {
        const extra = extractAccessToken();

        this._firstName = extra.first_name;
        this._lastName = extra.last_name;
        this._userName = extra.username;
        this._email = extra.email;
        this._citizenId = extra.citizen_id;
    }

    public async fetchUserCompany(sid: string, citizen_id: string, status: string = 'active') {
        const [error, responseUserCompany] = await getUserInformation({
            sid,
            status,
            citizen_id
        });

        if (error) {
            throw error;
        }

        this._setUser(responseUserCompany.result_list ?? []);
    }

    public async featchUpdateUsersStatus(status: string, sid: string, citizen_id_list: string[]) {
        const [error, message] = await editUsersStatus({
            status,
            sid,
            citizen_id_list
        });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._message = message;
        });
    }

    private _setUser(userCompanies: UserCompany[]) {
        const user = userCompanies.filter(result => result.citizen_id === this._citizenId);

        if (user.length === 0) {
            return;
        }

        const role = this._getUserRole(user);
        const userCompanyMappingOptions = this._formatUserCompanyMapping(userCompanies);

        runInAction(() => {
            this._userRole = role;
            this._userCompanyMapping = [...userCompanies];
            this._userCompanyMappingOptions = userCompanyMappingOptions;
        });
    }

    private _getUserRole(userCompanies: UserCompany[]) {
        const roles = new Map<string, string>([
            ['super_admin', 'Super Admin'],
            ['admin', 'Admin']
        ]);

        const foundAdminOrSuperAdmin = userCompanies.find(
            user => user.type === 'super_admin' || user.type === 'admin'
        );

        if (!foundAdminOrSuperAdmin) {
            return 'User';
        }

        if (foundAdminOrSuperAdmin.type === 'admin') {
            this._currentAdminCompany = foundAdminOrSuperAdmin;
        }

        return roles.get(foundAdminOrSuperAdmin.type) ?? 'User';
    }

    private _formatUserCompanyMapping(userCompanies: UserCompany[]) {
        const options = userCompanies.map(
            userCompany =>
                ({
                    label: userCompany.company_name,
                    value: userCompany.sid
                }) as UserCompanyMappingOption
        );

        return options;
    }
}

export default UserCompanyStore;
