import { block as _block$ } from "million/react";
import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
type OwnProps = {
  onCheck?: (checked: boolean) => void;
};
type Props = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> & OwnProps;
const EMPTY_FUNCTION = () => void 0;
const M$Switch = function Switch(props: Props) {
  const {
    className,
    onCheck,
    ...checkboxProps
  } = props;
  return <label className={twMerge('relative inline-block h-5 w-14', className)}>
            <input {...checkboxProps} type="checkbox" className="peer/switch invisible h-0 w-0" onChange={EMPTY_FUNCTION} />
            <span className={twMerge('pointer-events:none absolute inset-0 cursor-pointer rounded-full bg-gray-100 ', 'before:absolute before:top-1/2 before:h-7 before:w-7 before:-translate-y-1/2 before:rounded-full before:bg-white before:shadow-[1px_3px_7px_-2px_rgba(0,0,0,0.75)] before:content-[""]', 'transition-color before:transition-[transform,_background-color]', 'peer-checked/switch:bg-primary-100 peer-checked/switch:before:translate-x-7 peer-checked/switch:before:bg-primary-500')} />
        </label>;
};
const Switch = M$Switch;
export default Switch;