import React, { forwardRef, useImperativeHandle } from 'react';
import useCloseTaskViewModel from './CloseTask.viewmodel';
import { TaskInbox } from '../../models/link/Task';
import { TaskStore } from '../../models/taskStore/Task';
export type CloseTaskHandle = {
  handleClickCloseTaskActivity: () => Promise<void>;
};

// export type CloseTaskViewProps = {
//     onClose?: () => void;
//     selectedTask?: TaskInbox;
// };

export type CloseTaskViewProps = {
  onClose?: () => void;
  selectedTask?: TaskStore;
};
const CloseTask = forwardRef<CloseTaskHandle, CloseTaskViewProps>((props, ref) => {
  const {
    handleClickCloseTaskActivity
  } = useCloseTaskViewModel(props);
  const {
    selectedTask
  } = props;
  useImperativeHandle(ref, () => {
    return {
      handleClickCloseTaskActivity
    };
  });
  return <React.Fragment>
            {selectedTask && <p>
                    หากต้องการ<span className="text-black underline">ปิดงาน</span>{' '}
                    <span className="font-bold text-orange-500">{selectedTask.subject}</span>{' '}
                    กรุณากด Confirm
                </p>}
        </React.Fragment>;
});
export default CloseTask;