import React, { useMemo } from 'react';
import { ActivityParticipantMessage, EmailContent } from 'models/link/Task';
type Props = {
  activity: ActivityParticipantMessage;
};
function ParticipantMessage(props: Props) {
  const {
    activity
  } = props;
  const email = useMemo(() => (JSON.parse(activity.data ?? '') as EmailContent), [activity.data]);
  return <React.Fragment>
            <div className="rounded-md border border-primary-900 bg-primary-50 px-2">
                <div dangerouslySetInnerHTML={{
        __html: email.content_body
      }} className="py-2" />
                {activity.files && activity.files.length > 0 && <div className="border-t border-zinc-300 py-2">
                        <p className="text-sm">Attachments</p>
                        <div className="flex flex-wrap gap-2">
                            {activity.files && activity.files.map(file => {
            switch (file.type) {
              case 'FILE':
                return <div key={file.key} className="rounded-md border border-zinc-300 bg-neutral-50 px-2">
                                                    <p>
                                                        {file.url.slice(file.url.lastIndexOf('/') + 1)}
                                                    </p>
                                                </div>;
              case 'IMAGE':
                return <img key={file.key} src={file.url} alt="" className="w-24" />;
            }
          })}
                        </div>
                    </div>}
            </div>
        </React.Fragment>;
}
export default ParticipantMessage;