import axios from 'axios';
import { FocusOneHelpResponse } from '../schemas';

export async function getFocusOneHelpTokenFromAPI() {
    return axios.get<FocusOneHelpResponse>('/v1/focusone-help/token', {
        headers: {
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('SSO_AUTH') ?? '').access_token
        }
    });
}
