import React from 'react';
import { Badge } from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';
import { FaThLarge, FaThList } from 'react-icons/fa';
import { Button, Input } from 'common/materials';
import SupportModal from 'common/components/TopNavigation/SupportModal';
import { TicketCard, TicketDetailModal, TicketTable } from './components';
import useTicketViewModel from './Ticket.viewmodel';
export const TABS = ([{
  label: 'All',
  value: 'all',
  color: 'bg-primary-50 text-primary-600'
}, {
  label: 'Processing',
  value: 'กำลังดำเนินการ',
  color: 'bg-blue-50 text-blue-600'
}, {
  label: 'Cancel',
  value: 'ถูกยกเลิก',
  color: 'bg-red-50 text-red-600'
}, {
  label: 'Finish',
  value: 'ปิดงานแล้ว',
  color: 'bg-green-50 text-green-600'
}] as const);
function TicketView() {
  const {
    email,
    filter,
    displayStyle,
    filteredTasks,
    search,
    showSupportModal,
    selectedTask,
    selectedTaskDetail,
    showTaskDetailModal,
    setFilter,
    setDisplayStyle,
    handleChangeSearch,
    handleClickTicketDetail,
    handleCloseTicketDetailModal,
    setShowSupportModal
  } = useTicketViewModel();
  return <React.Fragment>
            <div className="flex flex-1 flex-col gap-4">
                <div className="flex">
                    <p className="text-2xl font-bold">Ticket</p>
                    <Button className="ml-auto bg-primary-900 text-white" onClick={setShowSupportModal.on}>
                        Create Ticket
                    </Button>
                </div>
                <div className="flex items-center justify-between">
                    <Badge variant="subtle" colorScheme="primary" className="w-fit rounded-md px-2 py-1 text-xs">
                        {email}
                    </Badge>

                    <div className="relative flex w-fit overflow-hidden rounded-md border [&_>_button:not(:last-child)]:border-r">
                        <button className={twMerge('flex flex-1 items-center justify-center px-3 text-stone-300 transition-colors', displayStyle === 'grid' && 'bg-zinc-300 text-[#777777]')} onClick={() => setDisplayStyle('grid')}>
                            <FaThLarge />
                        </button>
                        <button className={twMerge('flex flex-1 items-center justify-center px-3 py-2 text-stone-300 transition-colors', displayStyle === 'list' && 'bg-zinc-300 text-[#777777]')} onClick={() => setDisplayStyle('list')}>
                            <FaThList />
                        </button>
                    </div>
                </div>

                <div className="relative flex w-fit overflow-hidden rounded-md border [&_>_button:not(:last-child)]:border-r">
                    {TABS.map(tab => <button key={tab.label} className={twMerge('px-4 py-2 font-semibold transition-colors', filter === tab.value && tab.color)} onClick={() => setFilter(tab.value)}>
                            {tab.label}
                        </button>)}
                </div>

                <Input placeholder="Search ..." value={search} onChange={handleChangeSearch} />

                {displayStyle === 'grid' ? <div className="grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-2">
                        {filteredTasks && filteredTasks.length > 0 ? filteredTasks.map((ticket, index) => <div key={index}>
                                    <TicketCard ticket={ticket} />
                                </div>) : <div className="col-span-2 text-center">No ticket...</div>}
                    </div> : <TicketTable tasks={filteredTasks} onClickTicketDetail={handleClickTicketDetail} />}
            </div>

            {/* Create ticket modal */}
            <SupportModal header="Create Ticket" isOpen={showSupportModal} onClose={setShowSupportModal.off} />

            <TicketDetailModal isOpen={showTaskDetailModal} task={selectedTask} taskActivity={selectedTaskDetail} onClose={handleCloseTicketDetailModal} />
        </React.Fragment>;
}
export default TicketView;