import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Gifs } from 'assets';
import OverlayPortal from './OverlayPortal';
interface Props {
  loading: boolean;
}
function LoadingOverlay({
  loading
}: Props) {
  const [loadingGif, setLoadingGif] = useState('');
  useEffect(() => {
    setLoadingGif('');
  }, [loading]);
  useEffect(() => {
    setLoadingGif(Gifs.F1_LOADINGGIF);
  }, [loadingGif]);
  return <OverlayPortal>
            <div className="container">
                <div className={twMerge('fixed left-0 top-0 z-[1450] h-screen w-screen items-center justify-center bg-[rgb(247_247_247_/_83%)]', loading ? 'flex' : 'hidden')}>
                    <img src={loadingGif} alt="loading-gif" className={`h-60 w-60`} />
                    {/* <div className="animate-spin w-16 h-16 border-8 border-t-[#3d5af1] border-r-transparent border-b-[#3d5af1] border-l-transparent rounded-[50%]" /> */}
                </div>
            </div>
        </OverlayPortal>;
}
export default LoadingOverlay;