import { useState, useEffect } from 'react';
import { useLoader, useStore } from '../../../common/hooks';
import { Option, RequestLog, Log } from 'models/log/Log';
import axios from 'axios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { UserCompanyResponse } from '../../../data/company/schemas';
import { getUserCompanyByCompany } from '../../../data/company/repositories/companyMasterRepository';
import { set } from 'mobx';
import { ActionMeta } from 'react-select';
const DEFAULT_ACTION: {
  label: string;
  value: string;
}[] = [{
  label: 'Request to Sandbox',
  value: 'request_to_sandbox'
}, {
  label: 'Send Email Approve to Sandbox',
  value: 'send_email_approve_sandbox'
}, {
  label: 'Add Employee to FocusOne',
  value: 'add_employee_to_f1'
}, {
  label: 'Add User to FocusOne',
  value: 'add_user_to_f1'
}, {
  label: 'Request to Company',
  value: 'request_to_company'
}, {
  label: 'Active User',
  value: 'active'
}, {
  label: 'Approve User',
  value: 'approve'
}, {
  label: 'Invite User',
  value: 'invite_user'
}, {
  label: 'Reject User',
  value: 'reject'
}, {
  label: 'Send Email Approve to Company',
  value: 'send_email_approve'
}, {
  label: 'Send Email Invite to Company',
  value: 'send_email_invite'
}, {
  label: 'Send Email Reject to Company',
  value: 'send_email_reject'
}, {
  label: 'Send Email Request to Company',
  value: 'send_email_request_to_company'
}].map(value => ({
  label: value.label,
  value: value.value
}));
function LogsViewModel() {
  const loader = useLoader();
  const [companyOptions, setCompanyOptions] = useState<Option[]>([]);
  const [userName, setUserName] = useState<string>('');
  const [company, setCompany] = useState<Option | null>(null);
  const [action, setAction] = useState<Option | null>();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [logs, setLogs] = useState<Log[]>([]);
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const {
    companyMasterStore,
    userCompanyStore,
    logStore
  } = useStore();
  const companyMaster = companyMasterStore.companyMaster;
  const handleUserNameChange = (newValue: Option | null, actionMeta: ActionMeta<Option>) => {
    if (newValue) {
      setUserName(newValue.value);
    } else {
      setUserName(''); // Set an appropriate fallback value if needed
    }
  };

  useEffect(() => {
    const companySelectOption: Option[] = [];
    if (companyMaster) {
      companyMaster.forEach(company => {
        companySelectOption.push(({
          label: company.company_name,
          value: company.sid
        } as Option));
      });
      setCompanyOptions(companySelectOption);
    }
  }, [companyMaster]);
  useEffect(() => {
    const userCompanyOption: Option[] = [];
    const fetchUserCompany = async () => {
      const [, user] = await getUserCompanyByCompany({
        sid: company?.value!
      });
      if (user) {
        user.forEach(userConpamy => {
          console.log(userConpamy);
          userCompanyOption.push({
            label: userConpamy.citizen_id,
            value: userConpamy.citizen_id
          });
        });
        setUserOptions(userCompanyOption);
      }
    };
    if (company?.value) {
      fetchUserCompany();
    }
  }, [company]);
  const resetDateTime = (date: string) => {
    if (date === 'start') {
      setStartDate('');
      setStartTime('');
    }
    if (date === 'end') {
      setEndDate('');
      setEndTime('');
    }
  };
  const handleSearch = async () => {
    loader.show;
    if (!company?.value) {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาเลือก Company',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      });
      return;
    }
    if (userName === '') {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาระบุ User Name',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      });
      return;
    }
    const searchLog: RequestLog = {
      user: userName,
      sid: company?.value,
      action: action?.value,
      with: userName,
      created_on_start: startDate && startTime ? format(new Date(`${startDate}T${startTime}`), "yyyy-MM-dd'T'HH:mm:ssxxx") : '',
      created_on_end: endDate && endTime ? format(new Date(`${endDate}T${endTime}`), "yyyy-MM-dd'T'HH:mm:ssxxx") : ''
    };
    //const logs = (
    //    await axios.post<Log[]>('/v1/GetConditionLogs', searchLog)
    //    //await logStore.fetchLogStore(searchLog)
    //).data;

    await logStore.fetchLogStore(searchLog);
    const logsData = logStore.log;
    logsData ? logsData.forEach(log => {
      log.sid = companyMaster.find(company => company.sid === log.sid)?.company_name;
      const dateString = log.created_on;
      const date = new Date(dateString!);
      const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      };
      const formatter = new Intl.DateTimeFormat('en-GB', options);
      const formattedDate = formatter.format(date);
      log.created_on = formattedDate;
    }) : null;
    setLogs(logsData);
    loader.hide;
  };
  return {
    DEFAULT_ACTION,
    companyOptions,
    userName,
    setUserName,
    company,
    setCompany,
    action,
    setAction,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    resetDateTime,
    handleSearch,
    logs,
    userCompanyStore,
    userOptions,
    handleUserNameChange
  };
}
export default LogsViewModel;