import { parseResponseError } from '../../../common/helpers';
import {
    DatasourceReturnType,
    ResponseError,
    ResponseList
} from '../../../models';
import { get } from '../datasources/version.datasource';

export async function getApplicationBuildVersion(): Promise<
    DatasourceReturnType<ResponseError, { message: string }>
> {
    try {
        const data = (await get()).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
