import axios from 'axios';

import {
    CompanyMasterRequest,
    CompanyMasterRespones,
    UserCompanyRequest,
    UserCompanyResponse
} from '../schemas';

export async function getAll(params: CompanyMasterRequest) {
    return axios.get<CompanyMasterRespones>('/v1/company_master', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function getUserCompany(params: UserCompanyRequest) {
    return axios.get<UserCompanyResponse[]>('v1/usercompany', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
