import { block as _block$ } from "million/react";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../../../assets';
import { useStore } from '../../hooks';
import { arrGetSubDashboardComponentV2GroupDetail } from '../../../models/dashboard/SubDashboardComponentV2';
import { observer } from 'mobx-react';
type Props = {
  header: string;
  indexKey: number;
  dashboardDataItem: arrGetSubDashboardComponentV2GroupDetail[];
};
const M$Accordion = function Accordion(props: Props) {
  const {
    subDashboardStore
  } = useStore();
  const {
    header,
    dashboardDataItem,
    indexKey
  } = props;
  const [isExpansion, setIsExpansion] = useState(true);
  const selectDashBoard = subDashboardStore.selectedApplication;
  return <div key={indexKey}>
            <div className="flex flex-row">
                <div className="flex-1 flex flex-row mt-[2px] rounded-[4px] p-[10px] bg-[#FFFFFF] justify-between" onClick={() => setIsExpansion(prevState => !prevState)}>
                    <div className="flex ml-[10px]">
                        <img src={Icons.VECTOR_GRAY} />
                        <span className="text-black font-bold leading-[21px] text-[14px] ml-[16px]">
                            {header}
                        </span>
                    </div>
                    <img src={Icons.EXPAND_ARROW_BLACK} className={`${isExpansion && 'rotate-90'}`} />
                </div>
            </div>
            {isExpansion && dashboardDataItem.map((dataItem, index) => <Link key={index} to={'/user-applications-redesign'} className={`text-black`} onClick={() => subDashboardStore.setSelectedApplication(dataItem.group_name, dataItem.company_token)}>
                        {selectDashBoard === dataItem.group_name ? <div className="flex flex-1 p-[8px] border-b-[1px] pl-[50px] bg-[#F5F5F5]">
                                <span className="font-normal text-[14px] leading-[21px] text-[#E55C00]">
                                    {dataItem.group_name}
                                </span>
                            </div> : <div className="flex flex-1 p-[8px] border-b-[1px] border-[#D3D1D1] pl-[50px] bg-[#F9F9F9]">
                                <span className="font-normal text-[14px] leading-[21px]">
                                    {dataItem.group_name}
                                </span>
                            </div>}
                    </Link>)}
        </div>;
};
const Accordion = M$Accordion;
export default observer(Accordion);