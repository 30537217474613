export const sizeTwClasses = new Map<MaterialSize, string>([
    ['xs', 'h-6'],
    ['sm', 'h-8'],
    ['md', 'h-10'],
    ['lg', 'h-12']
]);

export const badgeTwClasses = new Map<MaterialStatus, string>([
    ['success', 'bg-success-50 text-success-400'],
    ['danger', 'bg-danger-50 text-danger-400'],
    ['neutral', 'bg-neutral-100 text-neutral-900'],
    ['warning', 'bg-yellow-50 text-yellow-400']
]);
