import React, { useCallback, useMemo, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Button, Input, Label, RadioButtonGroup } from 'common/materials';
import { useStore, useLoader } from 'common/hooks';
import { IAddInviteRequest } from 'data/user/schemas';
import { addInvite } from 'data/user/repositories/userCompanyRepository';
interface Option {
  readonly label: string;
  readonly value: string;
}
type Props = {
  isOpen: boolean;
  email: string;
  role: string;
  companyOptions: Option[];
  onClose: () => void;
  onInviteSuccess?: (selectedCompany: Option, selectedEmails: Option[]) => void;
};
const ROLES = (['User', 'Admin'] as const);
function UserVerificationInviteModal(props: Props) {
  const {
    isOpen,
    email,
    role,
    companyOptions,
    onClose,
    onInviteSuccess
  } = props;
  const screenLoader = useLoader();
  const [emailOptions, setEmailOptions] = useState<Option[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Option>(companyOptions[0]);
  const [selectedRole, setSelectedRole] = React.useState<string>('User');
  const {
    userCompanyStore
  } = useStore();
  const {
    userRole,
    currentAdminCompany
  } = userCompanyStore;
  const displayRoles = useMemo(() => ROLES.slice(0, role === 'Super Admin' ? 2 : 1), [role]);
  useEffect(() => {
    if (userRole === '' || !currentAdminCompany) {
      return;
    }
    const selectedCompany = {
      label: currentAdminCompany.company_name,
      value: currentAdminCompany.sid
    };
    setSelectedCompany(selectedCompany);
  }, [userRole]);
  const handleChangeEmailOptions = (inputEmails: Option[]) => {
    const emailOptions = inputEmails.reduce<Option[]>((accumulate, current) => {
      const emails = current.value.split(' ').map<Option>(email => ({
        label: email,
        value: email
      }));
      accumulate.push(...emails);
      return accumulate;
    }, []);
    setEmailOptions(emailOptions);
  };
  const handleClickSendInvite = async () => {
    const selectedSid = selectedCompany?.value;
    if (!selectedSid) {
      fireSwal('Error!', 'กรุณาเลือก Company', 'error');
      return;
    }
    if (emailOptions.length < 1) {
      fireSwal('Error!', 'กรุณาระบุ email', 'error');
      return;
    }
    try {
      screenLoader.show();
      const prepareInviteData = emailOptions.map<IAddInviteRequest>(emailOption => ({
        citizen_id: emailOption.value,
        sid: selectedSid,
        type: selectedRole.toLowerCase()
      }));
      const [error] = await addInvite(prepareInviteData);
      if (error) {
        throw error;
      }
      fireSwal('Success!', 'เพิ่ม User สำเร็จ', 'success').then(() => {
        onClose();
        onInviteSuccess && onInviteSuccess(selectedCompany, emailOptions);
      });
    } catch (error) {
      console.log(error);
      fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
    } finally {
      screenLoader.hide();
    }
  };
  const fireSwal = useCallback((title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
    return Swal.fire({
      title,
      text: message,
      icon,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      allowOutsideClick: false
    });
  }, []);
  return <Modal isCentered size="5xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="text-2xl font-medium">User Verification</ModalHeader>
                <ModalBody>
                    <div className="bg-whtie rounded-md border px-4 py-2">
                        <p className="text-lg font-semibold">Invite User</p>
                        <div className="flex flex-col gap-2 px-2 py-2">
                            <Label>Email</Label>
                            <Input disabled placeholder={email} />
                            <Label>Role</Label>
                            <Input disabled placeholder={role} />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-6">
                        <span>Invite as</span>
                        <RadioButtonGroup values={displayRoles} defaultSelectedValue={selectedRole} onChange={value => setSelectedRole(value)} />
                        <Label>Company</Label>
                        <CreatableSelect className="w-full" onChange={value => setSelectedCompany((value as any))} options={companyOptions} value={selectedCompany} isDisabled={role !== 'Super Admin'} />
                        <Label>Emails</Label>
                        <CreatableSelect className="w-full" isMulti isClearable components={{
            DropdownIndicator: null
          }} value={emailOptions} onChange={newValue => handleChangeEmailOptions(((newValue as unknown) as Option[]))} />
                    </div>
                </ModalBody>
                <ModalFooter className="gap-4">
                    <Button outline size="sm" className="text-gray-400" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button size="sm" onClick={handleClickSendInvite} className="bg-primary-900 text-white">
                        Send Invite
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>;
}
export default UserVerificationInviteModal;