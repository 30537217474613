import { ComponentProps } from 'react';
import { TableContainer, Button, Badge, Tag } from '@chakra-ui/react';
import { useLoader } from 'common/hooks';
import { UserCompany } from 'models/user/UserCompany';
import { Table, Thead, Tbody, Td, Th, Tr, Switch } from 'common/materials';
import { TabLabel } from '../UserVerification.view';
import { twMerge } from 'tailwind-merge';
const BADGE_STYLES = new Map<string, ComponentProps<typeof Badge>['colorScheme']>([['pending', 'yellow'], ['rejected', 'red'], ['active', 'green'], ['inactive', 'gray']]);
interface Props {
  activeTab: TabLabel;
  email: string;
  users: UserCompany[];
  editUserStatus: (sid: string, citizenId: string, status: string) => Promise<void>;
}
function UserVerificationTable(props: Props) {
  const {
    activeTab,
    users,
    editUserStatus
  } = props;
  const loader = useLoader();
  const handleClickChangeUserStatus = async (sid: string, citizenId: string, status: 'approve' | 'active' | 'rejected' | 'inactive') => {
    loader.show();
    await editUserStatus(sid, citizenId, status);
    loader.hide();
  };
  const isOnApprovedTab = activeTab === 'Approved';
  const isOnRejectedTab = activeTab === 'Rejected';
  return <TableContainer className="mt-4">
            <Table>
                <Thead>
                    <Tr>
                        <Th>No</Th>
                        <Th>Company</Th>
                        <Th>User</Th>
                        <Th>User Type</Th>
                        {!isOnApprovedTab && <Th>Status</Th>}
                        <Th>Manage User</Th>
                        <Th>Last Update</Th>
                        {!isOnRejectedTab && <Th>Active User</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {users && users.length > 0 && users.map((user, index) => <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>{user.company_name}</Td>
                                <Td>{user.citizen_id}</Td>
                                <Td>{user.type}</Td>
                                {!isOnApprovedTab && <Td className="text-center">
                                        <Badge variant="subtle" colorScheme={BADGE_STYLES.get(user.status)} className="rounded-full px-2 py-1 text-xs">
                                            {user.status}
                                        </Badge>
                                    </Td>}
                                <Td>
                                    <div className="flex justify-center gap-2">
                                        {!isOnApprovedTab && <Button size="sm" colorScheme="primary" className="bg-primary-900" disabled={user.status !== 'pending'} onClick={() => handleClickChangeUserStatus(user.sid, user.citizen_id, 'approve')}>
                                                Approve
                                            </Button>}
                                        {!isOnRejectedTab && <Button variant="outline" size="sm" colorScheme="red" disabled={user.status !== 'pending'} onClick={() => handleClickChangeUserStatus(user.sid, user.citizen_id, 'rejected')}>
                                                Reject
                                            </Button>}
                                    </div>
                                </Td>
                                <Td></Td>
                                {!isOnRejectedTab && <Td>
                                        <div className="flex gap-3">
                                            <Switch checked={user.status === 'active'} onClick={() => handleClickChangeUserStatus(user.sid, user.citizen_id, user.status === 'active' ? 'inactive' : 'active')} />
                                            <Tag className={twMerge('flex-1', user.status === 'active' ? 'bg-primary-50 text-primary-900' : 'text-gray-600')}>
                                                <span className="flex-1 text-center">
                                                    {user.status[0].toUpperCase() + user.status.slice(1)}
                                                </span>
                                            </Tag>
                                        </div>
                                    </Td>}
                            </Tr>)}

                    {props.users.length === 0 && <Tr>
                            <Td colSpan={8} className="text-center">
                                No data
                            </Td>
                        </Tr>}
                </Tbody>
            </Table>
        </TableContainer>;
}
export default UserVerificationTable;