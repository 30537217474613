import { block as _block$ } from "million/react";
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';
import { nameof } from 'common/helpers';
import { GeneralInfo, OTPType, DigitalSignInfo, SignType, NationInfo, AddressInfo, WorkingPosition } from './components';
import useProfileViewModel from './Profile.viewmodel';
const M$Profile = function Profile() {
  const {
    previewImageBlobUrl,
    previewImageSignatureBlobUrl,
    userInformation,
    copyUserInformation,
    handleRemoveAttachFile,
    handleChangeCopyUserInformation,
    handleChangeAttachFile,
    handleSaveChange
  } = useProfileViewModel();
  return <div className="flex flex-col gap-y-2.5 space-y-2 px-3 pb-4">
            <div className="flex flex-row">
                <p className="text-2xl font-bold">Profile</p>

                <div className="ms-auto flex gap-4">
                    <Button size="md" onClick={handleSaveChange} className="bg-primary-900 text-xs text-white hover:bg-primary-700">
                        Save Change
                    </Button>

                    <Button size="md" className="bg-primary-900 text-xs text-white hover:bg-primary-700">
                        Change Password
                    </Button>
                </div>
            </div>

            <hr />

            {userInformation && copyUserInformation && <div className="flex flex-col gap-4 xl:flex-row">
                    <div className="flex flex-col space-y-4">
                        <div className="relative h-[150px] w-[150px] rounded-md border">
                            <FaTimes size={16} className="absolute -right-2 -top-2 box-content cursor-pointer rounded-full bg-red-600 p-0.5 text-white" onClick={() => handleRemoveAttachFile('image')} />
                            <img src={previewImageBlobUrl ?? userInformation.image ?? ''} className="object-cover" />
                        </div>

                        <input id="file-upload" type="file" name={nameof('image', userInformation)} accept="image/png, image/jpg, image/jpeg" onChange={handleChangeAttachFile} />

                        <div>
                            <p>{userInformation.employment_id ?? ''}</p>

                            {/* <div className="flex items-center gap-1">
                             <AiFillFacebook size={24} />
                             <span className="text-base">Krit Wangsoh</span>
                             </div> */}
                        </div>
                    </div>

                    <div className="flex-1 space-y-4">
                        <GeneralInfo // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <OTPType // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <DigitalSignInfo userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeAttachFile={handleChangeAttachFile} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <SignType // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <NationInfo // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <AddressInfo // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                        <WorkingPosition // Bound
        userInformation={userInformation} copyUserInformation={copyUserInformation} handleChangeCopyUserInformation={handleChangeCopyUserInformation} />
                    </div>
                </div>}
        </div>;
};
const Profile = M$Profile;
export default Profile;