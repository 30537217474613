import React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from '../components/_helpers/PrivateRoute';
import Login from '../components/authentication/Login';
import Invite from '../components/authentication/Invite';
import Home from '../components/Home';
import Platform from '../components/group/Platform';
import Application from '../components/platform/Application';
import Company from '../components/company/Company';
import PlatformDashboardComponent from '../components/group/PlatformDashboardComponent';
import UserComponent from '../components/user/UserComponent';
import CompanyDashboardComponent from '../components/company/CompanyDashboardComponent';
import PlatformComponent from '../components/group/PlatformComponent';
import EditCitizenComponent from '../components/citizen/EditCitizenComponent';
import CallbackComponent from '../components/config/CallbackComponent';
import LogoutComponent from '../components/authentication/Logout';
import UserSupportComponent from '../components/user/UserSupportComponent';
import UserRegistration from '../components/user/UserRegistration';
import FeedTask from 'components/FeedTask/FeedTask.view';
import UserApplications from '../presentations/view/UserApplications/UserApplications.view';
import ClientSystemDownload from '../presentations/view/ClientSystemDownload/ClientSystemDownload.view';
import AssignRoleProfile from '../presentations/view/AssignRoleProfile/AssignRoleProfile.view';

// import Dashboard from '../components/dashboard/Dashboard'; // Deprecated
import Dashboard from '../common/components/Dashboard';
import UserVerification from '../presentations/view/UserVerification/UserVerification.view';
import SandboxRegister from '../presentations/view/SandboxRegister/SandboxRegister.view';
import NewTask from '../presentations/view/NewTask/NewTask.view';
// import UserVerification from '../components/user/UserVerification';
import Logs from '../presentations/view/Logs/Logs.view';
import ingress from '../components/authentication/ingress';
import LineConnectView from 'components/LineConnect/LineConnect.View';
import Profile from 'components/Profile/Profile.view';
import UserDashboardComponent from 'components/user/UserDashboardComponentMain';
import TicketView from 'presentations/view/Ticket/Ticket.view';
import AssignEmployee from 'presentations/view/AssignEmployee/AssignEmployee.view';
// import FeedTask from '../presentations/view/FeedTask/FeedTask.view';

function Layout() {
  return <Switch>
            <Route path="/login" component={Login} />
            <Route path="/invite" component={Invite} />
            <Route path="/citizen/editcitizen" component={EditCitizenComponent} />
            <Route path="/config/callback" component={CallbackComponent} />
            <Route path="/logout" component={LogoutComponent} />
            <Route path="/ingress" component={ingress} />
            <Route path="/line-connect" component={LineConnectView} />
            <Dashboard>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute path="/platform" component={Platform} />
                <PrivateRoute path="/application" component={Application} />
                <PrivateRoute path="/company" component={Company} />
                <PrivateRoute path="/platform-dashboard" component={PlatformDashboardComponent} />
                <PrivateRoute path="/user" component={UserComponent} />
                <PrivateRoute path="/company-dashboard" component={CompanyDashboardComponent} />
                <PrivateRoute path="/ticket" component={TicketView} />

                {/* Deprecated */}
                <PrivateRoute path="/user-dashboard" component={UserDashboardComponent} />
                <PrivateRoute path="/platform-register" component={PlatformComponent} />
                <PrivateRoute path="/user-support" component={UserSupportComponent} />
                <PrivateRoute path="/user-register" component={UserRegistration} />
                <PrivateRoute path="/user-verify" component={UserVerification} />
                <PrivateRoute path="/feed-task" component={FeedTask} />
                <PrivateRoute path="/user-applications-redesign" component={UserApplications} />
                <PrivateRoute path="/client-system-program" component={ClientSystemDownload} />
                <PrivateRoute path="/sandbox-register" component={SandboxRegister} />
                <PrivateRoute path="/assign-role-profile" component={AssignRoleProfile} />
                <PrivateRoute path="/assign-employee" component={AssignEmployee} />
                <PrivateRoute path="/new-task" component={NewTask} />
                <PrivateRoute path="/report-log" component={Logs} />
                <PrivateRoute path="/profile" component={Profile} />
            </Dashboard>
        </Switch>;
}
export default Layout;