import { AxiosError } from 'axios';

import { ResponseError } from '../../models';

export function parseResponseError(error: any): ResponseError | null {
    try {
        // Stock response
        if (!(error instanceof AxiosError)) {
            return error;
        }

        if (error.code === 'ERR_NETWORK') {
            return { message: 'การเชื่อมต่อขัดข้อง' };
        }

        return error.response?.data;
    } catch {
        return null;
    }
}
