import { block as _block$ } from "million/react";
import React from 'react';
import { twMerge } from 'tailwind-merge';
const M$FocusoneTextLogo = function FocusoneTextLogo(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) {
  const {
    className,
    ...spanProps
  } = props;
  return <span className={twMerge('text-2xl font-bold', className)} {...spanProps}>
            focus
            <span className="text-[#ffa500] font-extrabold">O</span>
            ne
        </span>;
};
const FocusoneTextLogo = M$FocusoneTextLogo;
export default FocusoneTextLogo;