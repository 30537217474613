import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';
import axios from 'axios';

import { useLoader } from '../../../../common/hooks';
import { Option } from '../../../../models/taskGroup/TaskGroup';
import { Attachment, FileInfo, RemarkFileList, TaskStore } from '../../../../models/taskStore/Task';
import { extractAccessToken } from '../../../../common/helpers';
import { createTask } from '../../../../data/taskStore/repositories/taskRepository';

type EditTaskStore = {
    key_id: string;
    type: string;
    subject: string;
    description?: string;
    group_id?: string;
    created_by: string;
    updated_by: string;
    attachment?: Attachment;
};

type NormalModeViewModelProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    selectedTask?: TaskStore;
    taskGroupList: Option[];
};

function NormalModeViewModel(props: NormalModeViewModelProps) {
    const { onClose, onRefresh, selectedTask, taskGroupList } = props;

    const loader = useLoader();
    const accessToken = extractAccessToken();

    const [filesUpload, setFilesUpload] = useState<File[]>([]);
    const [profileUpload, setProfileUpload] = useState<File>();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const profileInputRef = useRef<HTMLInputElement>(null);

    const [task, setTask] = useState<EditTaskStore>({
        key_id: '',
        type: '',
        subject: '',
        description: '',
        group_id: '',
        created_by: '',
        updated_by: ''
    });

    const [selectedTaskGroup, setSelectedTaskGroup] = useState<SingleValue<Option>>();

    const [model, setModel] = useState<string>('');

    useEffect(() => {
        if (!taskGroupList) {
            return;
        }

        setSelectedTaskGroup(taskGroupList.find(item => item.value === '0000'));
    }, [taskGroupList]);

    useEffect(() => {
        if (!selectedTask) {
            return;
        }

        const { key_id, type, subject, description, attachment, group_id, created_by, updated_by } =
            selectedTask;

        const attachmentData: Attachment = attachment ? JSON.parse(attachment) : '';
        const { file_list, profile_pic } = attachmentData;

        if (file_list.length > 0) {
            handleSetFileBlob(file_list);
        }

        if (profile_pic) {
            handleSetProfileBlob(profile_pic);
        }

        setTask({
            key_id,
            type,
            subject,
            description,
            group_id,
            created_by,
            updated_by,
            attachment: attachmentData
        });

        if (group_id) {
            setSelectedTaskGroup(taskGroupList.find(group => group.value === group_id));
        }

        if (description) {
            setModel(description);
        }
    }, []);

    const handleSetFileBlob = async (fileInfo: FileInfo[]) => {
        const fileBlob = await mapFileInfoListtoFileBlob(fileInfo);

        setFilesUpload(prevState => [...prevState, ...fileBlob] as File[]);
    };

    const handleSetProfileBlob = async (fileInfo: FileInfo) => {
        const profileBlob = await fileInfotoFileBlob(fileInfo);

        setProfileUpload(profileBlob ?? undefined);
    };

    const mapFileInfoListtoFileBlob = async (fileInfo: FileInfo[]) => {
        const fileObjectsPromises = fileInfo.map(async fileInfo => fileInfotoFileBlob(fileInfo));

        const fileObjects = await Promise.all(fileObjectsPromises);
        const validFileObjects = fileObjects.filter(file => file !== null);
        return validFileObjects;
    };

    const fileInfotoFileBlob = async fileInfo => {
        try {
            const url = new (window.URL ? URL : webkitURL)(fileInfo.file_path);
            const response = await axios.get(`${url}`, {
                //headers: { "Access-Control-Allow-Origin": "*" },
                responseType: 'blob'
            });

            if (response.status !== 200) {
                return null;
            }

            const mimeType = response.headers['content-type'];
            const file = new File([response.data], fileInfo.file_name, { type: mimeType });

            return file;
        } catch (error) {
            console.error(`Error fetching ${fileInfo.file_name}: ${error}`);

            return null;
        }
    };

    const handleChangeProfile = (files: FileList | null) => {
        if (!files || files.length === 0) {
            return;
        }

        setProfileUpload(files[0]);
    };

    const handleChangeTask = <K extends keyof typeof task>(key: K, value: (typeof task)[K]) => {
        setTask(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleChangeFile = (files: FileList | null) => {
        if (!files) {
            return;
        }

        setFilesUpload(prevState => [...prevState, ...Array.from(files)]);
    };

    const handleDeleteFile = (index: number) => {
        if (index < 0) {
            return;
        }

        const files = [...filesUpload];
        files.splice(index, 1);

        setFilesUpload(files);
    };

    const addTaskValidation = (task: EditTaskStore): [boolean, string] => {
        const { subject, created_by: createdBy } = task;

        if (!subject.trim()) {
            return [false, 'subject is null or empty.'];
        }
        if (!createdBy.trim()) {
            return [false, 'createdBy is null or empty.'];
        }

        return [true, ''];
    };

    // ? pass FormData to new task ref
    const getNormalFormData = async () => {
        try {
            const [valid, message] = addTaskValidation(task);

            if (!valid) {
                throw new Error(message);
            }

            const { key_id, subject, description, created_by: createdBy } = task;

            const fileList: RemarkFileList[] = [];

            const formData = new FormData();
            formData.append('key_id', key_id);
            formData.append('type', 'task');
            formData.append('subject', subject);
            formData.append('start_date_time', '999999999999');
            formData.append('end_date_time', '999999999999');
            // formData.append('description', description); // ? old editor
            formData.append('attachment', '');
            formData.append('is_active', 'true');
            formData.append('is_release', 'false');
            formData.append('group_id', selectedTaskGroup?.value ?? '0000');
            formData.append('created_by', createdBy);
            formData.append('created_on', '');
            formData.append('updated_by', accessToken.first_name + ' ' + accessToken.last_name);
            formData.append('updated_on', '');

            if (profileUpload) {
                formData.append(`profile`, profileUpload, 'profile-pic-' + profileUpload.name);
            }

            filesUpload.forEach((file, index) => {
                formData.append(`file-${index + 1}`, file, file.name);
                fileList.push({ file_name: file.name, url: '' });
            });

            // Attach file inside editor if exists
            const editorContentDOM = document.createElement('div');
            editorContentDOM.innerHTML = model;

            const attachmentNodes = editorContentDOM.querySelectorAll('img, video') as NodeListOf<
                HTMLImageElement | HTMLVideoElement
            >;
            await Promise.all(
                Array.from(attachmentNodes).map(async (node, index) => {
                    const url = node.getAttribute('src')!;

                    // if already exists on s3
                    if (url.includes('s3')) {
                        return;
                    }

                    const fileName = 'editor-file-' + (index + 1);
                    const file = await axios
                        .get<Blob>(url, { responseType: 'blob' })
                        .then(response => new File([response.data], fileName));

                    node.src = fileName;

                    URL.revokeObjectURL(url);
                    formData.append(file.name, file);
                })
            );

            const innerHtml = editorContentDOM.innerHTML;
            formData.append('description', innerHtml);

            return formData;
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            return;
        }
    };

    return {
        profileInputRef,
        profileUpload,
        setProfileUpload,
        handleChangeProfile,
        task,
        handleChangeTask,
        taskGroupList,
        selectedTaskGroup,
        setSelectedTaskGroup,
        model,
        setModel,
        fileInputRef,
        filesUpload,
        handleDeleteFile,
        handleChangeFile,
        handleClickAddTask: getNormalFormData,
        getNormalFormData
    };
}

export default NormalModeViewModel;
