import React from 'react';
import { observer } from 'mobx-react';
import { Label, Input, Button } from '../../../common/materials';
import LogsTable from './component/LogsTable';
import CreatableSelect from 'react-select/creatable';
import LogsViewModel from './Logs.viewmodel';
function Logs() {
  const {
    DEFAULT_ACTION,
    companyOptions,
    userName,
    setUserName,
    company,
    setCompany,
    action,
    setAction,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    resetDateTime,
    handleSearch,
    logs,
    userCompanyStore,
    userOptions,
    handleUserNameChange
  } = LogsViewModel();
  return <React.Fragment>
            <div className="flex flex-col gap-4">
                <p className="text-2xl font-bold">Report Log</p>
                <div className="flex flex-col gap-4 bg-white p-4">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2 flex flex-col">
                            <Label>Company</Label>
                            <CreatableSelect className="h-[32px]" options={companyOptions} value={company} onChange={selectedCompany => setCompany(selectedCompany)} isClearable />
                        </div>
                        <div className="flex flex-col">
                            <Label>Date From:</Label>
                            <div className="flex flex-row gap-2">
                                <Input type="date" name="start_date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                                <Input type="time" name="start_time" value={startTime} onChange={e => setStartTime(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2 flex flex-col">
                            <Label>Username</Label>
                            {userOptions !== null && userOptions.length > 0 ? <CreatableSelect options={userOptions} value={userOptions.find(option => option.value === userName)} onChange={handleUserNameChange} isClearable /> : <Input name="user-input" placeholder="User Name" disabled={userCompanyStore.userRole !== 'Super Admin'} value={userCompanyStore.userRole !== 'Super Admin' ? userCompanyStore.userName : userName} onChange={e => setUserName(e.target.value)} />}
                        </div>
                        <div className="flex flex-col">
                            <Label>Date From:</Label>
                            <div className="flex flex-row gap-2">
                                <Input type="date" name="end_date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                                <Input type="time" name="end_time" value={endTime} onChange={e => setEndTime(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2 flex flex-col">
                            <Label>Action</Label>
                            <CreatableSelect options={DEFAULT_ACTION} value={action} onChange={selectedAction => setAction(selectedAction)} isClearable />
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-4 gap-4">
                        <div className="col-span-1 flex gap-4">
                            <Button className="col-span-1 flex-1 bg-zinc-300 text-black">
                                Reset
                            </Button>
                            <Button className="col-span-1 flex-1 bg-primary-900 text-white" onClick={handleSearch}>
                                Search
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-4">
                    <LogsTable logs={logs} />
                </div>
            </div>
        </React.Fragment>;
}
export default observer(Logs);