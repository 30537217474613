import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineCloudUpload, AiOutlineFile } from 'react-icons/ai';
import { QuillEditor } from 'common/components';
import { Input, Label } from 'common/materials';
import useNewTaskViewModel from './NewTask.viewmodel';
type Props = {
  onClose?: () => void;
};
export type NewTaskHandle = {
  handleClickAddTask: () => Promise<void>;
};
const NewTask = forwardRef<NewTaskHandle, Props>((props, ref) => {
  const {
    onClose
  } = props;
  const {
    task,
    filesUpload,
    model,
    setModel,
    handleChangeTask,
    handleChangeFile,
    handleClickAddTask
  } = useNewTaskViewModel();
  const fileInputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => {
    return {
      handleClickAddTask
    };
  });
  return <React.Fragment>
            <div className="flex flex-col">
                <Label>Subject</Label>
                <Input value={task.subject} onChange={event => handleChangeTask('subject', event.target.value)} />
            </div>

            <div className="mt-2 flex gap-2">
                <div className="flex-1">
                    <Label>Start Date Time</Label>
                    <DatePicker selected={task.startDate} onChange={date => handleChangeTask('startDate', date ?? new Date())} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                </div>
                <div className="flex-1">
                    <Label>End Date Time</Label>
                    <DatePicker selected={task.endDate} onChange={date => handleChangeTask('endDate', date ?? new Date())} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                </div>
            </div>

            <div className="mt-2 flex flex-1 flex-col">
                <Label>Description</Label>
                <QuillEditor value={model} onChange={setModel} editorClassName="[&>.ql-container]:min-h-[85px]" />
            </div>

            <div className="mt-2 flex gap-2">
                <div className="flex-1">
                    <Label>Attachment</Label>
                    <div className="flex h-8 items-center justify-center gap-2 rounded-md border border-neutral-300" onClick={() => fileInputRef.current?.click()}>
                        <AiOutlineCloudUpload size={18} />
                        <span className="text-sm text-neutral-500">
                            Drop file to attach or <span className="text-primary-900">browse</span>
                        </span>
                    </div>
                </div>
                <div className="flex-1">
                    <Label>Assignee</Label>
                    <Input />
                </div>
            </div>

            <div className="mt-3 flex flex-wrap gap-2">
                {filesUpload.map(file => <div key={file.name} className="flex h-[140px] w-[140px] items-center justify-center overflow-hidden rounded-sm border bg-white">
                        {file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png') ? <img src={URL.createObjectURL(file)} alt="image" className="h-full w-full object-cover" /> : <React.Fragment>
                                <AiOutlineFile size={36} />
                                <span className="text-center">{file.name}</span>
                            </React.Fragment>}
                    </div>)}

                <input ref={fileInputRef} type="file" onChange={event => handleChangeFile(event.target.files)} className="hidden" />
            </div>
        </React.Fragment>;
});
export default NewTask;