import React, { useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { twMerge } from 'tailwind-merge';
import Marquee from 'react-fast-marquee';
import { IconButton, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, useBoolean, useConst } from '@chakra-ui/react';

// Icons
import { AiOutlineQuestionCircle, AiFillCaretDown } from 'react-icons/ai';
import { BsGrid3X3Gap, BsTicketPerforated } from 'react-icons/bs';
import { MdOutlineAnnouncement, MdOutlineAssignmentInd } from 'react-icons/md';
import { BiTask } from 'react-icons/bi';
import { FiUserPlus, FiUserCheck, FiUsers, FiList } from 'react-icons/fi';
import { TbReport, TbUsersGroup } from 'react-icons/tb';
import { PiChatsLight } from 'react-icons/pi';
import { IoNotificationsOutline } from 'react-icons/io5';
import { extractAccessToken, getCurrentDateTime, parseDateString, parseTimeString } from 'common/helpers';
import { Modal, ModalHeader } from 'common/materials';
import { useStore, useLoader } from 'common/hooks';
import UserCompanyMappingComponent from 'components/user/components/UserCompanyMappingComponent';
import CreateTaskComponent from 'components/task/components/CreateTaskComponent';
import logo from 'images/f1-new-logo.png';
import { LinkMapping } from 'models/link/Task';
import { UserApplicationMapping } from 'models/dashboard/SubDashboardComponentV2';
import { getFocusOneHelpToken } from 'data/focusOneHelp/repositories/focusOneHelp.repository';
import { getToken } from 'data/token/repositories/tokenRepository';
import NewTaskModal from './TopNavigation/NewTaskModal';
import SpeedTestPopover from './TopNavigation/SpeedTestPopover';
import ProfilePopover from './TopNavigation/ProfilePopover';
import LineConnectPreparationModal from './TopNavigation/LineConnectPreparationModal';
import LineConnectOnboardModal from './TopNavigation/LineConnectOnboardModal';
import SupportModal from './TopNavigation/SupportModal';
function TopNavigation() {
  const history = useHistory();
  const [showModalUserRegisterPopup, setShowModalUserRegisterPopup] = useBoolean();
  const [showModalCreateTaskSchedulePopup, setShowModalCreateTaskSchedulePopup] = useBoolean();
  const [showModalSupport, setShowModalSupport] = useBoolean();
  const [showModalNewTask, setShowModalNewTaskPopup] = useBoolean();
  const [showModalPreparationLineConnect, setShowModalPreparationLineConnect] = useBoolean();
  const [showModalLineConnect, setShowModalLineConnect] = useBoolean();
  const extra = useConst(extractAccessToken());
  const loader = useLoader();
  const {
    versionStore,
    userCompanyStore,
    companyMasterStore,
    focusOneLinkStore
  } = useStore();
  const {
    userName,
    userRole,
    email
  } = userCompanyStore;
  const {
    applicationVersion
  } = versionStore;
  const companymaster = companyMasterStore.companyMaster;
  const taskScheduleList = focusOneLinkStore.taskScheduleList;
  useEffect(() => {
    fetchVersion();
    getUserInformation('', '', 'active');
    fetchCompanies();
    fetchTasks();
  }, []);
  const fetchVersion = useCallback(async () => {
    await versionStore.fetchApplicationBuildVersion();
  }, []);
  const getUserInformation = useCallback(async (sid: string, citizenId: string, status: string) => {
    await userCompanyStore.fetchUserCompany(sid, citizenId, status);
  }, []);
  const fetchCompanies = async () => {
    await companyMasterStore.fetchCompanyMasterStore('', 'active');
  };
  const fetchTasks = async () => {
    const dateTimeNow = getCurrentDateTime();
    await focusOneLinkStore.fetchTasksSchedule(dateTimeNow, true);
  };
  const handleClickLogout = useCallback(async () => {
    const result = await Swal.fire({
      title: 'คุณต้องการออกจากทุกระบบ Focus One Web Application หรือไม่ ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยัน'
    });
    if (!result.isConfirmed) {
      return;
    }
    history.replace('/logout');
  }, []);
  const openModal = (isOpen: boolean, isReloadWindow: boolean = false) => {
    setShowModalCreateTaskSchedulePopup.toggle();
    if (isReloadWindow) window.location.reload();
  };
  const createTaskAnnounce = (task: LinkMapping) => {
    const startDateTime = parseDateString(task.datetime_start.substring(0, 8)) + ' ' + parseTimeString(task.datetime_start.substring(8, 14));
    const endDateTime = parseDateString(task.datetime_end.substring(0, 8)) + ' ' + parseTimeString(task.datetime_end.substring(8, 14));
    return task.subject + ' เริ่มต้น ' + startDateTime + ' สิ้นสุด ' + endDateTime;
  };
  const redirectToFocusOneHelp = async () => {
    loader.show();
    let helpToken: string | undefined = undefined;
    let accessToken = '';

    //if (userApplicationMapping.length > 0) {
    //    companyToken = getCompanyTokenDemo(userApplicationMapping);
    //}

    helpToken = await getHelpToken();
    if (helpToken) {
      accessToken = await getAccessToken(helpToken);
    }
    window.open('https://f1-help.thailanderp.com/ingress.aspx?token=' + accessToken);
    loader.hide();
  };
  const getCompanyTokenDemo = (userApplicationMapping: UserApplicationMapping[]) => {
    const foundF1ErpWebDemo = userApplicationMapping.find(user => user.groupName.includes('DEMO'));
    if (!foundF1ErpWebDemo) {
      return;
    }
    const companyToken = foundF1ErpWebDemo.groupItems.map(group => group.company_token)[0];
    return companyToken;
  };
  const getHelpToken = async () => {
    const getHelpTokenResponse = await getFocusOneHelpToken();
    return getHelpTokenResponse[1] ?? '';
  };
  const getAccessToken = async (companyToken: string) => {
    const getTokenResponse = await getToken({
      token: companyToken,
      citizen_id: extra.citizen_id,
      email: extra.email,
      first_name: extra.first_name,
      last_name: extra.last_name
    });
    return getTokenResponse[1] ?? '';
  };
  const menuGroups = useMemo(() => [{
    allowed: ['Super Admin'],
    menus: [{
      to: '/feed-task',
      Icon: FiList,
      label: 'Feed Task',
      tailwindClasses: '-left-0.5'
    }, {
      to: '#',
      onClick: setShowModalCreateTaskSchedulePopup.toggle,
      Icon: MdOutlineAnnouncement,
      label: 'New Announcement',
      tailwindClasses: '-left-0.5'
    }, {
      to: '#',
      onClick: setShowModalNewTaskPopup.toggle,
      Icon: BiTask,
      label: 'New Task',
      tailwindClasses: '-left-0.5'
    }]
  }, {
    allowed: ['Super Admin', 'Admin'],
    menus: [{
      to: '/user-register',
      Icon: FiUserPlus,
      label: 'User Register',
      tailwindClasses: ''
    }, {
      to: '/user-verify',
      Icon: FiUserCheck,
      label: 'User Verification',
      tailwindClasses: ''
    }, {
      to: '/assign-role-profile',
      Icon: MdOutlineAssignmentInd,
      label: 'Assign Role Profile',
      tailwindClasses: '-left-0.5'
    }, {
      to: '/assign-employee',
      Icon: TbUsersGroup,
      label: 'Assign Employee',
      tailwindClasses: '-left-0.5'
    }, {
      to: '/report-log',
      Icon: TbReport,
      label: 'Report Logs',
      tailwindClasses: '-left-0.5'
    }]
  }, {
    allowed: ['Super Admin', 'Admin', 'User'],
    menus: [{
      to: '#',
      onClick: setShowModalUserRegisterPopup.toggle,
      Icon: FiUsers,
      label: 'User Company Mapping',
      tailwindClasses: ''
    }]
  }], [setShowModalCreateTaskSchedulePopup, setShowModalNewTaskPopup, setShowModalUserRegisterPopup]);
  return <header className="flex">
            {/* Brand */}
            <Link to="/" className={twMerge('relative hidden min-w-[416px] cursor-pointer overflow-hidden bg-[#EEEEEE] py-2 lg:flex', 'after:absolute after:right-0 after:top-0 after:h-0 after:w-0 after:border-b-[50px] after:border-r-[50px] after:border-t-0 after:border-solid', "after:border-b-transparent after:border-l-transparent after:border-r-[#1C2F36] after:border-t-transparent after:content-['']")}>
                <img className="mx-auto h-[33px] w-[76px]" src={logo} alt="F1" />
            </Link>

            {/* Content */}
            <div className="flex flex-1 items-center justify-between bg-[#1C2F36] px-[15px]">
                <div className="flex flex-col gap-x-6 lg:flex-row lg:items-center">
                    <span className="text-[20px] font-bold text-white">
                        focus<span className="text-[#f39267]">O</span>ne ERP
                    </span>

                    <span className="text-[14px] font-bold text-[#E55C00]">
                        version {applicationVersion}
                    </span>
                </div>

                <div className="mx-10 hidden flex-1 xl:block">
                    <Marquee className="rounded-[5px] border border-neutral-700 py-1" speed={30} gradient={false} pauseOnHover>
                        {(!taskScheduleList || taskScheduleList.length === 0) && <span className="text-white">-</span>}

                        {taskScheduleList && taskScheduleList.length > 0 && taskScheduleList.map((task, index) => {
            if (task.activity_type !== 'announce') {
              return <React.Fragment key={index}></React.Fragment>;
            }
            return <span key={index} className="mr-14 text-white">
                                        {createTaskAnnounce(task)}
                                    </span>;
          })}
                    </Marquee>
                </div>

                {/* Right icons */}
                <div className="flex flex-row items-center">
                    <IconButton size="md" variant="unstyled" aria-label="Line Connect" className="group" icon={<img src="https://focusone-documents.s3.ap-southeast-1.amazonaws.com/line-icon/Line-Icon.png" className="h-8 w-8" />} onClick={setShowModalPreparationLineConnect.on} />

                    <IconButton size="md" variant="unstyled" aria-label="Ticket" className="group" icon={<BsTicketPerforated size={28} className="text-white transition-colors group-hover:text-primary-900" />} onClick={() => history.push('/ticket')} />

                    <SpeedTestPopover />

                    <IconButton size="md" variant="unstyled" aria-label="Notification" className="group" icon={<IoNotificationsOutline size={28} className="text-white transition-colors group-hover:text-primary-900" />} />

                    <IconButton size="md" variant="unstyled" aria-label="Question" className="group" icon={<PiChatsLight size={28} className="text-white transition-colors group-hover:text-primary-900" />} />

                    <Menu isLazy placement="bottom-end">
                        <MenuButton as={IconButton} size="md" aria-label="Menu" variant="unstyled" icon={<BsGrid3X3Gap size={28} className="text-white transition-colors group-hover:text-primary-900" />} className="group" />
                        <MenuList>
                            <MenuGroup title="F1 ERP ADMIN" className="text-base font-bold">
                                {menuGroups.reduce<JSX.Element[]>((accumulate, menuGroup, index) => {
                if (!menuGroup.allowed.includes(userRole) && !menuGroup.allowed.includes('User')) {
                  return accumulate;
                }
                if (index > 0) {
                  accumulate.push(<MenuDivider key={`divider-${index}`} />);
                }
                accumulate.push(<React.Fragment key={`menu-${index}`}>
                                                {menuGroup.menus.map(menu => <MenuItem key={menu.label} as={Link} to={menu.to} icon={<menu.Icon size={20} className={twMerge('relative text-primary-900', menu.tailwindClasses)} />} onClick={menu.onClick} className="text-sm">
                                                        {menu.label}
                                                    </MenuItem>)}
                                            </React.Fragment>);
                return accumulate;
              }, [])}
                            </MenuGroup>
                        </MenuList>
                    </Menu>

                    <IconButton size="md" variant="unstyled" aria-label="Help" className="group" icon={<AiOutlineQuestionCircle size={28} className="mr-1 text-white transition-colors group-hover:text-primary-900" />} onClick={redirectToFocusOneHelp} />

                    {/* <div className="bg-white w-[1px] h-[25px]" /> */}

                    <div className="flex cursor-pointer items-center gap-1 border-l border-l-white pl-2">
                        <AiFillCaretDown size={14} className="text-white" />
                        <span className="text-[14px] text-white">EN</span>
                    </div>

                    <ProfilePopover userName={userName} userRole={userRole} email={email} onClickNavigateToProfile={() => history.push('/profile')} onClickLogout={handleClickLogout} onClickOpenSupportModal={setShowModalSupport.toggle} />

                    <LineConnectPreparationModal motionPreset="slideInBottom" isOpen={showModalPreparationLineConnect} onClose={setShowModalPreparationLineConnect.off} onClickStartConnect={() => {
          setShowModalPreparationLineConnect.off();
          setShowModalLineConnect.on();
        }} />
                    <LineConnectOnboardModal motionPreset="slideInBottom" isOpen={showModalLineConnect} onClose={setShowModalLineConnect.off} />

                    <NewTaskModal isOpen={showModalNewTask} onClose={setShowModalNewTaskPopup.toggle} />

                    {/* modal User company maping */}
                    <Modal closeOnClickOverlay isOpen={showModalUserRegisterPopup} onClose={setShowModalUserRegisterPopup.toggle}>
                        <ModalHeader>User Company Mapping</ModalHeader>
                        <UserCompanyMappingComponent userId={extra.citizen_id} openModal={setShowModalUserRegisterPopup.toggle} companyMaster={companymaster} />
                    </Modal>

                    {/* modal Create new Task */}
                    <Modal fullscreen closeOnClickOverlay isOpen={showModalCreateTaskSchedulePopup} onClose={setShowModalCreateTaskSchedulePopup.toggle}>
                        <ModalHeader>New Announcement</ModalHeader>
                        {/* <ModalBody> */}
                        <CreateTaskComponent userId={extra.citizen_id} openModal={openModal} activityTypeProp={'announce'} mode={'TaskScheduler'} onClose={setShowModalCreateTaskSchedulePopup.toggle} />
                        {/* </ModalBody> */}
                        {/*<ModalFooter>*/}
                        {/*</ModalFooter>*/}
                    </Modal>

                    {/* Modal Support */}
                    <SupportModal isOpen={showModalSupport} onClose={setShowModalSupport.toggle} />
                </div>
            </div>
        </header>;
}
export default observer(TopNavigation);