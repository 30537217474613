import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as CompanyModel from '../../models/company/Company';
interface RequestFunctionAction {
  type: 'REQUEST_COMPANYMANAGE';
}
interface ReceiveFunctionAction {
  type: 'RECEIVE_COMPANYMANAGE';
  model: CompanyModel.ResponseCompany;
  version: number;
}
type KnownAction = RequestFunctionAction | ReceiveFunctionAction;
export const actionCreators = {
  requestFunction: (companyName: string, email: string, username: string, password: string, method: string, version: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    const body = {
      'company_name': companyName,
      'email': email,
      'username': username,
      'password': password
    };
    if (appState && appState.applicationManage && method == "POST") {
      console.log("call api register company");
      fetch(`/v1/company/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(response => (response.json() as Promise<CompanyModel.ResponseCompany>)).then(data => {
        console.log("register apps success. - " + version);
        dispatch({
          type: 'RECEIVE_COMPANYMANAGE',
          model: data,
          version: version
        });
      }).then(error => {});
      dispatch({
        type: 'REQUEST_COMPANYMANAGE'
      });
    }
  }
};
const unloadedState: CompanyModel.CompanyState = {
  isLoading: false,
  response: undefined,
  version: 0
};
export const reducer: Reducer<CompanyModel.CompanyState> = (state: CompanyModel.CompanyState | undefined, incomingAction: Action): CompanyModel.CompanyState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = (incomingAction as KnownAction);
  switch (action.type) {
    case 'REQUEST_COMPANYMANAGE':
      return {
        isLoading: false,
        version: 0
      };
    case 'RECEIVE_COMPANYMANAGE':
      return {
        isLoading: true,
        response: action.model,
        version: action.version + 1
      };
    default:
      return state;
  }
};