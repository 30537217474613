import React from 'react';
import { twMerge } from 'tailwind-merge';
function ModalFooter(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const {
    children,
    className,
    ...divProps
  } = props;
  return <div {...divProps} className={twMerge('mt-6 flex justify-end gap-2', className)}>
            {children}
        </div>;
}
export default ModalFooter;