import { block as _block$ } from "million/react";
import { HTMLProps } from 'react';
import { usePagination } from 'common/hooks';
import { TbChevronLeft, TbChevronLeftPipe, TbChevronRight, TbChevronRightPipe } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
type UsePaginationReturnType = ReturnType<typeof usePagination>;
type Props = Partial<Omit<UsePaginationReturnType, keyof Pick<UsePaginationReturnType, 'collections' | 'pageSize'>>> & {
  className?: string;
};
const M$Pagination = function Pagination(props: Props) {
  const {
    currentPage,
    numberOfPages,
    handleClickFirstPage,
    handleClickPreviousPage,
    handleClickNextPage,
    handleClickLastPage,
    className
  } = props;
  return <div className={twMerge('my-4 flex items-center justify-end px-4', className)}>
            <button className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200" onClick={handleClickFirstPage}>
                <TbChevronLeftPipe size={18} />
            </button>
            <button className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200" onClick={handleClickPreviousPage}>
                <TbChevronLeft size={18} />
            </button>
            <span className="mx-2">
                Page {currentPage || 1} of {numberOfPages || 1}
            </span>
            <button className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200" onClick={handleClickNextPage}>
                <TbChevronRight size={18} />
            </button>
            <button className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200" onClick={handleClickLastPage}>
                <TbChevronRightPipe size={18} />
            </button>
        </div>;
};
const Pagination = M$Pagination;
export default Pagination;