import React, { useDeferredValue, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';
import { Badge } from '@chakra-ui/layout';
import { Label, Button, Input, Modal, ModalBody, ModalFooter } from 'common/materials';
import { usePagination } from 'common/hooks';
import { Pagination } from 'common/components';
import WorkflowModal from './components/WorkflowRoleModal';
import UserRoleMappingModal from './components/UserRoleMappingModal';
import AssignRoleProfileModel from './AssignRoleProfile.viewmodel';
function AssignRoleProfile() {
  const {
    userRole,
    companyOptions,
    userOptions,
    selectedUsers,
    selectedCompany,
    roleProfileMapping,
    userRoleProfileMapping,
    showModalERP,
    showModalWorkflow,
    handleSelectCompany,
    handleSelectUser,
    setShowModalERP,
    setShowModalWorkflow,
    handleSaveUserRoles,
    platformOptions,
    selectedPlatform,
    handleSelectPlatform
  } = AssignRoleProfileModel();
  const [searchERPRole, setSearchERPRole] = useState('');
  const deferredSearchERPRole = useDeferredValue(searchERPRole);
  const userRoles = useMemo(() => {
    const search = deferredSearchERPRole.toLowerCase();
    return userRoleProfileMapping.filter(userRole => `${userRole.roleid} - ${userRole.rolename}`.toLowerCase().includes(search));
  }, [deferredSearchERPRole, userRoleProfileMapping]);
  const {
    collections: slicedUserRoles,
    ...paginationProps
  } = usePagination(userRoles, {
    pageSize: 10
  });
  return <React.Fragment>
            <p className="text-2xl font-bold">Assign Role Profile</p>
            <div className="flex flex-col p-4">
                <div className="flex flex-col gap-2 p-4">
                    <div className="grid grid-cols-2">
                        <div className="col-span-1 flex flex-col gap-2">
                            <Label>Company</Label>
                            <CreatableSelect isDisabled={userRole !== 'Super Admin'} placeholder="กรุณาเลือก Company" options={companyOptions} getOptionLabel={company => company.company_name} getOptionValue={company => company.sid} value={selectedCompany} onChange={handleSelectCompany} className="w-full" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1 flex flex-col gap-2">
                            <Label>System</Label>
                            <CreatableSelect isDisabled={userRole !== 'Super Admin' && userRole !== 'Admin' || !selectedCompany} placeholder="กรุณาเลือก System" options={platformOptions} getOptionLabel={platform => platform.platform_name} getOptionValue={platform => platform.platform_id} value={selectedPlatform} onChange={handleSelectPlatform} className="w-full" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1 flex flex-col gap-2">
                            <Label>Username</Label>
                            <CreatableSelect isDisabled={userRole !== 'Super Admin' && userRole !== 'Admin' || !selectedCompany || !selectedPlatform} placeholder="กรุณาเลือก User" options={userOptions} getOptionLabel={user => user.citizen_id} getOptionValue={user => user.citizen_id} value={selectedUsers} onChange={handleSelectUser} className="w-full" />
                        </div>
                    </div>
                </div>

                <p className="text-lg font-bold">Role</p>
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex flex-col gap-2">
                        <Label>focusOne ERP</Label>
                        <div className="flex flex-1 flex-row items-end gap-4">
                            <div className="ml-4 flex flex-col gap-1.5">
                                <div className="flex gap-2">
                                    <Input placeholder="Search role..." className="max-w-md" value={searchERPRole} onChange={event => setSearchERPRole(event.target.value)} />
                                    <Button disabled={!selectedUsers} outline size="sm" className="border-primary-900 text-primary-900" onClick={setShowModalERP.on}>
                                        Manage
                                    </Button>
                                </div>
                                {slicedUserRoles.length > 0 && <Pagination className="my-0 justify-start px-0 text-xs" {...paginationProps} />}
                                {slicedUserRoles.map(role => <Badge key={role.rolename} colorScheme="primary" className="w-fit">
                                        {role.roleid + (role.rolename && ` - ${role.rolename}`) ?? ''}
                                    </Badge>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ERP Role Madal */}
            <UserRoleMappingModal isOpen={showModalERP} onClose={setShowModalERP.off} roleProfileMapping={roleProfileMapping} userRoleProfileMapping={userRoleProfileMapping} selectedPlatform={selectedPlatform} selectedCompany={selectedCompany} selectedUsers={selectedUsers} onClickSaveRoles={handleSaveUserRoles} />

            {/* WorkFlow Role Madal */}
            <Modal fullscreen isOpen={showModalWorkflow} onClose={setShowModalWorkflow.off}>
                <ModalBody className="flex-1">
                    <WorkflowModal />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={setShowModalWorkflow.off}>Cancel</Button>
                    <Button className="bg-primary-900 text-white">Save</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>;
}
export default observer(AssignRoleProfile);