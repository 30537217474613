import React from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';
import { TaskGroup } from '../../../models/taskGroup/TaskGroup';
type AddTaskModalProps = {
  isOpen: boolean;
  deleteTaskGroup: TaskGroup;
  handleDeleteTaskGroup: (taskGroup: TaskGroup) => void;
  confirmDeleteTaskGroup: () => Promise<void>;
};
function DeleteTaskGroupModal(props: AddTaskModalProps) {
  const {
    deleteTaskGroup,
    isOpen,
    handleDeleteTaskGroup,
    confirmDeleteTaskGroup
  } = props;
  return <Modal isOpen={isOpen}>
            <ModalHeader>ยืนยันการลบ Task Group</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <div className="flex flex-row gap-4">
                    {deleteTaskGroup && <div className="flex">
                            <p>
                                หากต้องการ<span className="text-black underline">ลบ</span>{' '}
                                <span className="font-bold text-orange-500">
                                    {deleteTaskGroup.group_description}
                                </span>{' '}
                                กรุณากด Confirm
                            </p>
                        </div>}
                </div>
            </ModalBody>
            <ModalFooter className="mt-4">
                <Button outline onClick={() => {
        handleDeleteTaskGroup({
          group_index: '',
          group_id: '',
          group_description: ''
        });
      }} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={confirmDeleteTaskGroup} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default DeleteTaskGroupModal;