import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/company/Company';
import * as Models from '../../models/company/Company';
import { Button } from 'reactstrap';
import ModalRegisterCompanyComponent from './ModalRegisterCompanyComponent';
type Store = Models.CompanyState & typeof Stores.actionCreators;
class Company extends React.Component<any, {
  pageLoad: boolean;
  isOpenModalSerInApp: boolean;
  isOpenModalAccRej: boolean;
  companyUUID: string;
  companyName: string;
  typeClick: string;
}, Store> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageLoad: true,
      isOpenModalSerInApp: false,
      isOpenModalAccRej: false,
      companyUUID: "",
      companyName: "",
      typeClick: ""
    };
    this.BindData = this.BindData.bind(this);
    this.onClickTr = this.onClickTr.bind(this);
    this.afterOpenModalSerInApp = this.afterOpenModalSerInApp.bind(this);
    this.afterCloseModalSerInApp = this.afterCloseModalSerInApp.bind(this);
    this.onClickAccOrRej = this.onClickAccOrRej.bind(this);
    this.afterOpenModalAccRej = this.afterOpenModalAccRej.bind(this);
    this.afterCloseModalAccRej = this.afterCloseModalAccRej.bind(this);
    this.clickEmpty = this.clickEmpty.bind(this);
    this.afterCloseModalRegister = this.afterCloseModalRegister.bind(this);
  }
  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {
    this.props.requestFunction(this.state.pageLoad);
    this.setState({
      pageLoad: false
    });
  }
  public BindData() {
    this.setState({
      pageLoad: true
    });
    this.ensureDataFetched();
  }
  private onClickTr(e: any) {
    this.setState({
      pageLoad: false,
      isOpenModalSerInApp: true,
      companyUUID: e.currentTarget.dataset.companyuuid,
      companyName: e.currentTarget.dataset.companyname
    });
  }
  public afterOpenModalSerInApp() {
    this.setState({
      isOpenModalSerInApp: false
    });
  }
  public afterCloseModalSerInApp() {
    this.setState({
      pageLoad: true
    });
  }
  private onClickAccOrRej(e: any) {
    this.setState({
      pageLoad: false,
      isOpenModalAccRej: true,
      companyUUID: e.target.dataset.companyuuid,
      companyName: e.target.dataset.companyname,
      typeClick: e.target.dataset.typeclick
    });
  }
  public afterOpenModalAccRej() {
    this.setState({
      isOpenModalAccRej: false
    });
  }
  public afterCloseModalAccRej() {
    this.setState({
      pageLoad: true
    });
  }
  public afterCloseModalRegister() {
    this.setState({
      pageLoad: true
    });
  }
  public clickEmpty() {}
  render() {
    var {
      isOpenModalSerInApp
    } = this.state;
    var {
      isOpenModalAccRej
    } = this.state;
    var {
      companyUUID
    } = this.state;
    var {
      companyName
    } = this.state;
    var {
      typeClick
    } = this.state;
    return <div style={{
      paddingLeft: 'unset'
    }}>
                <ModalRegisterCompanyComponent BindData={this.BindData.bind(this)} afterCloseModalRegister={this.afterCloseModalRegister.bind(this)} />
                <hr />
                <h4 className="mb-3" style={{
        fontWeight: 'bold'
      }}>Company</h4>

                {this.props.isLoading && this.renderService()}
            </div>;
  }
  renderService() {
    return <React.Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr style={{
            background: "rgba(9, 132, 227, 1)",
            color: "#fff",
            fontSize: '10pt'
          }}>
                            <th>Company UUID</th>
                            <th>Company Name</th>
                            <th>Company ID</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.isLoading && this.props.response.result_list != undefined && this.props.response.result_list.map((item: Models.Company) => <tr key={item.company_uuid} className={'table-tbody table-onclick table-tr-' + item.active_status} data-companyuuid={item.company_uuid} data-companyname={item.company_name} onClick={item.active_status == "active" ? this.onClickTr : this.clickEmpty}>
                                <td>{item.company_uuid}</td>
                                <td>{item.company_name}</td>
                                <td>{item.company_id}</td>
                                <td>{item.email}</td>
                                <td>
                                    {item.active_status == "pending" ? <div>
                                            <Button color="primary" data-companyuuid={item.company_uuid} data-companyname={item.company_name} data-typeclick={'Accept'} onClick={this.onClickAccOrRej}>Accept</Button>{' '}
                                            <Button color="danger" data-companyuuid={item.company_uuid} data-companyname={item.company_name} data-typeclick={'Reject'} onClick={this.onClickAccOrRej}>Reject</Button>
                                        </div> : item.active_status}
                                </td>
                                <td>{item.created_by}</td>
                                <td></td>
                            </tr>)}
                    </tbody>
                </table>
            </React.Fragment>;
  }
}
export default connect((state: ApplicationState) => state.company, Stores.actionCreators)(Company);