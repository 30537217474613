import { makeAutoObservable, runInAction } from 'mobx';
import { getApplicationsLinkedToTheCompany } from '../../data/company/repositories/companyApplicationMappingRepository';

import { arrGetCompanyApplicationMapping } from '../../models/company/CompanyApplicationMapping';
import RootStore from '../Root.store';

class UserApplicationStore {
    private _userApplications: arrGetCompanyApplicationMapping[] = [];

    public get userApplications() {
        return this._userApplications;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchUserApplications(citizenId: string) {
        const [error, responseUserApplicationList] =
            await getApplicationsLinkedToTheCompany({
                citizen_id: citizenId
            });

        if (error) {
            throw error;
        }

        this._replaceSpaceToNewLine(responseUserApplicationList.result_list);

        runInAction(() => {
            this._userApplications = responseUserApplicationList.result_list;
        });
    }

    private _replaceSpaceToNewLine(appGroups: arrGetCompanyApplicationMapping[]) {
        const appGroupsLength = appGroups.length;
        for (let i = 0; i < appGroupsLength; i++) {
            appGroups[i].application_list = appGroups[i].application_list.map(app => ({
                ...app,
                application_name: app.application_name.replace(' ', '\n')
            }));
        }
    }
}

export default UserApplicationStore;
