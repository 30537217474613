import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import FocusoneTextLogo from '../FocusoneTextLogo';
import Icons from 'assets/Icons';
function Step4() {
  return <React.Fragment>
            <div className="flex items-baseline space-x-3 text-lg">
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#F7980A] font-bold">
                    4
                </span>
                <span className="flex-1 text-lg font-bold ">
                    เสร็จสิ้นขั้นตอนการขอรับการแจ้งเตือนผ่าน Line{' '}
                    <FocusoneTextLogo className="text-xl" />
                </span>
            </div>

            <div className="mt-6 flex flex-1 flex-col items-center justify-center gap-4 rounded-md p-4 shadow-[0px_0px_8px_0px_rgba(69,69,69,0.2)] lg:mx-10 xl:mx-12 2xl:mx-0">
                <AiFillCheckCircle size={52} className="text-line-green-400" />
                <p className="text-center text-neutral-800">
                    <span className="font-semibold">focusone</span>{' '}
                    สามารถรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line Chat
                </p>

                <button className="bg-line-green-400 hover:bg-line-green-500 relative w-full rounded-md px-4 py-2 text-white shadow-md transition-colors active:brightness-95 disabled:opacity-60">
                    ปิด
                    <img src={Icons.ICON_CURSOR_CLICK} className="absolute -left-4 -top-1 h-[55px] w-[55px]" />
                </button>
            </div>
        </React.Fragment>;
}
export default Step4;