import 'react-datepicker/dist/react-datepicker.css';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, TextArea } from '../../common/materials';
import { useLoader } from '../../common/hooks';
import { extractAccessToken, parseDateToString } from '../../common/helpers';
import { CreateTaskResponse } from '../../models/taskStore/Task';
import { createTaskAnnouncement } from '../../data/taskStore/repositories/taskRepository';
// import { CreateTaskResponse } from '../../models/link/Task';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};
function NewAnnouncementModal(props: Props) {
  const {
    isOpen,
    onClose
  } = props;
  const loader = useLoader();
  const [announcementForm, setAnnouncementForm] = useState({
    subject: '',
    activityType: '',
    description: '',
    startDate: new Date(),
    endDate: new Date()
  });

  //const LIMIT_DESCRIPTION_CHAR = 3000;

  const handleChangeAnnouncementForm = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setAnnouncementForm(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, [setAnnouncementForm]);
  const handleChangeDateAnnouncementForm = useCallback((key: keyof Pick<typeof announcementForm, 'startDate' | 'endDate'>) => (date: Date | null, _event: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!date) {
      return;
    }
    setAnnouncementForm(prevState => ({
      ...prevState,
      [key]: date
    }));
  }, [setAnnouncementForm]);
  const handleClickSave = useCallback(async () => {
    try {
      loader.show();
      const {
        subject,
        description,
        startDate,
        endDate
      } = announcementForm;

      //if (description.length > LIMIT_DESCRIPTION_CHAR) {
      //    throw {
      //        response: {
      //            data: {
      //                message: `You have exceeded the maximum number of characters allowed for the task description (${LIMIT_DESCRIPTION_CHAR} characters)`
      //            }
      //        }
      //    };
      //}

      const [startDateString, startTimeString] = parseDateToString(startDate);
      const [endDateString, endTimeString] = parseDateToString(endDate);
      const formData = new FormData();
      formData.append('type', 'announcement');
      formData.append('subject', subject);
      formData.append('start_date_time', startDateString + startTimeString);
      formData.append('end_date_time', endDateString + endTimeString);
      formData.append('description', description);
      formData.append('is_active', 'true');
      formData.append('is_release', 'false');
      formData.append('created_by', extractAccessToken().citizen_id);
      formData.append('created_on', '');
      formData.append('updated_by', '');
      formData.append('updated_on', '');
      const [error, data] = await createTaskAnnouncement(formData);
      if (error) {
        throw error;
      }
      if (!data) {
        return;
      }

      // console.log('jj data', data);

      if (data.result) {
        const result = await Swal.fire({
          title: 'Success!',
          text: `Create announcement success`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false
        });
        if (result.isConfirmed) {
          onClose && onClose();
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
      const result = await Swal.fire({
        title: 'Error!',
        text: (e as any).response.data.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      });
      if (result.isConfirmed) {
        onClose && onClose();
        // window.location.reload();
      }
    } finally {
      loader.hide();
    }
  }, [announcementForm, loader]);

  // ? link-task
  // const handleClickSave = useCallback(async () => {
  //     try {
  //         loader.show();

  //         const {
  //             subject,
  //             description,
  //             startDate: startDateTime,
  //             endDate: endDateTime
  //         } = announcementForm;

  //         if (description.length > LIMIT_DESCRIPTION_CHAR) {
  //             throw {
  //                 response: {
  //                     data: {
  //                         message: `You have exceeded the maximum number of characters allowed for the task description (${LIMIT_DESCRIPTION_CHAR} characters)`
  //                     }
  //                 }
  //             };
  //         }

  //         const options: Intl.DateTimeFormatOptions = {
  //             timeZone: 'Asia/Bangkok',
  //             hour12: false,
  //             year: 'numeric',
  //             month: '2-digit',
  //             day: '2-digit',
  //             hour: '2-digit',
  //             minute: '2-digit',
  //             second: '2-digit'
  //         };

  //         // ---- Start Date Time ----
  //         const startDateTimeFormat = startDateTime.toLocaleString('en-US', options).split(', ');

  //         let startDate = startDateTimeFormat[0];
  //         startDate = startDate.replace('/', '').replace('/', '');
  //         startDate =
  //             startDate.substring(4, 8) + startDate.substring(0, 2) + startDate.substring(2, 4);

  //         const startTime = startDateTimeFormat[1]
  //             .substring(0, 8)
  //             .replace(':', '')
  //             .replace(':', '');

  //         // ---- End Date Time ----
  //         const endDateTimeFormat = endDateTime.toLocaleString('en-US', options).split(', ');

  //         let endDate = endDateTimeFormat[0];
  //         endDate = endDate.replace('/', '').replace('/', '');
  //         endDate = endDate.substring(4, 8) + endDate.substring(0, 2) + endDate.substring(2, 4);

  //         const endTime = endDateTimeFormat[1].substring(0, 8).replace(':', '').replace(':', '');

  //         const formData = new FormData();
  //         formData.append('subject', subject);
  //         formData.append('detail', description);
  //         formData.append('start_date', startDate);
  //         formData.append('end_date', endDate);
  //         formData.append('start_time', startTime);
  //         formData.append('end_time', endTime);
  //         formData.append('activity_type', 'announce');
  //         formData.append('remarks_request', '');

  //         const data = (
  //             await axios.post<CreateTaskResponse>('v1/link/task', formData, {
  //                 headers: {
  //                     Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
  //                     'Content-Type': 'multipart/form-data'
  //                 },
  //                 params: {
  //                     user_id: extractAccessToken().citizen_id
  //                 }
  //             })
  //         ).data;

  //         if (data.a_object_link) {
  //             const result = await Swal.fire({
  //                 title: 'Success!',
  //                 text: `Create task success`,
  //                 icon: 'success',
  //                 showCancelButton: false,
  //                 confirmButtonColor: '#3085d6',
  //                 confirmButtonText: 'Ok',
  //                 allowOutsideClick: false
  //             });

  //             if (result.isConfirmed) {
  //                 onClose && onClose();
  //                 window.location.reload();
  //             }
  //         }
  //     } catch (e) {
  //         console.log(e);

  //         const result = await Swal.fire({
  //             title: 'Error!',
  //             text: (e as any).response.data.message,
  //             icon: 'error',
  //             showCancelButton: false,
  //             confirmButtonColor: '#3085d6',
  //             confirmButtonText: 'Yes',
  //             allowOutsideClick: false
  //         });

  //         if (result.isConfirmed) {
  //             onClose && onClose();
  //             // window.location.reload();
  //         }
  //     } finally {
  //         loader.hide();
  //     }
  // }, [announcementForm, loader]);

  return <Modal fullscreen closeOnClickOverlay isOpen={isOpen} onClose={onClose}>
            <ModalHeader>New Announcement</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <span className="font-semibold text-gray-500">Add Information</span>
                <div className="flex flex-col gap-2 p-2">
                    <Label>Subject</Label>
                    <Input name="subject" placeholder="Subject name" onChange={handleChangeAnnouncementForm} />
                </div>
                <div className="flex gap-2 p-2">
                    <div className="flex flex-1 flex-col">
                        <Label>Start Date Time</Label>
                        <DatePicker data-name="startDate" selected={announcementForm.startDate} onChange={handleChangeDateAnnouncementForm('startDate')} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                    </div>
                    <div className="flex flex-1 flex-col">
                        <Label>End Date Time</Label>
                        <DatePicker data-name="endDate" selected={announcementForm.endDate} onChange={handleChangeDateAnnouncementForm('endDate')} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="dd/MM/yyyy HH:mm" customInput={<Input />} />
                    </div>
                </div>
                {/* <div className="flex flex-col gap-2 p-2">
                    <Label>Activity Type</Label>
                    <Input
                        name="activityType"
                        placeholder="announce"
                        onChange={handleChangeAnnouncementForm}
                    />
                 </div> */}
                <div className="flex flex-1 flex-col gap-2 p-2">
                    <Label>Description</Label>
                    <TextArea name="description" className="flex-1" onChange={handleChangeAnnouncementForm} />
                </div>
            </ModalBody>
            <ModalFooter className="mt-auto flex justify-end gap-4 px-2">
                <Button size="sm" outline className="text-gray-400" onClick={() => onClose && onClose()}>
                    Cancel
                </Button>
                <Button size="sm" className="bg-primary-900 text-white" onClick={handleClickSave}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>;
}
export default NewAnnouncementModal;