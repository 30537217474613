import { Quill } from 'react-quill';

const Video = Quill.import('formats/video');

class CoustomVideo extends Video {
    static create(value: any) {
        const node = super.create(value);

        const video = document.createElement('video');
        video.setAttribute('controls', 'true');
        video.setAttribute('type', 'video/mp4');
        video.setAttribute('style', 'height: 200px; width: 100%');
        video.setAttribute('src', value);
        node.appendChild(video);

        return node;
    }
}

CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'DIV';

Quill.register('formats/video', CoustomVideo);

export const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video'
];
