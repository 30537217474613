import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from '../../../models';
import {
    requestCancelTask,
    requestCreateTask,
    requestCreateTaskAnnouncement,
    requestEditTask,
    requestEditTaskAnnouncement,
    requestGetCurrentAnnouncement,
    requestGetTask,
    requestReleaseTask
} from '../datasources/task.datasource';
import {
    CancelTaskRequest,
    CancelTaskResponse,
    CreateTaskResponse,
    EditTaskResponse,
    GetCurrentAnnouncementRequest,
    GetTaskStoreRequest,
    ReleaseTaskRequest,
    ReleaseTaskResponse,
    TaskStoreMapping
} from '../schemas';

export async function getTask(
    params: GetTaskStoreRequest
): Promise<DatasourceReturnType<ResponseError, TaskStoreMapping[]>> {
    try {
        const data = (await requestGetTask(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function createTask(
    formData: FormData
): Promise<DatasourceReturnType<ResponseError, CreateTaskResponse>> {
    try {
        const data = (await requestCreateTask(formData)).data;
        // console.log('[Debug] create task success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] create task error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function editTask(
    formData: FormData
): Promise<DatasourceReturnType<ResponseError, EditTaskResponse>> {
    try {
        const data = (await requestEditTask(formData)).data;
        // console.log('[Debug] edit task success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] edit task error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
export async function releaseTask(
    params: ReleaseTaskRequest
): Promise<DatasourceReturnType<ResponseError, ReleaseTaskResponse>> {
    try {
        const data = (await requestReleaseTask(params)).data;
        // console.log('[Debug] release task success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] release task error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function cancelTask(
    params: CancelTaskRequest
): Promise<DatasourceReturnType<ResponseError, CancelTaskResponse>> {
    try {
        const data = (await requestCancelTask(params)).data;
        // console.log('[Debug] cancel task success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] cancel task error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getCurrentAnnouncement(
    params: GetCurrentAnnouncementRequest
): Promise<DatasourceReturnType<ResponseError, TaskStoreMapping[]>> {
    try {
        const data = (await requestGetCurrentAnnouncement(params)).data;
        // console.log('[Debug] get current annonucement success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] get current announcement error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function createTaskAnnouncement(
    formData: FormData
): Promise<DatasourceReturnType<ResponseError, CreateTaskResponse>> {
    try {
        const data = (await requestCreateTaskAnnouncement(formData)).data;
        // console.log('[Debug] create task announcement success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] create task announcement error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function editTaskAnnouncement(
    formData: FormData
): Promise<DatasourceReturnType<ResponseError, EditTaskResponse>> {
    try {
        const data = (await requestEditTaskAnnouncement(formData)).data;
        // console.log('[Debug] edit task announcement success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] edit task announcement error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
