import * as Authenthication from './authentication/Authentication';
import * as AuthenthicationModel from '../models/authentication/Authentication';
import * as Pending from './platform/Pending';
import * as PendingModel from '../models/platform/Pending';
import * as Platform from './group/Platform';
import * as PlatformManage from './group/PlatformManage';
import * as PlatformInApplication from './group/PlatformInApplication';
import * as ApplicationManage from './platform/ApplicationManage';
import * as PlatformModel from '../models/group/Platform';
import * as PlatformInApplicationModel from '../models/group/PlatformInApplication';
import * as ConfirmStatusApplicationModel from '../models/platform/ConfirmStatusApplication';
import * as Application from './platform/Application';
import * as ApplicationModel from '../models/platform/Application';
import * as ApplicationManageModel from '../models/platform/ApplicationManage';
import * as ConfirmStatusApplication from './platform/ConfirmStatusApplication';
import * as CompanyModel from '../models/company/Company';
import * as Company from './company/Company';
import * as CompanyManage from './company/CompanyManage';
import * as UserCompanyModel from '../models/user/UserCompany';
import * as UserCompanyStore from './user/UserCompany';

import * as UserComapnyMasterStore from './user/UserCompanyMaster';
import * as UserComapnyMastermodel from '../models/user/UserCompanyMaster';

import * as SubDashboardComponentModel from '../models/dashboard/SubDashboardComponent';
import * as SubDashboardComponent from './dashboard/SubDashboardComponent';

import * as UserApplicationManagementModel from '../models/user/UserApplicationManagement';
import * as UserApplicationManagementStore from './user/UserApplicationManagement';

import * as CompanyApplicationMappingModel from '../models/company/CompanyApplicationMapping';
import * as CompanyApplicationMappingStore from './company/CompanyApplicationMapping';

import * as SubDashboardComponentV2Model from '../models/dashboard/SubDashboardComponentV2';
import * as SubDashboardComponentV2Store from './dashboard/SubDashboardComponentV2';

import * as FApplicationModel from '../models/application/FApplication';
import * as FApplicationStore from './application/FApplication';

import RootStore from './Root.store';

// The top-level state object
export interface ApplicationState {
    authentication: AuthenthicationModel.AuthenticationState | undefined;
    pending: PendingModel.PenState | undefined;
    platform: PlatformModel.PlatformState | undefined;
    platformManage: PlatformModel.PlatformState | undefined;
    platformInApplication: PlatformInApplicationModel.PlatformInApplicationState | undefined;
    applicationManage: ApplicationManageModel.ApplicationManageState | undefined;
    application: ApplicationModel.ApplicationState | undefined;
    confirmStatusApplication: ConfirmStatusApplicationModel.ConfirmApplicationState | undefined;
    company: CompanyModel.CompanyState | undefined;
    companyManage: CompanyModel.CompanyState | undefined;
    subDashboardComponent: SubDashboardComponentModel.SubDashboardState | undefined;
    UserCompany: UserCompanyModel.AddUserCompanyState | undefined;
    UserCompanyMaster: UserComapnyMastermodel.UserCompanyMasterState | undefined;
    UserAppManage: UserApplicationManagementModel.GetUserAppManageState | undefined;
    CompanyApplication: CompanyApplicationMappingModel.CompanyApplicationMappingState | undefined;
    SubDashboardComponentV2: SubDashboardComponentV2Model.SubDashboardComponentV2State | undefined;
    FApplication: FApplicationModel.CFApplicationState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: Authenthication.reducer,
    pending: Pending.reducer,
    platform: Platform.reducer,
    platformManage: PlatformManage.reducer,
    platformInApplication: PlatformInApplication.reducer,
    applicationManage: ApplicationManage.reducer,
    application: Application.reducer,
    confirmStatusApplication: ConfirmStatusApplication.reducer,
    company: Company.reducer,
    companyManage: CompanyManage.reducer,
    subDashboardComponent: SubDashboardComponent.reducer,
    UserCompany: UserCompanyStore.rootReducer,
    UserCompanyMaster: UserComapnyMasterStore.reducer,
    UserAppManage: UserApplicationManagementStore.rootReducer,
    CompanyApplication: CompanyApplicationMappingStore.reducer,
    SubDashboardComponentV2: SubDashboardComponentV2Store.reducer,
    FApplication: FApplicationStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export const store = new RootStore();
