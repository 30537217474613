import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Option } from '../../../../models/taskGroup/TaskGroup';
import { useLoader } from '../../../../common/hooks';
import { extractAccessToken } from '../../../../common/helpers';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';
import axios from 'axios';

type PosterModeViewModelProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

function PosterModeViewModel(props: PosterModeViewModelProps) {
    const { onClose, onRefresh, taskGroupList } = props;

    const loader = useLoader();
    const accessToken = extractAccessToken();

    const [selectedTaskGroup, setSelectedTaskGroup] = useState<SingleValue<Option>>();

    const [selectedBackground, setSelectedBackground] = useState('');
    const backGroundMode = useMemo(() => {
        return ['Color', 'Image'];
    }, []);
    const [selectedMode, setSelectedMode] = useState<string>(backGroundMode[0]);

    const [model, setModel] = useState<string>('');

    useEffect(() => {
        if (!taskGroupList) {
            return;
        }

        setSelectedTaskGroup(taskGroupList.find(item => item.value === '0000'));
    }, [taskGroupList]);

    const handleChangeBackgroundMode = (selectedMode: string) => {
        setSelectedMode(selectedMode);
    };

    const handleChangePosterBackground = useCallback((background: string) => {
        setSelectedBackground(background);
    }, []);

    const handleChangePosterBackgroundImage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            if (!files || files.length === 0) {
                return;
            }

            handleChangePosterBackground(URL.createObjectURL(files[0]));
        },
        []
    );

    // ? pass FormData to new task ref
    const getPosterFormData = async () => {
        try {
            const formData = new FormData();

            if (!model.trim()) {
                throw new Error('poster_description is null or empty.');
            }

            formData.append('poster_subject', model);
            formData.append('poster_background', selectedBackground);

            if (selectedBackground.includes('blob')) {
                const file = await axios
                    .get<Blob>(selectedBackground, { responseType: 'blob' })
                    .then(response => new File([response.data], 'poster_background_image'));

                URL.revokeObjectURL(selectedBackground);
                formData.append(file.name, file, 'poster-background-image-' + file.name);
            }

            return formData;
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            return;
        }
    };

    return {
        selectedBackground,
        backGroundMode,
        selectedMode,
        handleChangeBackgroundMode,
        model,
        setModel,
        getPosterFormData,
        handleChangePosterBackground,
        handleChangePosterBackgroundImage
    };
}

export default PosterModeViewModel;
