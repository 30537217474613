import { useRef } from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, useConst } from '@chakra-ui/react';
import { Button } from 'common/materials';
import UserSupportComponent, { UserSupportComponentRefHandle } from 'components/user/UserSupportComponent';
import { extractAccessToken } from 'common/helpers';
type OwnProps = {
  /** Custom modal header (default is 'Support') */
  header?: string;
};
type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & OwnProps;
function SupportModal(props: Props) {
  const {
    isOpen,
    header,
    onClose
  } = props;
  const userSupportComponentRef = useRef<UserSupportComponentRefHandle>(null);
  const extra = useConst(extractAccessToken());
  return <Modal size="6xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="text-xl">{header ?? 'Support'}</ModalHeader>
                <ModalBody className="overflow-auto">
                    <UserSupportComponent ref={userSupportComponentRef} username={extra.username} email={extra.email} onClose={onClose} />
                </ModalBody>
                <ModalFooter className="gap-2">
                    <Button size="sm" outline className="w-fit text-neutral-400" onClick={onClose}>
                        Close
                    </Button>
                    <Button size="sm" outline className="w-fit border-none bg-primary-900 text-white" onClick={() => userSupportComponentRef.current?.handleClickSendEmail()}>
                        Send Email
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>;
}
export default SupportModal;