import axios from 'axios';

import { Log, LogRequest, LogRespones } from '../schemas';

export async function getAll(params: LogRequest) {
    return axios.post<Log[]>('/v1/GetConditionLogs', params, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
