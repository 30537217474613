import React, { useMemo } from 'react';
import { Button } from '../../../common/materials';
import { UserInformationResponse } from '../../../models/citizen/Citizen';
type Props = {
  userInformation: UserInformationResponse;
  copyUserInformation: UserInformationResponse;
  handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
function OTPType(props: Props) {
  const {
    userInformation
  } = props;
  const {
    sign_type
  } = userInformation;
  const signTypeRadioButtons = useMemo(() => [{
    ref: React.createRef<HTMLInputElement>(),
    label: 'OTP ไปยัง E-mail',
    defaultChecked: sign_type?.includes('mail') ?? false,
    value: '_email'
  }, {
    ref: React.createRef<HTMLInputElement>(),
    label: 'OTP ไปยัง Appication Line',
    defaultChecked: sign_type?.includes('line') ?? false,
    value: '_line'
  }], [sign_type]);
  return <React.Fragment>
            <p className="text-lg font-medium">ประเภทการรับ OTP</p>
            <hr />

            <div className="flex flex-col">
                <p>ขั้นตอนการขอ OTP ผ่าน Line</p>

                <p className="mt-2">1. เพิ่มเพื่อน Line FocusOne</p>
                <div className="px-4">
                    <img src="https://onlineform.focusonesoftware.com/images/qr-code-add-friend.png" alt="LINE-QR" className="h-[150px] w-[150px]" />
                    <p>
                        Add Line FocusOne Official Account
                        <br />
                        เพื่อรับ Notification หรือ
                        <br />
                        เพิ่มเพื่อนปุ่มด้านขวา{' '}
                        <span className="rounded-sm bg-[#00C854] px-4 py-1 text-white">
                            เพิ่มเพื่อน
                        </span>
                    </p>
                </div>

                <p className="mt-2">2. ผูก ID line กับ Online Form</p>
                <div className="px-4">
                    <Button outline className="w-fit bg-[#00C854] text-white">
                        ผูก ID Line
                    </Button>
                </div>

                <p className="mt-2">3. เลือกประเภทที่จะขอ OTP</p>
                <div className="px-4">
                    {signTypeRadioButtons.map(button => <div key={button.label} className="flex gap-2">
                            <input type="radio" name="otp_type" defaultChecked={button.defaultChecked} value={button.value} />
                            <p>{button.label}</p>
                        </div>)}
                </div>
            </div>
        </React.Fragment>;
}
export default OTPType;