import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Label, Select } from 'common/materials';
import { CompanyMaster } from 'models/company/Company';
import useAssignEmployeeViewModel from './AssignEmployee.viewmodel';
import { CompanyPlatformMapping } from 'data/company/schemas';
import { UserCompany } from 'models/user/UserCompany';
import { ManageEmployeeModal } from './components';
function AssignEmployee() {
  const {
    userRole,
    companyOptions,
    selectedCompany,
    platformOptions,
    selectedPlatform,
    userOptions,
    selectedUsers,
    showManageEmployeeModal,
    employee,
    handleSelectCompany,
    handleSelectPlatform,
    handleSelectUser,
    setShowManageEmployeeModal,
    handleClickSaveChangedProfile,
    handleClickSaveNewPassword
  } = useAssignEmployeeViewModel();
  const getCompanyLabel = useCallback((company: CompanyMaster) => company.company_name, []);
  const getCompanyValue = useCallback((company: CompanyMaster) => company.sid, []);
  const getPlatformLabel = useCallback((platform: CompanyPlatformMapping) => platform.platform_name, []);
  const getPlatformValue = useCallback((platform: CompanyPlatformMapping) => platform.platform_id, []);
  const getUserLabel = useCallback((user: UserCompany) => user.citizen_id, []);
  return <React.Fragment>
            <p className="text-2xl font-bold">Assign Employee</p>
            <div className="p-4">
                <div className="space-y-2 p-4 xl:w-1/2">
                    <div>
                        <Label>Company</Label>
                        <Select isDisabled={userRole !== 'Super Admin'} placeholder="กรุณาเลือก Company" options={companyOptions} getOptionLabel={getCompanyLabel} getOptionValue={getCompanyValue} value={selectedCompany} onChange={handleSelectCompany} />
                    </div>
                    <div>
                        <Label>System</Label>
                        <Select isDisabled={userRole !== 'Super Admin' && userRole !== 'Admin' || !selectedCompany} placeholder="กรุณาเลือก System" options={platformOptions} getOptionLabel={getPlatformLabel} getOptionValue={getPlatformValue} value={selectedPlatform} onChange={handleSelectPlatform} />
                    </div>
                    <div>
                        <Label>Username</Label>
                        <Select isDisabled={userRole !== 'Super Admin' && userRole !== 'Admin' || !selectedCompany || !selectedPlatform} placeholder="กรุณาเลือก User" options={userOptions} getOptionLabel={getUserLabel} getOptionValue={getUserLabel} value={selectedUsers} onChange={handleSelectUser} />
                    </div>
                </div>

                <div className="p-4">
                    <Button outline size="sm" className="ml-auto border-primary-900 text-primary-900" onClick={setShowManageEmployeeModal.on}>
                        Manage
                    </Button>
                </div>
            </div>

            <ManageEmployeeModal isOpen={showManageEmployeeModal} selectedCompany={selectedCompany} selectedPlatform={selectedPlatform} employee={employee} onClose={setShowManageEmployeeModal.off} onClickSave={handleClickSaveChangedProfile} onClickSaveNewPassword={handleClickSaveNewPassword} />
        </React.Fragment>;
}
export default observer(AssignEmployee);