import React from 'react';
import FocusoneTextLogo from '../FocusoneTextLogo';
import Icons from 'assets/Icons';
function Step3() {
  return <React.Fragment>
            <div className="flex items-baseline space-x-3 text-lg">
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#F7980A] font-bold">
                    3
                </span>
                <span className="flex-1 text-lg font-bold ">
                    กดปุ่ม ใช่ เพื่อยืนยันการเชื่อมต่อ User เข้ากับระบบ Line
                </span>
            </div>

            <div className="mt-6 flex flex-1 flex-col items-center justify-center gap-4 rounded-md p-4 shadow-[0px_0px_8px_0px_rgba(69,69,69,0.2)] lg:mx-10 xl:mx-12 2xl:mx-0">
                <div className="flex flex-col items-center gap-3">
                    <img src="https://www.focusoneerp.com/focusoneerp/Logo2.png" alt="line-profile-pic" className="h-20 w-20 rounded-full border object-contain" />

                    <div className="text-center">
                        <p className="text-sm text-neutral-600">เข้าสู่ระบบด้วยผู้ใช้</p>
                        <p className="font-semibold text-neutral-800">focusone</p>
                    </div>
                </div>

                <p className="text-center text-neutral-800">
                    คุณต้องการรับการแจ้งเตือนของ <span className="font-semibold">focusone</span>{' '}
                    ผ่าน Line Chat <FocusoneTextLogo className="text-sm" />
                </p>

                <div className="relative flex w-full flex-col gap-2">
                    <img src={Icons.ICON_CURSOR_CLICK} className="absolute -left-4 -top-1 h-[55px] w-[55px]" />

                    <button className="bg-line-green-400 hover:bg-line-green-500 flex-1 rounded-md px-4 py-2 text-white shadow-md transition-colors active:brightness-95 disabled:opacity-60">
                        ใช่
                    </button>
                    <button className="flex-1 rounded-md bg-red-500/95 px-4 py-2 text-white shadow-md transition-colors hover:bg-red-600/95 active:brightness-95 disabled:opacity-60">
                        ไม่ใช่
                    </button>
                </div>
            </div>
        </React.Fragment>;
}
export default Step3;