import React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;
function Table(props: Props) {
  const {
    className,
    children,
    ...tableProps
  } = props;
  return <table {...tableProps} className={twMerge('w-full table-auto border border-zinc-300', className)}>
            {children}
        </table>;
}
export default Table;