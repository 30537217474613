import React from 'react';
import { nameof } from 'common/helpers';
import { UserInformationResponse } from 'models/citizen/Citizen';
import ChangeDetectionInput from './ChangeDetectionInput';
type Props = {
  userInformation: UserInformationResponse;
  copyUserInformation: UserInformationResponse;
  handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
function GeneralInfo(props: Props) {
  const {
    userInformation,
    copyUserInformation,
    handleChangeCopyUserInformation
  } = props;
  const {
    first_name,
    last_name,
    email,
    phone
  } = copyUserInformation;
  const comparers = ([userInformation, copyUserInformation] as [UserInformationResponse, UserInformationResponse]);

  // Check from origin object not copy, because copy will re-render every times it change.
  const disabledEditEmail = userInformation.email?.includes('@scg.com') || userInformation.email?.includes('@qmix.com');
  return <React.Fragment>
            <p className="text-lg font-medium">General Personal Digital Information</p>
            <hr />

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput disabled comparers={comparers} compareKey="first_name" label="First Name" name={nameof('first_name', userInformation)} placeholder="First Name" value={first_name ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput disabled comparers={comparers} compareKey="last_name" label="Last Name" name={nameof('last_name', userInformation)} placeholder="Last Name" value={last_name ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
            </div>

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput disabled={disabledEditEmail} comparers={comparers} compareKey="email" label="Email" name={nameof('email', userInformation)} placeholder="Email" value={email ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput comparers={comparers} compareKey="phone" label="Mobile Phone Number" name={nameof('phone', userInformation)} placeholder="Mobile Phone Number" value={phone ?? ''} onChange={handleChangeCopyUserInformation} />
                </div>
            </div>
        </React.Fragment>;
}
export default GeneralInfo;