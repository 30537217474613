import React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
function Thead(props: Props) {
  const {
    className,
    children,
    ...theadProps
  } = props;
  return <thead {...theadProps} className={twMerge('bg-gray-200', className)}>
            {children}
        </thead>;
}
export default Thead;