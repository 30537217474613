import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/group/Platform';
import * as Models from '../../models/group/Platform';
import ModalRegisterPlatformComponent from './ModalRegisterPlatformComponent';
import ModalConfirmDeletePlatformComponent from './ModalConfirmDeletePlatformComponent';
type Store = Models.PlatformState & typeof Stores.actionCreators;
class Platform extends React.Component<any, {
  pageLoad: boolean;
  isOpenModalDelete: boolean;
  platformSelected: Models.Platform;
}, Store> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageLoad: true,
      isOpenModalDelete: false,
      platformSelected: {
        platform_id: '',
        platform_name: '',
        created_by: '',
        created_on: ''
      }
    };
    this.BindData = this.BindData.bind(this);
  }
  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {
    this.setState({
      pageLoad: true
    });
    this.props.requestFunction(this.state.pageLoad);
    this.setState({
      pageLoad: false
    });
  }
  public BindData() {
    this.ensureDataFetched();
  }
  afterOpenModalDelete = () => {
    this.setState({
      isOpenModalDelete: false
    });
  };
  render() {
    var {
      isOpenModalDelete,
      platformSelected
    } = this.state;
    return <div style={{
      paddingLeft: 'unset'
    }}>
                <ModalRegisterPlatformComponent BindData={this.BindData.bind(this)} />
                <hr />

                <h4 className="mb-3" style={{
        fontWeight: 'bold'
      }}>
                    Platform
                </h4>

                {this.props.isLoading && this.renderPlatform()}

                <ModalConfirmDeletePlatformComponent isOpenModalDelete={isOpenModalDelete} platformSelected={platformSelected} afterOpenModalDelete={this.afterOpenModalDelete.bind(this)} />
            </div>;
  }
  renderPlatform() {
    return <React.Fragment>
                <table className="table-bordered table">
                    <thead>
                        <tr style={{
            background: 'rgba(9, 132, 227, 1)',
            color: '#fff',
            fontSize: '10pt'
          }}>
                            <th>Platform ID</th>
                            <th>Platform Name</th>
                            <th>Created By</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.isLoading && this.props.response.result_list != undefined && this.props.response.result_list.map((item: Models.Platform) => <tr key={item.platform_id} className={'table-tbody table-tr-active'}>
                                    <td>{item.platform_id}</td>
                                    <td>{item.platform_name}</td>
                                    <td>{item.created_by}</td>
                                    <td>{item.created_on}</td>
                                    <td>
                                        <div className="justify-content-center flex">
                                            <button className="btn btn-danger" onClick={() => this.setState({
                  isOpenModalDelete: true,
                  platformSelected: item
                })}>
                                                <i className="fas fa-ban"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>)}
                    </tbody>
                </table>
            </React.Fragment>;
  }
}
export default connect((state: ApplicationState) => state.platform, Stores.actionCreators)(Platform);

//<td>{moment(item.created_on).format('DD/MMM/YYYY, HH:mm')}</td>