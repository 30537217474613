import React from 'react';
import { twMerge } from 'tailwind-merge';
import { badgeTwClasses } from './constant';
type OwnProps = {
  color?: MaterialStatus;
};
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & OwnProps;
function Badge(props: Props) {
  const {
    color = 'neutral',
    className,
    children
  } = props;
  return <span className={twMerge('inline-block rounded-full py-0.5 px-2 text-sm', badgeTwClasses.get(color) ?? '', className)}>
            {children}
        </span>;
}
export default Badge;