import { useEffect, useState } from 'react';

import { useStore } from '../../../common/hooks';

function ClientSystemDownloadViewModel() {
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const { userCompanyStore, clientSystemStore } = useStore();

    const userCompanyOption = userCompanyStore.userCompanyMappingOptions;
    const citizenId = userCompanyStore.citizenId;
    const userClientSystem = clientSystemStore.clientSystemProgram;

    useEffect(() => {
        fetchUserCompanyOption();
    }, []);

    useEffect(() => {
        fetchDataUserClientSystem();
    }, [selectedCompany]);

    const fetchUserCompanyOption = async () => {
        await userCompanyStore.fetchUserCompany('', citizenId);
    };

    const fetchDataUserClientSystem = async () => {
        if (selectedCompany === '') {
            return;
        }
        await clientSystemStore.fetchClientSystemProgram(selectedCompany);
    };

    return {
        selectedCompany,
        setSelectedCompany,
        userCompanyOption,
        userClientSystem
    };
}

export default ClientSystemDownloadViewModel;
