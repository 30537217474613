import * as React from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
class PlatformComponent extends React.Component<any, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {}
  render() {
    return <div>
                <div>
                    <Row>
                        <Col md="3">
                            ระบุ Platform ID
                            </Col>
                        <Col md="6">
                            <Input type="text" />
                        </Col>
                        <Col md="3">
                            <Button color="primary">ขอเข้า Platform</Button>
                        </Col>
                    </Row>
                </div>
                <br />
                <br />

                <div>
                    <h6>Platform List</h6>
                    <table className='table table-bordered'>
                        <thead>
                            <tr style={{
              background: "rgba(9, 132, 227, 1)",
              color: "#fff",
              fontSize: '10pt'
            }}>
                                <th>Platform ID</th>
                                <th>Platform Name</th>
                                <th>status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>001</td>
                                <td>SCG</td>
                                <td>Active</td>
                            </tr>
                            <tr>
                                <td>002</td>
                                <td>In House</td>
                                <td>Active</td>
                            </tr>
                            <tr>
                                <td>003</td>
                                <td>Heavy</td>
                                <td>In Active</td>
                            </tr>
                            <tr>
                                <td>004</td>
                                <td>Dutchhcun</td>
                                <td>Pending</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>;
  }
}
export default PlatformComponent;