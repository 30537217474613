import React, { useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from '../../../../common/materials';
import UserVerificationViewModel from '../UserVerification.viewmodel';
import { IoIosClose } from 'react-icons/io';
import ToggleSwitches from './ToggleSwitches';
interface Props {
  showModal: boolean;
  setShowModal: (isShow: boolean) => void;
}
function UserVerificationAddUsersModal(props: Props) {
  const {
    showModal,
    setShowModal
  } = props;
  const {
    displayRoles,
    onSelectCompany,
    companyOptions,
    selectedCompany,
    userRole,
    onSelectUser,
    selectedUsers,
    searchPendingUser,
    handleClickAddUser
  } = UserVerificationViewModel();
  const [selectedRole, setSelectedRole] = React.useState<(typeof displayRoles)[number]>('User');
  return <React.Fragment>
            {showModal ? <>
                    <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex justify-end text-[40px]">
                                    <IoIosClose onClick={() => setShowModal(false)} />
                                </div>
                                <div className="flex items-start justify-between p-1 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-2xl ml-2 font-meduim">
                                        Add User (เพิ่มบัญชีผู้ใช้)
                                    </h3>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => setShowModal(false)}></button>
                                </div>

                                <div className="p-6">
                                    <div className="flex">
                                        <span className="mt-1">Company</span>
                                        <CreatableSelect className="w-full ml-3 z-40" onChange={onSelectCompany} options={companyOptions} value={selectedCompany} isDisabled={userRole !== 'Super Admin'} />
                                    </div>
                                    <div className="flex items-center w-full mt-3">
                                        <span>Users</span>
                                        <CreatableSelect isMulti isClearable
                //isValidNewOption={() => false}
                className="ml-3 w-full z-30" onChange={user => onSelectUser(user)} options={[]} value={selectedUsers} filterOption={(userOptions, inputUser) => searchPendingUser(userOptions, inputUser)} />
                                    </div>
                                    <div className="flex w-full mt-3">
                                        <span className="mt-2">Roles</span>
                                        <div className="flex justify-between px-3">
                                            <ToggleSwitches names={displayRoles} selectedName={selectedRole} onSelect={setSelectedRole} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid  border-slate-200 rounded-b">
                                    <Button className="bg-[#1ab86c]" onClick={() => handleClickAddUser(selectedRole)}>
                                        Add User
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </> : null}
        </React.Fragment>;
}
export default UserVerificationAddUsersModal;