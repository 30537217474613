import { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { useLocalStorage } from 'usehooks-ts';
import Swal from 'sweetalert2';

import { useLoader, useStore } from 'common/hooks';
import { Task } from 'models/link/Link';
import { ResponseError } from 'models';
import { TaskActivity } from 'models/link/Task';
import { getTaskActivity, getTaskLink } from 'data/link/respositories/taskRepository';

import { TABS } from './Ticket.view';

function TicketViewModel() {
    const loader = useLoader();
    const { userCompanyStore } = useStore();
    const { email } = userCompanyStore;

    const [tasks, setTasks] = useState<Task[]>();
    const [filter, setFilter] = useState<(typeof TABS)[number]['value']>('all');
    const [search, setSearch] = useState('');
    const [displayStyle, setDisplayStyle] = useLocalStorage<'grid' | 'list'>(
        'SUPPORT-DISPLAY_TICKET_STYLE',
        'grid'
    );
    const [showSupportModal, setShowSupportModal] = useBoolean();
    const [selectedTask, setSelectedTask] = useState<Task>();
    const [selectedTaskDetail, setSelectedTaskDetail] = useState<TaskActivity>();
    const showTaskDetailModal = !!selectedTaskDetail;

    const deferredSearch = useDeferredValue(search);
    const deferredFilter = useDeferredValue(filter);
    const filteredTasks = useMemo(() => {
        if (!tasks) {
            return null;
        }

        const searchTerm = deferredSearch.toLowerCase();
        return tasks.filter(
            task =>
                task.activityOverviewStatus.includes(
                    deferredFilter === 'all' ? '' : deferredFilter
                ) &&
                (task.jobdescription.toLowerCase().includes(searchTerm) ||
                    task.ticketNO.toLowerCase().includes(searchTerm))
        );
    }, [tasks, deferredFilter, deferredSearch]);

    useEffect(() => {
        fetchTaskLink(userCompanyStore.email);
    }, []);

    const fetchTaskLink = useCallback(async (email: string) => {
        try {
            loader.show();
            const [, data] = await getTaskLink(email);

            if (data === null) {
                return;
            }

            setTasks(data);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    }, []);

    const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, []);

    const handleClickTicketDetail = useCallback(async (task: Task) => {
        try {
            loader.show();

            const [error, data] = await getTaskActivity({
                key: task.aobjectlink,
                link_id: task.empcode
            });
            if (error) {
                throw error;
            }

            setSelectedTask(task);
            setSelectedTaskDetail(data);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    }, []);

    const handleCloseTicketDetailModal = useCallback(() => {
        setSelectedTask(undefined);
        setSelectedTaskDetail(undefined);
    }, []);

    return {
        email,
        filter,
        displayStyle,
        filteredTasks,
        search,
        showSupportModal,
        selectedTask,
        selectedTaskDetail,
        showTaskDetailModal,
        setDisplayStyle,
        setFilter,
        handleChangeSearch,
        handleClickTicketDetail,
        handleCloseTicketDetailModal,
        setShowSupportModal
    };
}

export default TicketViewModel;
