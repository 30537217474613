import { extendTheme } from '@chakra-ui/react';

import colorPalettes from './color-palettes';

const theme = extendTheme({
    components: {
        Tabs: {
            baseStyle: {
                tab: {
                    _selected: {
                        color: 'primary.900'
                    }
                }
            }
        }
    },
    colors: {
        ...colorPalettes
    }
});

export default theme;
