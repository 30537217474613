import { Action, combineReducers, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/user/UserApplicationManagement';

// Load UserAppManage
interface LoadUserAppManage {
    type: 'LOAD_USERAPPMANAGE'
}
interface LoadedUserAppManage {
    type: 'LOADEDUSERAPPMANAGE'
    response: Models.resGetUserAppManage
    status: number,
    statusMessage: string
}
interface FailLoadedUserAppManage {
    type: 'LOADUSERAPPMANAGEFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedUserAppManage {
    type: 'CLEARLOADEDUSERAPPMANAGE'
}

// Add UserAppManage
interface AddUserAppManage {
    type: 'ADD_USERAPPMANAGE'
}
interface AddedUserAppManage {
    type: 'ADDEDUSERAPPMANAGE'
    status: number,
    statusMessage: string
}
interface FailAddedUserAppManage {
    type: 'ADDUSERAPPMANAGEFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedUserAppManage {
    type: 'CLEARADDUSERAPPMANAGE'
}

// Delete UserAppManage
interface DeleteUserAppManage {
    type: 'DELETE_USERAPPMANAGE'
}
interface DeletedUserAppManage {
    type: 'DELETEDUSERAPPMANAGE'
    status: number,
    statusMessage: string
}
interface FailDeletedUserAppManage {
    type: 'DELETEUSERAPPMANAGEFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeletedUserAppManage {
    type: 'CLEARDELETEUSERAPPMANAGE'
}

//Search UserAppManage
interface SearchUserAppManage {
    type: 'SEARCH_USERAPPMANAGE'
}
interface SearchedUserAppManage {
    type: 'SEARCHEDUSERAPPMANAGE'
    response: Models.resGetUserAppManage
    status: number,
    statusMessage: string
}
interface FailSearchedUserAppManage {
    type: 'SEARCHUSERAPPMANAGEFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchedUserAppManage {
    type: 'CLEARSEARCHUSERAPPMANAGE'
}

// Edit UserAppManage
interface EditUserAppManage {
    type: 'EDIT_USERAPPMANAGE'
}
interface EditedUserAppManage {
    type: 'EDITEDUSERAPPMANAGE'
    response: Models.resGetUserAppManage
    status: number,
    statusMessage: string
}
interface FailEditedUserAppManage {
    type: 'EDITUSERAPPMANAGEFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditedUserAppManage {
    type: 'CLEAREDITUSERAPPMANAGE'
}

type KnownAction = LoadedUserAppManage | FailLoadedUserAppManage | LoadUserAppManage | AddUserAppManage | AddedUserAppManage | FailAddedUserAppManage | ClearAddedUserAppManage | ClearLoadedUserAppManage | DeleteUserAppManage | DeletedUserAppManage | FailDeletedUserAppManage | ClearDeletedUserAppManage | SearchUserAppManage | SearchedUserAppManage | FailSearchedUserAppManage | ClearSearchedUserAppManage | EditUserAppManage | EditedUserAppManage | FailEditedUserAppManage | ClearEditedUserAppManage

export const actionCreators = {
    // GET
    requestGetUserAppManage: (check: boolean, method: string, company_id: string, citizen_id: string, application_id: string, platform_id: string, status: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserAppManage && check && method == 'GET') {
            console.log('call actionCreators : requestGetUserAppManage')
            fetch(`/v1/user/application?company_id=` + company_id + '&citizen_id=' + citizen_id + '&application_id=' + application_id + '&platform_id=' + platform_id + '&status=' + status, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADUSERAPPMANAGEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDUSERAPPMANAGE', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_USERAPPMANAGE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDUSERAPPMANAGE' });
        }
    },

    // ADD
    requestAddUserAppManage: (check: boolean, method: string, Formbody: Models.reqAddUserAppManage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        const appState = getState();
        if (appState && appState.UserAppManage && check && method == 'POST') {
            console.log('call actionCreators : requestAddUserAppManage')
            fetch(`/v1/user/application`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDUSERAPPMANAGEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        dispatch({ type: 'ADDEDUSERAPPMANAGE', status: 200, statusMessage: '' });
                    }
                })
          
            dispatch({ type: 'ADD_USERAPPMANAGE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDUSERAPPMANAGE' });
        }
    },

    // Delete
    requestDeleteUserAppManage: (check: boolean, method: string, sid: string, citizen_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserAppManage && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteUserAppManage')
            fetch(`/v1/user/application?sid=` + sid + '&citizen_id=' + citizen_id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETEUSERAPPMANAGEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'DELETEDUSERAPPMANAGE', status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'DELETE_USERAPPMANAGE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEUSERAPPMANAGE' });
        }
    },

    // EDIT
    requestEditUserAppManage: (check: boolean, method: string, status: string, company_id: string, citizen_id: string, application_id: string, platform_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserAppManage && check && method == 'PUT') {
            console.log('call actionCreators : requestEditUserAppManage')
            fetch(`/v1/user/application?company_id=` + company_id + '&citizen_id=' + citizen_id + '&application_id=' + application_id + '&platform_id=' + platform_id + '&btn_status=' + status, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'EDITUSERAPPMANAGEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'EDITEDUSERAPPMANAGE', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'EDIT_USERAPPMANAGE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITUSERAPPMANAGE' });
        }
    },
}

const unloadedStateGet: Models.GetUserAppManageState = { isLoadGetUserAppManage: false, statusGetUserAppManage: 0 };
const unloadedStateAdd: Models.AddUserAppManageState = { isLoadAddUserAppManage: false, statusAddUserAppManage: 0 };
const unloadedStateDelete: Models.DeleteUserAppManageState = { isLoadDeleteUserAppManage: false, statusDeleteUserAppManage: 0 };
const unloadedStateEdit: Models.EditUserAppManageState = { isLoadEditUserAppManage: false, statusEditUserAppManage: 0 };

const reducerGet: Reducer<Models.GetUserAppManageState> = (state: Models.GetUserAppManageState | undefined, incomingAction: Action): Models.GetUserAppManageState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_USERAPPMANAGE':
            return {
                isLoadGetUserAppManage: false,
                statusGetUserAppManage: 0
            }

        case 'LOADEDUSERAPPMANAGE':
            return {
                isLoadGetUserAppManage: true,
                responseGetUserAppManage: action.response as any,
                statusGetUserAppManage: action.status
            }

        case 'LOADUSERAPPMANAGEFAIL':
            return {
                isLoadGetUserAppManage: true,
                messageGetUserAppManage: action.statusMessage,
                statusGetUserAppManage: action.status
            }

        case 'CLEARLOADEDUSERAPPMANAGE':
            return {
                isLoadGetUserAppManage: false,
                messageGetUserAppManage: "",
                statusGetUserAppManage: 0
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddUserAppManageState> = (state: Models.AddUserAppManageState | undefined, incomingAction: Action): Models.AddUserAppManageState => {
    if (state == undefined) {
        return unloadedStateAdd;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'ADD_USERAPPMANAGE':
            return {
                isLoadAddUserAppManage: false,
                statusAddUserAppManage: 0
            }

        case 'ADDEDUSERAPPMANAGE':
            return {
                isLoadAddUserAppManage: true,
                statusAddUserAppManage: action.status
            }

        case 'ADDUSERAPPMANAGEFAIL':
            return {
                isLoadAddUserAppManage: true,
                messageAddUserAppManage: action.statusMessage,
                statusAddUserAppManage: action.status
            }

        case 'CLEARADDUSERAPPMANAGE':
            return {
                isLoadAddUserAppManage: false,
                messageAddUserAppManage: '',
                statusAddUserAppManage: 0
            }
        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteUserAppManageState> = (state: Models.DeleteUserAppManageState | undefined, incomingAction: Action): Models.DeleteUserAppManageState => {
    if (state == undefined) {
        return unloadedStateDelete;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'DELETE_USERAPPMANAGE':
            return {
                isLoadDeleteUserAppManage: false,
                statusDeleteUserAppManage: 0
            }

        case 'DELETEDUSERAPPMANAGE':
            return {
                isLoadDeleteUserAppManage: true,
                statusDeleteUserAppManage: action.status
            }

        case 'DELETEUSERAPPMANAGEFAIL':
            return {
                isLoadDeleteUserAppManage: true,
                messageDeleteUserAppManage: action.statusMessage,
                statusDeleteUserAppManage: action.status
            }

        case 'CLEARDELETEUSERAPPMANAGE':
            return {
                isLoadDeleteUserAppManage: false,
                messageDeleteUserAppManage: '',
                statusDeleteUserAppManage: 0
            }
        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditUserAppManageState> = (state: Models.EditUserAppManageState | undefined, incomingAction: Action): Models.EditUserAppManageState => {
    if (state == undefined) {
        return unloadedStateEdit;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'EDIT_USERAPPMANAGE':
            return {
                isLoadEditUserAppManage: false,
                statusEditUserAppManage: 0
            }

        case 'EDITEDUSERAPPMANAGE':
            return {
                isLoadEditUserAppManage: true,
                responseEditUserAppManage: action.response as any,
                statusEditUserAppManage: action.status
            }

        case 'EDITUSERAPPMANAGEFAIL':
            return {
                isLoadEditUserAppManage: true,
                messageEditUserAppManage: action.statusMessage,
                statusEditUserAppManage: action.status
            }

        case 'CLEAREDITUSERAPPMANAGE':
            return {
                isLoadEditUserAppManage: false,
                messageEditUserAppManage: '',
                statusEditUserAppManage: 0
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetUserAppManage: reducerGet, AddUserAppManage: reducerAdd, DeleteUserAppManage: reducerDelete, EditUserAppManage: reducerEdit });