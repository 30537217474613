import axios from 'axios';

import {
    CompanyPlatformMappingRequest,
    CompanyPlatformMappingResponse
} from '../schemas';

export async function getCompanyPlatformMappingFromRemote(params: CompanyPlatformMappingRequest) {
    return axios.get<CompanyPlatformMappingResponse>('/v1/company/platform_mapping', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}