import React, { useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';
import NewTaskView, { NewTaskHandle } from '../../../presentations/view/NewTask/NewTask.view';
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};
function NewTaskModal(props: Props) {
  const {
    isOpen,
    onClose
  } = props;
  const ref = useRef<NewTaskHandle>(null);
  return <Modal fullscreen closeOnClickOverlay isOpen={isOpen} onClose={onClose}>
            <ModalHeader>New Task</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <NewTaskView onClose={onClose} ref={ref} />
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button outline onClick={onClose} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={() => {
        ref.current?.handleClickAddTask();
      }} className="bg-primary-900 text-white">
                    Create
                </Button>
            </ModalFooter>
        </Modal>;
}
export default NewTaskModal;