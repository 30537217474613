import React from 'react';
import { Button } from '@chakra-ui/react';
import FocusoneTextLogo from '../FocusoneTextLogo';
import Icons from '../../../assets/Icons';
function Step1() {
  return <React.Fragment>
            <div className="flex items-baseline space-x-3 text-lg">
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#F7980A] font-bold">
                    1
                </span>
                <span className="flex-1 text-lg font-bold ">
                    เพิ่มเพื่อน @
                    <FocusoneTextLogo className="text-lg" />
                </span>
            </div>

            <div className="mt-6 flex flex-1 flex-col items-center gap-4">
                <Button as="a" href="https://page.line.me/605cpksi?oat_content=url&openQrModal=false" target="_blank" size="lg" className="bg-line-green-400 hover:bg-line-green-500 text-white">
                    <img src={Icons.ICON_CURSOR_CLICK} className="absolute -left-8 top-4 h-[55px] w-[55px]" />
                    กดปุ่นี้เพื่อ Add Friend
                </Button>

                <p className="text-lg text-neutral-700">หรือแสกน QR Code</p>

                <img className="h-[240px] w-[240px]" src="https://qr-official.line.me/gs/M_605cpksi_GW.png?oat_content=qr" />
            </div>
        </React.Fragment>;
}
export default Step1;