import React from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';
import { TaskGroup } from '../../../models/taskGroup/TaskGroup';
type EditTaskModalProps = {
  isOpen: boolean;
  editTaskGroup: TaskGroup;
  handleEditTaskGroup: (taskGroup: TaskGroup) => void;
  handleChangeEditTaskGroup: <K extends 'group_id' | 'group_description'>(key: K, value: {
    group_id: string;
    group_description: string;
  }[K]) => void;
  confirmEditTaskGroup: () => Promise<void>;
};
function EditTaskGroupModal(props: EditTaskModalProps) {
  const {
    editTaskGroup,
    isOpen: showEditTaskGroupModal,
    handleEditTaskGroup,
    handleChangeEditTaskGroup,
    confirmEditTaskGroup
  } = props;
  return <Modal isOpen={showEditTaskGroupModal}>
            <ModalHeader>Edit Task Group</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <div className="flex flex-row gap-4">
                    <div className="flex w-1/5 flex-col">
                        <Label>Group Id</Label>
                        <Input disabled value={editTaskGroup.group_id} onChange={event => handleChangeEditTaskGroup('group_id', event.target.value)} />
                    </div>

                    <div className="flex flex-1 flex-col">
                        <Label>Group Description</Label>
                        <Input value={editTaskGroup.group_description} onChange={event => handleChangeEditTaskGroup('group_description', event.target.value)} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="mt-4">
                <Button outline onClick={() => {
        handleEditTaskGroup({
          group_index: '',
          group_id: '',
          group_description: ''
        });
      }} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={confirmEditTaskGroup} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default EditTaskGroupModal;