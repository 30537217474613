import { useCallback, useMemo, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';

import { useLoader, useStore } from 'common/hooks';
import { getCompanyPlatformMapping } from 'data/company/repositories/companyPlatformMapping.repository';
import { CompanyPlatformMapping } from 'data/company/schemas';
import { ResponseError } from 'models';
import { CompanyMaster } from 'models/company/Company';
import { UserCompany } from 'models/user/UserCompany';
import {
    changePassword,
    getEmployee,
    saveEmployee
} from 'data/user/repositories/employee.repository';
import { Employee, GetEmployeeRequestParams } from 'models/user/Employee';

function AssignEmployeeViewModel() {
    const loader = useLoader();

    const { userCompanyStore, companyMasterStore } = useStore();
    const { companyMaster } = companyMasterStore;
    const { userRole, userCompanyMapping, currentAdminCompany } = userCompanyStore;

    // Company
    const companyOptions = useMemo(() => {
        if (userRole === 'Super Admin') {
            return companyMaster;
        }

        // If doesn't have admin role
        if (!currentAdminCompany) {
            return [];
        }

        // This case might not be happen but it's safe to do this.
        const foundCompanyMaster = companyMaster.find(
            company => company.sid === currentAdminCompany.sid
        );
        if (!foundCompanyMaster) {
            return [];
        }

        return [foundCompanyMaster];
    }, [userRole, companyMaster, currentAdminCompany]);
    const [selectedCompany, setSelectedCompany] = useState<CompanyMaster>();

    // Platform
    const [companyPlatformMapping, setCompanyPlatformMapping] = useState<CompanyPlatformMapping[]>(
        []
    );
    const platformOptions = useMemo(() => {
        if (!selectedCompany) {
            return [];
        }

        return companyPlatformMapping;
    }, [companyPlatformMapping, selectedCompany]);
    const [selectedPlatform, setSelectedPlatform] = useState<CompanyPlatformMapping>();

    // Users
    const userOptions = useMemo(() => {
        if (!selectedCompany) {
            return [];
        }

        return userCompanyMapping.filter(user => user.sid === selectedCompany.sid);
    }, [userCompanyMapping, selectedCompany]);
    const [selectedUsers, setSelectedUsers] = useState<UserCompany>();

    const [employee, setEmployee] = useState<Employee>();

    const [showManageEmployeeModal, setShowManageEmployeeModal] = useBoolean();

    const fetchCompanyPlatformMapping = async (sid: string) => {
        try {
            loader.show();

            const [error, data] = await getCompanyPlatformMapping({ sid });
            if (error) {
                throw error;
            }

            if (data.total_count === 0) {
                setCompanyPlatformMapping([]);

                throw Error('Roles not found from this company.');
            }

            setCompanyPlatformMapping(data.result_list);
        } catch (error) {
            Swal.fire('Error!', (error as Error).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const fetchEmployee = async (params: GetEmployeeRequestParams) => {
        try {
            loader.show();

            if (params.user_id.includes('@')) {
                params.user_id = params.user_id.split('@')[0];
            }

            const [error, data] = await getEmployee(params);
            if (error) {
                throw error;
            }
            setEmployee(
                data.total_count > 0
                    ? data.result_list[0]
                    : {
                          EmployeeCode: '',
                          Username: params.user_id,
                          FirstName: '',
                          LastName: '',
                          NamePrefix: '',
                          FirstName_TH: '',
                          LastName_TH: '',
                          Email: '',
                          BranchCode: '',
                          Department: '',
                          PositionCode: '',
                          IsSaleRepresent: 'False'
                      }
            );
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const handleSelectCompany = useCallback((company: SingleValue<CompanyMaster>) => {
        if (!company || !company.sid) {
            return;
        }

        setSelectedCompany(company);
        fetchCompanyPlatformMapping(company.sid);
    }, []);

    const handleSelectPlatform = useCallback(
        (platform: SingleValue<CompanyPlatformMapping>) => {
            if (!selectedCompany || !platform || !platform.platform_id) {
                return;
            }

            setSelectedPlatform(platform);
        },
        [selectedCompany]
    );

    const handleSelectUser = useCallback(
        (user: SingleValue<UserCompany>) => {
            if (
                !selectedCompany ||
                !selectedPlatform ||
                !selectedPlatform.platform_id ||
                !user ||
                !user.citizen_id
            ) {
                return;
            }

            setSelectedUsers(user);
            fetchEmployee({
                sid: selectedCompany.sid,
                platform_id: selectedPlatform.platform_id,
                user_id: user.citizen_id
            });
        },
        [selectedCompany, selectedPlatform]
    );

    const handleClickSaveChangedProfile = useCallback(
        async (changedEmployee: Employee) => {
            try {
                loader.show();

                if (!selectedCompany || !selectedPlatform) {
                    return;
                }

                const {
                    EmployeeCode,
                    Username,
                    NamePrefix,
                    FirstName,
                    LastName,
                    FirstName_TH,
                    LastName_TH,
                    Email,
                    BranchCode,
                    Department,
                    PositionCode,
                    IsSaleRepresent
                } = changedEmployee;
                const [error, data] = await saveEmployee({
                    sid: selectedCompany.sid,
                    platform_id: selectedPlatform.platform_id,
                    employee_code: EmployeeCode,
                    username: Username,
                    name_prefix: NamePrefix,
                    first_name: FirstName,
                    last_name: LastName,
                    first_name_th: FirstName_TH,
                    last_name_th: LastName_TH,
                    email: Email,
                    branch_code: BranchCode,
                    department: Department,
                    position_code: PositionCode,
                    is_sale_represent: IsSaleRepresent
                });
                if (error) {
                    throw error;
                }

                await Swal.fire('Success!', 'บันทึกข้อมูลสำเร็จ', 'success');
                setEmployee(changedEmployee);
                setShowManageEmployeeModal.off();
            } catch (error) {
                Swal.fire('Error!', 'เกิดข้อผิดพลาด ระหว่างการบันทึก', 'error');
            } finally {
                loader.hide();
            }
        },
        [selectedCompany, selectedPlatform]
    );

    const handleClickSaveNewPassword = useCallback(
        async (newPassword: string) => {
            try {
                loader.show();

                if (!selectedCompany || !selectedPlatform || !employee) {
                    return;
                }

                const [error] = await changePassword({
                    sid: selectedCompany.sid,
                    platform_id: selectedPlatform.platform_id,
                    username: employee.Username,
                    password: newPassword
                });
                if (error) {
                    throw error;
                }

                Swal.fire('Success!', 'เปลี่ยนรหัสผ่านสำเร็จ', 'success');
            } catch (error) {
                Swal.fire('Error!', (error as ResponseError).message, 'error');
            } finally {
                loader.hide();
            }
        },
        [selectedCompany, selectedPlatform, employee]
    );

    return {
        userRole,
        companyOptions,
        selectedCompany,
        platformOptions,
        selectedPlatform,
        userOptions,
        selectedUsers,
        showManageEmployeeModal,
        employee,
        handleSelectCompany,
        handleSelectPlatform,
        handleSelectUser,
        setShowManageEmployeeModal,
        handleClickSaveChangedProfile,
        handleClickSaveNewPassword
    };
}

export default AssignEmployeeViewModel;
