import React from 'react';
import { twMerge } from 'tailwind-merge';
type OwnProps<T extends string> = {
  values: readonly T[];
  defaultSelectedValue?: T;
  onChangeTab?: (value: T) => void;
};
type Props<T extends string> = React.HTMLProps<HTMLDivElement> & OwnProps<T>;
function Tabs<T extends string>(props: Props<T>) {
  const {
    values,
    defaultSelectedValue,
    className,
    onChangeTab,
    ...divProps
  } = props;
  if (values.length === 0) {
    return null;
  }
  return <div {...divProps} className={twMerge('flex border-b border-b-zinc-300', className)}>
            {values.map(value => <button key={value} className={twMerge('rounded-t-md border-x border-x-white px-4 py-2 text-neutral-800 transition-colors', defaultSelectedValue === value && '-mb-[1px] border border-x-zinc-300 border-b-white border-t-zinc-300 text-primary-900')} onClick={() => onChangeTab && onChangeTab(value)}>
                    {value}
                </button>)}
        </div>;
}
export default Tabs;