// This component has been used in `ChangedPasswordModal`

import React, { useCallback, useState } from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import { Button, Input, Label } from 'common/materials';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClickSaveNewPassword: (newPassword: string) => void;
};
function ChangePasswordModal(props: Props) {
  const {
    isOpen,
    onClose,
    onClickSaveNewPassword
  } = props;
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const isPasswordEqual = newPassword === confirmNewPassword;
  const handleChangeNewPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }, []);
  const handleChangeConfirmNewPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
  }, []);
  const handleClickSave = useCallback(async () => {
    await onClickSaveNewPassword(newPassword);
    onClose();
  }, [newPassword, onClickSaveNewPassword]);
  return <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody className="space-y-4">
                    <div className="border-b border-zinc-300 py-2">
                        <h2 className="text-lg font-semibold text-black">Change Password</h2>
                    </div>

                    <div className="space-y-2">
                        <div>
                            <Label>รหัสผ่านใหม่</Label>
                            <Input value={newPassword} onChange={handleChangeNewPassword} />
                        </div>
                        <div>
                            <Label>ยืนยันรหัสผ่านใหม่</Label>
                            <Input value={confirmNewPassword} onChange={handleChangeConfirmNewPassword} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="gap-2">
                    <Button outline onClick={onClose} className="border-primary-900 text-primary-900">
                        Cancel
                    </Button>
                    <Button disabled={!isPasswordEqual} className="bg-primary-900 text-white" onClick={handleClickSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>;
}
export default ChangePasswordModal;