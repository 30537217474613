import { block as _block$ } from "million/react";
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
type Props = {
  outline?: boolean;
  values?: string[];
  defaultSelectedValue?: string;
  onChange?: (values: string) => void;
};
const M$RadioButtonGroup = function RadioButtonGroup(props: Props) {
  const {
    outline,
    values = [],
    defaultSelectedValue,
    onChange
  } = props;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  const handleClick = (value: string) => {
    setSelectedValue(value);
    onChange && onChange(value);
  };
  useEffect(() => {
    setSelectedValue(defaultSelectedValue);
  }, [defaultSelectedValue]);
  return <div className="inline-flex items-center [&>button:first-child]:rounded-l-md [&>button:last-child]:rounded-r-md">
            {values.map((value, index) => <button key={`btn-${value}-${index}`} className={twMerge('border bg-neutral-50 px-3 py-1 text-xs text-neutral-600 transition-colors duration-100', value.toLowerCase() === selectedValue?.toLowerCase() ? outline ? 'border-primary-900 bg-white text-primary-900' : 'border-primary-900 bg-primary-900 text-white' : 'border-neutral-400 text-neutral-600')} onClick={() => handleClick(value)}>
                    <span className="relative top-[1px]">{value}</span>
                </button>)}
        </div>;
};
const RadioButtonGroup = M$RadioButtonGroup;
export default RadioButtonGroup;