import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { SingleValue } from 'react-select';
import { FaMinusCircle } from 'react-icons/fa';
import { useLoader } from 'common/hooks';
import { Input, Label, Select, Button } from 'common/materials';
import { QuillEditor } from 'common/components';
import { ResponseList } from 'models';
import { UserSupportEmail } from 'models/user/UserSupport';
export type UserSupportComponentRefHandle = {
  handleClickSendEmail: () => Promise<void>;
};
type Props = {
  from?: 'login' | undefined;
  username: string;
  email: string;
  onClose: () => void;
};
const UserSupportComponent = forwardRef<UserSupportComponentRefHandle, Props>((props, ref) => {
  const {
    from,
    email,
    username,
    onClose
  } = props;
  const loader = useLoader();
  const [requestForm, setRequestForm] = useState({
    Subject: '',
    FromName: username,
    CcEmails: '',
    BccEmails: '',
    FromEmail: email,
    ToEmails: ''
  });
  const [supportEmails, setSupportEmails] = useState<UserSupportEmail[]>([]);
  const [selectedSupportEmail, setSelectedSupportEmail] = useState<UserSupportEmail>();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [model, setModel] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (from === 'login') {
      setSelectedSupportEmail(({
        email_to: 'f1std5-support@a-gape.com',
        id: 'f1std5-support@a-gape.com'
      } as UserSupportEmail));
      return;
    }
    fetchSupportEmailOptions();
  }, []);
  const fetchSupportEmailOptions = useCallback(async () => {
    try {
      const data = (await axios.get<ResponseList<UserSupportEmail>>('/v2/user/support/email', {
        params: {
          citizen_id: username
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
      })).data;
      setSupportEmails(data.result_list);
    } catch {} finally {
      loader.hide();
    }
  }, [username]);
  const handleChangeRequestForm = useCallback(<K extends keyof typeof requestForm,>(key: K) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestForm(prevState => ({
      ...prevState,
      [key]: event.target.value
    }));
  }, [setRequestForm]);
  const handleChangeSupportEmail = useCallback((email: SingleValue<UserSupportEmail>) => {
    email && setSelectedSupportEmail(email);
  }, []);
  const handleChangeFileInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    setAttachments(prevState => [...prevState, ...Array.from(files)]);
  }, [setAttachments]);
  const handleRemoveAttachment = useCallback((index: number) => {
    setAttachments(prevState => {
      const nextState = [...prevState];
      nextState.splice(index, 1);
      return nextState;
    });
  }, []);
  const handleClickSendEmail = useCallback(async () => {
    if (!selectedSupportEmail?.email_to) {
      Swal.fire('Error', `กรุณาเลือก Email`, 'error');
      return;
    }
    if (!requestForm.Subject) {
      Swal.fire('Error', `กรุณาระบุ Subject`, 'error');
      return;
    }
    if (!model) {
      Swal.fire('Error', `กรุณาระบุ Content`, 'error');
      return;
    }
    try {
      loader.show();
      const {
        Subject,
        FromName,
        CcEmails,
        BccEmails,
        FromEmail
      } = requestForm;
      const formData = new FormData();
      formData.append('Body', model);
      formData.append('Subject', Subject);
      formData.append('FromName', FromName);
      formData.append('CcEmails', CcEmails);
      formData.append('BccEmails', BccEmails);
      formData.append('FromEmail', FromEmail);
      formData.append('ToEmails', selectedSupportEmail.email_to);
      attachments.forEach(attachment => formData.append('Attachments', attachment));
      await axios.post('/v1/user/support', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
      });
      Swal.fire({
        title: 'Success!',
        icon: 'success',
        text: 'Send Email Success.',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      }).then(() => onClose());
    } catch (error) {
      Swal.fire('Error', ((error as AxiosError).response?.data as any).message ?? '', 'error');
    } finally {
      loader.hide();
    }
  }, [selectedSupportEmail, requestForm, model, loader]);
  useImperativeHandle(ref, () => ({
    handleClickSendEmail
  }), [handleClickSendEmail]);
  return <div className="space-y-4">
            <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                    <div className="flex-1">
                        <Label>From</Label>
                        <Input disabled={from !== 'login'} value={requestForm.FromEmail} onChange={from === 'login' ? handleChangeRequestForm('FromEmail') : undefined} />
                    </div>
                    <div className="flex-1">
                        <Label>Subject</Label>
                        <Input type="text" value={requestForm.Subject} onChange={handleChangeRequestForm('Subject')} />
                    </div>
                </div>
                <div className="flex gap-2">
                    <div className="flex-1">
                        <Label>User</Label>
                        <Input disabled type="text" value={requestForm.FromName} onChange={handleChangeRequestForm('FromName')} />
                    </div>
                    <div className="flex-1">
                        <Label>To</Label>
                        <Select isDisabled={supportEmails.length === 1 ?? from === 'login'} placeholder="โปรดเลือก Email" options={supportEmails} getOptionLabel={email => email.email_to} getOptionValue={email => email.id} value={selectedSupportEmail} onChange={handleChangeSupportEmail} />
                    </div>
                </div>
                <div className="flex gap-2">
                    <div className="flex-1">
                        <Label>CC</Label>
                        <Input type="email" placeholder="name@example.com" value={requestForm.CcEmails} onChange={handleChangeRequestForm('CcEmails')} />
                    </div>
                    <div className="flex-1">
                        <Label>BCC</Label>
                        <Input type="text" placeholder="name@example.com" value={requestForm.BccEmails} onChange={handleChangeRequestForm('BccEmails')} />
                    </div>
                </div>
            </div>

            {/* Files preview */}
            {attachments.length > 0 && <div className="flex gap-2 overflow-x-auto pt-2">
                    {attachments.map((attachment, index) => <div key={attachment.lastModified} className="relative rounded-md border border-primary-900 p-1.5">
                            <FaMinusCircle className="text-4 absolute -right-2 -top-2 z-20 cursor-pointer bg-white text-danger-600" onClick={() => handleRemoveAttachment(index)} />
                            <p className="whitespace-nowrap text-sm text-primary-900">
                                {attachment.name}
                            </p>
                            <p className="text-xs text-neutral-500">
                                {(attachment.size / (1024 * 1024)).toFixed(2)} Mb
                            </p>
                        </div>)}
                </div>}
            <div className="flex items-center gap-2">
                <Button size="sm" outline className="w-fit border-primary-900 text-primary-900" onClick={() => fileInputRef.current?.click()}>
                    Attach File
                </Button>
                <button className="cursor-pointer rounded-full px-4 py-0.5 text-sm text-neutral-500 hover:bg-neutral-100" onClick={() => setAttachments([])}>
                    Clear
                </button>
                <input ref={fileInputRef} type="file" multiple onChange={handleChangeFileInput} className="hidden" />
            </div>

            <QuillEditor value={model} onChange={setModel} editorClassName="[&>.ql-container]:min-h-[85px]" />
        </div>;
});
export default UserSupportComponent;