import React, { forwardRef, useImperativeHandle } from 'react';
import { IoArrowUpCircle } from 'react-icons/io5';
import { IoArrowDownCircle } from 'react-icons/io5';
import { Button, Input, Label } from '../../common/materials';
import useTaskGroupViewModel from './TaskGroup.viewmodel';
import { twMerge } from 'tailwind-merge';
import EditTaskGroupModal from './components/EditTaskGroupModal';
import AddTaskGroupModal from './components/AddTaskGroupModal';
import DeleteTaskGroupModal from './components/DeleteTaskGroupModal';
export type TaskGroupHandle = {
  handleClickSaveTaskGroup: () => Promise<void>;
};
export type TaskGroupProps = {
  onClose?: () => void;
  onRefresh?: () => void;
  setIsBlur?: React.Dispatch<React.SetStateAction<boolean>>;
};
const TaskGroup = forwardRef<TaskGroupHandle, TaskGroupProps>((props, ref) => {
  const {
    addTaskGroup,
    showAddTaskGroupModal,
    handleAddTaskGroup,
    handleChangeAddTaskGroup,
    confirmAddTaskGroup,
    editTaskGroup,
    showEditTaskGroupModal,
    handleEditTaskGroup,
    handleChangeEditTaskGroup,
    confirmEditTaskGroup,
    deleteTaskGroup,
    showDeleteTaskGroupModal,
    handleDeleteTaskGroup,
    confirmDeleteTaskGroup,
    taskGroupList,
    handleChangeOrder,
    handleClickSaveTaskGroup
  } = useTaskGroupViewModel(props);
  useImperativeHandle(ref, () => {
    return {
      handleClickSaveTaskGroup
    };
  });
  return <React.Fragment>
            <div className="mt-2 flex flex-col">
                <Button className="w-fit bg-[#428CE0] text-white" onClick={() => {
        handleAddTaskGroup({
          group_index: '',
          group_id: '',
          group_description: ''
        });
      }}>
                    Create
                </Button>
            </div>

            <table className="mt-6 w-full table-auto">
                <thead>
                    <tr className="border-b">
                        <th className="p-2 font-bold">Order</th>
                        <th className="p-2 font-bold">Group Index</th>
                        <th className="p-2 font-bold">Group ID</th>
                        <th className="p-2 font-bold">Group Description</th>
                        <th className="p-2 font-bold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {taskGroupList && taskGroupList.length > 0 && taskGroupList.map((group, index) => <tr className="border-b" key={index}>
                                <td className="p-2">
                                    <React.Fragment>
                                        <IoArrowUpCircle size={20} color="green" className={twMerge('block cursor-pointer', index === 0 ? 'hidden' : '')} onClick={() => handleChangeOrder(index, 'UP')} />
                                        <IoArrowDownCircle size={20} color="red" className={twMerge('block cursor-pointer', index === taskGroupList.length - 1 ? 'hidden' : '')} onClick={() => handleChangeOrder(index, 'DOWN')} />
                                    </React.Fragment>
                                </td>
                                <td className="p-2">{group.group_index}</td>
                                <td className="p-2">{group.group_id}</td>
                                <td className="p-2">{group.group_description}</td>
                                <td className="space-x-2 p-2">
                                    <Button className="w-1/4 bg-amber-500 text-white" onClick={() => {
              handleEditTaskGroup(group);
            }}>
                                        Edit
                                    </Button>
                                    <Button className="w-1/4 bg-red-500 text-white" onClick={() => {
              handleDeleteTaskGroup(group);
            }}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>)}
                </tbody>
            </table>

            <EditTaskGroupModal isOpen={showEditTaskGroupModal} editTaskGroup={editTaskGroup} handleEditTaskGroup={handleEditTaskGroup} handleChangeEditTaskGroup={handleChangeEditTaskGroup} confirmEditTaskGroup={confirmEditTaskGroup} />

            <AddTaskGroupModal isOpen={showAddTaskGroupModal} addTaskGroup={addTaskGroup} handleAddTaskGroup={handleAddTaskGroup} handleChangeAddTaskGroup={handleChangeAddTaskGroup} confirmAddTaskGroup={confirmAddTaskGroup} />

            <DeleteTaskGroupModal isOpen={showDeleteTaskGroupModal} deleteTaskGroup={deleteTaskGroup} handleDeleteTaskGroup={handleDeleteTaskGroup} confirmDeleteTaskGroup={confirmDeleteTaskGroup} />
        </React.Fragment>;
});
export default TaskGroup;