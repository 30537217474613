import React, { useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../common/materials';
import { TaskStore } from '../../models/taskStore/Task';
import ReleaseTaskView, { ReleaseTaskHandle } from './ReleaseTask.view';
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  selectedTask?: TaskStore;
};
function ReleaseTaskModal(props: Props) {
  const {
    isOpen,
    onClose,
    onRefresh,
    selectedTask
  } = props;
  const ref = useRef<ReleaseTaskHandle>(null);
  return <Modal className="max-w-2xl" closeOnClickOverlay isOpen={isOpen} onClose={onClose}>
            <ModalHeader>ยืนยันการ Release Task</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <ReleaseTaskView onClose={onClose} onRefresh={onRefresh} ref={ref} selectedTask={selectedTask} />
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button outline onClick={onClose} className="text-neutral-400">
                    Cancel
                </Button>
                <Button onClick={() => {
        ref.current?.handleClickReleaseTask();
      }} className="bg-[#E55C00] text-white">
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>;
}
export default ReleaseTaskModal;