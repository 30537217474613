import { useEffect } from 'react';
import Swal from 'sweetalert2';

import { useStore, useLoader } from '../../../common/hooks';
import { participateUserToSandboxPlatform } from '../../../data/sandbox/repositories/sandboxRepository';

function SandboxRegisterViewModel() {
    const SANDBOX_LICENSE = '2OAp9J1uPKFp2aIkCtFJMFPNZ3E';
    const TYPE = 'user'
    const screenLoader = useLoader();

    const { userCompanyStore, sandboxStore } = useStore();
    const { citizenId } = userCompanyStore;
    const { message } = sandboxStore;

    useEffect(() => {
        if (message !== '') {
            _createSandboxUserResponseHandling();
        }
    }, [message]);

    const onClickCreateSandboxUser = () => {
        _fetchCreateSandboxUser();
    };

    const _fetchCreateSandboxUser = async () => {
        const result = await Swal.fire({
            title: 'ต้องการเข้าร่วม Sandbox?',
            text: 'กดยืนยันเพื่อเข้าร่วม Sandbox',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#ef4444',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        });

        if (result.isConfirmed) {
            try {
                screenLoader.show();
                //await sandboxStore.fetchAddSandboxUser(SANDBOX_LICENSE, citizenId);

                const prepareParticipateUser = {
                    sid: SANDBOX_LICENSE,
                    type: TYPE,
                    citizen_id: citizenId
                }

                await participateUserToSandboxPlatform(prepareParticipateUser)

                console.log("sid: ", prepareParticipateUser.sid,
                    "type: ", prepareParticipateUser.type,
                    "citizen_id: ", prepareParticipateUser.citizen_id
                )

                window.location.replace(window.location.protocol + "//" + window.location.host);
            }
            catch (error) {
                console.log(error)
            } finally {
                screenLoader.hide();
            }
        }
    };

    const _createSandboxUserResponseHandling = () => {
        if (message === 'success') {
            Swal.fire({
                title: 'Success!',
                text: 'Create sandbox user successfully.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then(result => {
                if (result.isConfirmed) {
                    window.location.reload();
                    screenLoader.hide();
                }
            });

            return;
        }

        // On error
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then(result => {
            if (result.isConfirmed) {
                window.location.reload();
                screenLoader.hide();
            }
        });
    };

    return { onClickCreateSandboxUser };
}

export default SandboxRegisterViewModel;
